import React,{useEffect,useState} from "react";
import {Table,Select,Form,Input,Modal,Button,Flex,Row,Col,Upload,message,DatePicker,Space,Alert,Card,Popconfirm,Image,Typography,Tooltip} from "antd";
import {InboxOutlined,CloseOutlined,EditOutlined,FilterOutlined,PlusOutlined,LoginOutlined, UploadOutlined} from "@ant-design/icons";
import useBroker from "../../hooks/useBroker";
import useServer from "../../hooks/useServer";
import useCustomer from "../../hooks/useCustomer";
import AxiosService from "../../services/APIService";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {deleteDoc,getDocURL} from "../../utils/s3Service";
import dayjs from 'dayjs'
import ContactInput from "../../components/ContactInput";
import {downloadFileHandle} from "../../utils/commonFunc";
const {Option} = Select;
function WhiteLabels({permission,user,isLoading}) {
    const apiService = new AxiosService()
    
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [isEditModalOpen,setIsEditModalOpen] = useState(false);
    const [createdUserList,setCreatedUserList] = useState([]);
    const {brokerList} = useBroker()
    const {serverList} = useServer()
    const {customerList} = useCustomer({})
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [form2] = Form.useForm()
    const [setUpdateData,setSetUpdateData] = useState(null);
    const [filterShow,setFilterShow] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [userPreference,setuserPreference] = useState("Mr.");
    const [fileList,setFileList] = useState([]);
    const [previewImg,setPreviewImg] = useState("");
    const [previewShow,setPreviewShow] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            fixed: 'left',
            width: "50px"
        },
        {
            title: "White Label Name",
            dataIndex: "whiteLabelName",
            key: "whiteLabelName",
            fixed: 'left',
            render: (e,rec) => e
        },
        {
            title: "Full Name",
            dataIndex: "name",
            key: "name",
            fixed: 'left',
            render: (e,rec) => e
        },
        {
            title: "Account Status",
            key: "status",
            dataIndex: "status",
            render: (e,rec) => <Select
                defaultValue={e}
                value={e}
                style={{width: "100%"}}
                onChange={(val) => statusChange(val,rec)}
                options={[
                    {value: 'ACTIVE',label: 'Activate'},
                    {value: 'DEACTIVE',label: 'Deactivate'}
                ]}
            />,
            width: "170px"
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e,rec) => (permission?.customer === "EDIT" || user?.role !== "SUBADMIN") &&
                <Space>
                    <Tooltip title="Login">
                        <Button type="default" icon={<LoginOutlined />} size={"middle"}
                            onClick={() => loginHandle(rec)}
                        />
                    </Tooltip>
                    <Button type="default" icon={<EditOutlined />} size={"middle"}
                        onClick={() => editOpen(rec)}
                    />
                    <Popconfirm
                        description="Are you sure you want to close this account ?"

                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => onCloseCustomer(rec.key)}
                    >
                        <Tooltip title="Deactivate account status and bot status to close this account">
                            <Button icon={<CloseOutlined />} danger size={"middle"} disabled={rec?.record?.botStatus || rec?.record?.user?.status == "ACTIVE"} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
        },
        {
            title: "Ref. URL",
            dataIndex: "ref_url",
            key: "ref_url",
            render: (e,rec) => <Typography.Text copyable={{text: `${window.location.hostname}/registration?wlid=${rec?.record?.user?._id}`}}>Copy</Typography.Text>
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Password",
            dataIndex: "password",
            key: "password",
        },
        {
            title: "Level",
            dataIndex: "level",
            key: "level",
            render: (val) => `Level ${val}`
        },
        {
            title: "Account Reference By",
            dataIndex: "reference",
            key: "reference",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Phone Number",
            dataIndex: "phNo",
            key: "phNo",
        },
        {
            title: "Whatsapp Number",
            dataIndex: "wpNum",
            key: "wpNum",
        },
        {
            title: "Document",
            dataIndex: "doc",
            key: "doc",
            render: (_,rec) => <Space>
                {rec?.record?.docs?.slice(0,2).map((d,i) => <Button type="default" onClick={() =>
                    imagePreviewOpen(d,rec)}>Doc {i + 1}</Button>)
                }
            </Space>
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
        },
        {
            title: "Pincode",
            dataIndex: "pincode",
            key: "pincode",
        }
    ];

    const onCloseCustomer = (id) => {
        apiService.post(`/white-label/close/${id}`)
            .then((res) => {
                fetchCreatedUsers({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const imagePreviewOpen = (doc,rec) => {
        let url = getDocURL(`user_doc/${rec.cutId}/${doc}`)
        let ext = doc.split('.')[1];
        let imgex = ['jpg','jpeg','png']
        if(imgex.includes(ext)) {
            setPreviewImg(url)
            setPreviewShow(true)
        } else {
            window.open(url,"_blank")
        }
    }
    const editOpen = (rec) => {

        const data = rec.record
        setSetUpdateData(data)
        form1.setFieldValue("whiteLabel", data.name)
        form1.setFieldValue("fullname",data?.user?.fullName)
        form1.setFieldValue("email",data?.business_email)
        form1.setFieldValue("street",data?.address?.street)
        form1.setFieldValue("city",data?.address?.city)
        form1.setFieldValue("state",data?.address?.state)
        form1.setFieldValue("country",data?.address?.country)
        form1.setFieldValue("pincode",data?.address?.pincode)
        form1.setFieldValue("refName",data?.reference_by?._id)
        form1.setFieldValue("contactNo",data?.contact_no)
        form1.setFieldValue("dob",dayjs(data?.dob))
        form1.setFieldValue("whatsappNo",data?.contact_no?.whatsapp_no)
        form1.setFieldValue("phoneNo",data?.contact_no?.phone_no)
        form1.setFieldValue("username",data?.user?.email)
        form1.setFieldValue("account_created_date",dayjs(data?.account_created_date))
        let list = data?.docs?.map((d,i) => {
            return {
                uid: `-${i}`,
                name: d,
                status: 'done',
                url: getDocURL(`white_label/${data._id}/${d}`),
            }
        })
        setFileList(list)
        form1.setFieldValue("docs",list)
        setuserPreference(data?.user?.preference)
        setIsEditModalOpen(true)

    }
    const loginHandle = (rec) => {

        apiService.post('/auth/admin-login',{id: rec.record.user._id})
            .then(res => {
                window.open(`/wl/home?token=${res.token}`)
            })
            .catch(e => console.log(e))
    }
    const statusChange = (val,rec) => {
        apiService.post(`/white-label/activate`,{
            status: val,
            id: rec?.record?.user?._id
        }).then(res => {
            message.success(res.message)
            fetchCreatedUsers({})
        })
            .catch(e => message.error(e.message))
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleEditCancel = () => {
        setIsEditModalOpen(false);
    };
    const formSubmit = (val) => {
        if(!val.logo || val.logo.fileList.length === 0) {
            message.error("Please upload Logo.")
            return
        }
        if(val.docs.fileList.length === 0) {
            message.error("Please upload at least 1 Document.")
            return
        }
        const formData = new FormData()

        formData.append("fullName",val?.fullname)
        if(val?.phoneNo) formData.append("phone_no",val?.phoneNo)
        formData.append("whatsapp_no",val?.whatsappNo)
        formData.append("street",val?.street)
        formData.append("city",val?.city)
        formData.append("state",val?.state)
        formData.append("country",val?.country)
        formData.append("pincode",val?.pincode)
        formData.append("email",val?.username)
        formData.append("business_email",val?.email)
        formData.append("dob",val?.dob)
        formData.append("password",val?.password)
        formData.append("reference_by",val?.refName)
        formData.append("pref",userPreference)
        if(val?.account_created_date) formData.append("account_created_date",val?.account_created_date)
        formData.append("name", val.whiteLabel)
        formData.append("logo", val.logo.file)        

        val.docs.fileList.forEach((fl,i) => {
            formData.append(`doc${i}`,fl.originFileObj)
        });

        apiService.post('auth/create-white-label',formData)
            .then(() => {
                message.success(`User created successfully`);
                setIsModalOpen(false)
                fetchCreatedUsers({})
            })
            .catch((e) => message.error(e.message))
    }

    useEffect(() => {
        fetchCreatedUsers()
    },[]);
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
        setFileList([])
    };
    const edithandler = (val) => {
        const formData = new FormData()
        if(val.docs.hasOwnProperty('fileList') && val.docs.fileList.length === 0) {
            message.error("Please Select at least 1 Document")
        }

        formData.append("fullName",val?.fullname)
        formData.append("phone_no",val?.phoneNo || "")
        formData.append("whatsapp_no",val?.whatsappNo)
        formData.append("street",val?.street)
        formData.append("city",val?.city)
        formData.append("state",val?.state)
        formData.append("country",val?.country)
        formData.append("pincode",val?.pincode)
        formData.append("email",val?.username)
        formData.append("business_email",val?.email)
        formData.append("dob",val?.dob)
        if(val?.password) formData.append("password",val?.password)
        if(val?.refName) formData.append("reference_by",val?.refName)
        formData.append("pref",userPreference)
        
        formData.append("name",val?.whiteLabel)
        if(val.logo) formData.append("logo", val.logo.file)
        if(val?.account_created_date) formData.append("account_created_date",val?.account_created_date)

        let lastCount = 0;

        if(setUpdateData.docs.length > 0) {
            const lastExtraDoc = setUpdateData.docs[setUpdateData.docs.length - 1];
            const matchResult = lastExtraDoc.match(/\d+/g);
            if(matchResult) {
                lastCount = Number(matchResult[0]);
            }
        }

        for(const file of val?.docs?.fileList || []) {
            if(file.hasOwnProperty("originFileObj")) {
                lastCount++;
                const fieldName = `doc${lastCount}`;
                const fileObject = file.originFileObj;
                formData.append(fieldName,fileObject);
            }
        }

        apiService.put(`/white-label/admin-update/${setUpdateData._id}`,formData)
            .then(res => {
                message.success(res.message)
                fetchCreatedUsers()
                handleEditCancel()
                form1.resetFields()
                setFileList([])
            })
            .catch(e => message.error(e.message))
    }

    const fetchCreatedUsers = (val) => {
        apiService.get('/white-label/list',{
            status: `["CREATED"]`,
            closed_user: false,
            ...val
        })
            .then(res => {
                const list = res.data.map((cst,index) => ({
                    count: index + 1,
                    key: cst?._id,
                    whiteLabelName: cst?.name,
                    name: cst?.user?.preference + cst?.user?.fullName,
                    status: cst?.user?.status,
                    reference: cst?.reference_by?.fullName || "-",
                    record: cst,
                    username: cst?.user?.email,
                    password: cst?.user?.password,
                    dob: dayjs(cst.dob).format("DD-MM-YYYY"),
                    address: cst?.address?.street,
                    city: cst?.address?.city,
                    state: cst?.address?.state,
                    country: cst?.address?.country,
                    pincode: cst?.address?.pincode,
                    phNo: cst?.contact_no?.phone_no,
                    wpNum: cst?.contact_no?.whatsapp_no,
                    level: cst?.level || 1
                }))
                setCreatedUserList(list)
            })
            .catch(e => {console.log(e)})
    }
    const filterHandle = (val) => {
        fetchCreatedUsers({
            botStatus: val.botStatus,
            brokerName: val.brokerName,
            ibName: val.ibName,
            refName: val.refName,
            usrStatus: val.status,
            vpsServer: val.vpsServer
        })
    }
    const resetFilter = () => {
        fetchCreatedUsers({})
        form2.resetFields()
    }
    const removeDoc = (val) => {
        if(!val.hasOwnProperty("originFileObj")) {
            let res = deleteDoc(`white_label/${setUpdateData._id}/${val.name}`)
            if(res) {
                apiService.post(`/white-label/remove-doc/${setUpdateData._id}/${val.name}`)
                    .then(res => {
                        message.success(res.message)
                    })
                    .catch(e => message.error(e.message))
            } else {
                message.error("Internal Server Error")
            }


        }
    }
    const filterData = (searchTxt) => {
        return createdUserList.filter(dt => dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.state.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.country.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.pincode.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.city.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.cutId.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.accNo.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.username.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.password.toLowerCase().includes(searchTxt.toLowerCase()))
    }
    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    return (
        <>
            <Image
                width={200}
                style={{display: 'none'}}
                src={previewImg}
                preview={{
                    visible: previewShow,
                    src: previewImg,
                    onVisibleChange: (value) => {
                        setPreviewShow(value);
                    },
                }}
            />
            <Modal title="Create White Label"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={1000}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="White label" name="whiteLabel" rules={[{required: true,message: 'Please Enter White label Name!'}]}>
                                <Input placeholder="Enter White Label Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Logo" name="logo" rules={[{required: true,message: 'Please Add Logo'}]} extra="Please ensure the logo size are exactly 190px in width and 40px in height.">
                                <Upload
                                    beforeUpload={() => false}
                                    accept=".png"
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Full Name" name="fullname" rules={[{required: true,message: 'Please Enter full name!'}]}>
                                <Input addonBefore={<Select style={{width: 70}} onChange={setuserPreference} defaultValue={userPreference} options={[
                                    {value: "Mr.",label: "Mr."},
                                    {value: "Ms.",label: "Ms."},
                                    {value: "Mrs.",label: "Mrs."},
                                ]} />} placeholder="Enter Full Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email Address" name="email" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Street" name={"street"} rules={[{required: true,message: 'Please Enter Street!'}]}>
                                <Input placeholder="Street" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="City" name={"city"} rules={[{required: true,message: 'Please Enter City!'}]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="State" name={"state"} rules={[{required: true,message: 'Please Enter State!'}]}>
                                        <Input placeholder="State" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Country" name={"country"} rules={[{required: true,message: 'Please Enter Country!'}]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Pincode" name={"pincode"} rules={[{required: true,message: 'Please Enter Pincode!'}]}>
                                        <Input placeholder="Pincode" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <ContactInput form={form} name='phoneNo' label='Phone No.' />
                        </Col>
                        <Col span={12}>
                            <ContactInput form={form} name='whatsappNo' label='Whatsapp No.' require={true} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Documents" name="docs" rules={[{required: true,message: 'Documents requires'}]}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Created At" name="account_created_date" >
                                <DatePicker style={{width: '100%'}} defaultValue={dayjs()} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>  
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item label="Date of Birth" name="dob" rules={[{required: true,message: 'Please select date of birth!'}]}>
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Name (Email id)" name="username" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password (user)" name="password" rules={[{required: true,message: 'Please Enter password!'}]}>
                                <Input.Password placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Confirm Password" name="confirmPassword" dependencies={['password']} rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_,value) {
                                        if(!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}>
                                <Input.Password placeholder="Confirm your Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                </Form>
            </Modal>
            <Modal title="Edit White Label"
                open={isEditModalOpen}
                onCancel={handleEditCancel}
                footer={[]}
                width={1000}
            >
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={edithandler}
                >
                    <Row gutter={16}>
                    <Col span={12}>
                            <Form.Item label="White label" name="whiteLabel" rules={[{required: true,message: 'Please Enter White label Name!'}]}>
                                <Input placeholder="Enter White Label Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Logo" name="logo" extra="Please ensure the logo size are exactly 190px in width and 40px in height.">
                                <Upload
                                    beforeUpload={() => false}
                                    accept=".png"
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Full Name" name="fullname" rules={[{required: true,message: 'Please Enter full name!'}]}>
                                <Input addonBefore={<Select style={{width: 70}} onChange={setuserPreference} defaultValue={userPreference} options={[
                                    {value: "Mr.",label: "Mr."},
                                    {value: "Ms.",label: "Ms."},
                                    {value: "Mrs.",label: "Mrs."},
                                ]} />} placeholder="Enter Full Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email Address" name="email" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Street" name={"street"} rules={[{required: true,message: 'Please Enter Street!'}]}>
                                <Input placeholder="Street" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="City" name={"city"} rules={[{required: true,message: 'Please Enter City!'}]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="State" name={"state"} rules={[{required: true,message: 'Please Enter State!'}]}>
                                        <Input placeholder="State" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Country" name={"country"} rules={[{required: true,message: 'Please Enter Country!'}]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Pincode" name={"pincode"} rules={[{required: true,message: 'Please Enter Pincode!'}]}>
                                        <Input placeholder="Pincode" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Reference by (Name)" name="refName">
                                <Select placeholder="Select Customer" showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <ContactInput form={form1} name='phoneNo' label='Phone No.' />
                        </Col>
                        <Col span={8}>
                            <ContactInput form={form1} name='whatsappNo' label='Whatsapp No.' require={true} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Documents" name="docs" rules={[{required: true,message: 'Documents requires'}]}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    onRemove={removeDoc}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Created At" name="account_created_date" >
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item label="Date of Birth" name="dob" rules={[{required: true,message: 'Please select date of birth!'}]}>
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Name (Email id)" name="username" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password (user)" name="password">
                                <Input.Password placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Confirm Password" name="confirmPassword" dependencies={['password']} rules={[
                                ({getFieldValue}) => ({
                                    validator(_,value) {
                                        if(!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}>
                                <Input.Password placeholder="Confirm your Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">Users</Typography.Title>

            <Flex justify="end" gap={5} style={{marginBottom: "20px"}}>
                <Input style={{maxWidth: 200}} placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} />
                <Button type="primary" onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button>
                {(permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Button type="primary" onClick={showModal}>
                    Create white Label
                </Button>}
                <Button type="primary" onClick={() => downloadFileHandle(createdUserList, 'white_label')}>
                    Download
                </Button>
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form2}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="refName">
                                <Select placeholder="Select Reference" allowClear showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="brokerName">
                                <Select allowClear placeholder="Select Broker">
                                    {brokerList.map(bkr => <Option key={bkr._id} value={bkr._id}>{bkr.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="ibName">
                                <Select allowClear placeholder="Select IB">
                                    {[...new Set(brokerList.map(item => item.ib_name))].map(bkr => <Option key={bkr} value={bkr}>{bkr}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="botStatus">
                                <Select allowClear placeholder="Select Bot Status">
                                    <Option value={"ACTIVE"}>Active</Option>)
                                    <Option value={"DEACTIVE"}>Deactivate</Option>)
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="status">
                                <Select allowClear placeholder="Select Status">
                                    <Option value={"ACTIVE"}>Active</Option>
                                    <Option value={"DEACTIVE"}>Deactivate</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="vpsServer">
                                <Select allowClear placeholder="Select VPS Server">
                                    {serverList.map(dt => <Option value={dt._id}>{dt.vps_ip}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            {(permission?.customer == "EDIT" || permission?.customer == "VIEW" || user?.role != "SUBADMIN") ?
                <Table loading={isLoading} scroll={{x: 3200}} columns={columns} dataSource={createdUserList} /> :
                // <Table loading={isLoading} scroll={{x: 3000}} columns={columns} dataSource={searchText ? filterData(searchText) : createdUserList} /> :
                <Alert message="You havn't Enough Permission" />
            }

        </>
    );
}
const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(WhiteLabels)