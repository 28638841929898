import React,{useEffect,useState} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    UserSwitchOutlined,
    TransactionOutlined,
    BankOutlined,
    BarChartOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    DownOutlined,
    UserDeleteOutlined,
    BookOutlined,
    SettingOutlined,
    NotificationOutlined,
    ProductOutlined,
    QrcodeOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import {Layout,Menu,Button,theme,Dropdown,Space,Flex,Avatar, Typography} from 'antd';
import logo from '../assets/whiteLogo.png';
import logoSm from '../assets/logoSm.png';
import {Link,useLocation,useNavigate} from 'react-router-dom';
import {connect} from "react-redux"
import {LocalStorageService} from '../services/LocalStorage';
import {isLogin,setUserData} from '../actions';
import Notification from './Notification';
import AxiosService from '../services/APIService';
import webSocketService from '../services/websocketService';
const {Header,Sider,Content} = Layout;
const WLLayout = ({children,user,isLogin,setUserData,currentRoute, whiteLabel}) => {
    const [currentActive,setCurrentActive] = useState('1')
    const apiService = new AxiosService()
    const [collapsed,setCollapsed] = useState(false);
    const location = useLocation();
    const searchParams = location.search;
    const [userCount,setUserCount] = useState({
        pendingUser: 0,
        user: 0,
        closeUser: 0,
        editReq: 0,
        settingReq: 0
    });
    const navigate = useNavigate()
    const {
        token: {colorBgContainer,borderRadiusLG},
    } = theme.useToken();

    const menuItems = [
        {
            key: '1',
            icon: <ProductOutlined />,
            label: (<Link to={`/wl/home${searchParams}`}>Dashboard</Link>),
        },
        {
            key: '2',
            icon: <UserOutlined />,
            label: (<Link to={`/wl/users${searchParams}`}>Users ({userCount.user})</Link>),
        },
        {
            key: '3',
            icon: <UserSwitchOutlined />,
            label: (<Link to={`/wl/pending-users${searchParams}`}>Pending Users ({userCount.pendingUser})</Link>),
        },
        {
            key: '4',
            icon: <UserDeleteOutlined />,
            label: (<Link to={`/wl/close-user${searchParams}`}>Closed Users ({userCount.closeUser})</Link>),
        },
        {
            key: '5',
            icon: <TransactionOutlined />,
            label: `Transaction`,
            children: [
                {
                    key: '5-1',
                    label: (<Link to={`/wl/deposit-report${searchParams}`}>Deposit Report</Link>),
                },
                {
                    key: '5-2',
                    label: (<Link to={`/wl/withdraw-report${searchParams}`}>Withdraw Report</Link>),
                },
                {
                    key: '5-3',
                    label: (<Link to={`/wl/botcharge-report${searchParams}`}>Bot Charge</Link>),
                }
            ]
        },
        {
            key: '9',
            icon: <NotificationOutlined />,
            label: (<Link to={`/wl/my-bill${searchParams}`}>Pay Bill</Link>),
        },
        {
            key: '10',
            icon: <BankOutlined />,
            label: (<Link to={`/wl/broker${searchParams}`}>Brokers</Link>),
        },
        {
            key: '6',
            icon: <BarChartOutlined />,
            label: (<Link to={`/wl/profit${searchParams}`}>Profit</Link>),
        },
        {
            key: '7',
            icon: <SettingOutlined />,
            label: `Service Request (${userCount.editReq + userCount.settingReq})`,
            children: [
                {
                    key: '7-1',
                    label: (<Link to={`/wl/note-request${searchParams}`}>Note Request ({userCount.editReq})</Link>),
                },
                {
                    key: '7-2',
                    label: (<Link to={`/wl/setting-request${searchParams}`}>Setting Request ({userCount.settingReq})</Link>),
                },
            ]
        },
        {
            key: '8',
            icon: <NotificationOutlined />,
            label: (<Link to={`/wl/announcement${searchParams}`}>Announcement</Link>),
        },
        {
            key: '11',
            icon: <UserSwitchOutlined />,
            label: (<Link to={`/wl/my-profile${searchParams}`}>My Profile</Link>),
        },
        {
            key: '12',
            icon: <ShareAltOutlined />,
            label: (<Link to={`/wl/inquiry${searchParams}`}>Inquiries</Link>),
        }
    ]
    const activeLink = (route) => {

        switch(route) {
            case "/wl/home":
                setCurrentActive("1");
                break;
            case "/wl/users":
                setCurrentActive("2");
                break;
            case "/wl/pending-users":
                setCurrentActive("3");
                break;
            case "/wl/close-user":
                setCurrentActive("4");
                break;
            case "/wl/deposit-report":
                setCurrentActive("5-1");
                break;
            case "/wl/withdraw-report":
                setCurrentActive("5-2");
                break;
            case "/wl/botcharge-report":
                setCurrentActive("5-3");
                break;
            case "/wl/profit":
                setCurrentActive("6");
                break;
            case "/wl/note-request":
                setCurrentActive("7-1");
                break;
            case "/wl/setting-request":
                setCurrentActive("7-2");
                break;
            case "/wl/announcement":
                setCurrentActive("8");
                break;
            case "/wl/my-bill":
                setCurrentActive("9");
                break;
            case "/wl/broker":
                setCurrentActive("10");
                break;
            case "/wl/my-profile":
                setCurrentActive("11");
                break;
            case "/wl/inquiry":
                setCurrentActive("12");
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        activeLink(currentRoute)
    },[currentRoute])


    const items = [
        {
            label: "Logout",
            key: '0',
        }
    ];
    const menuClick = (val) => {
        switch(val?.key) {
            case "0":
                logoutHandle()
                break;

            default:
                break;
        }
    }
    useEffect(() => {
        webSocketService.onMessage('counterHit',() => {
            fetchCounters()
        });
        fetchCounters()
    }, [user]);
    const fetchCounters = () => {
        apiService.get(`/wl-counter/${user?._id}`)
            .then(res => {
                setUserCount({
                    closeUser: res.closedUsers,
                    pendingUser: res.pendingUsers,
                    user: res.users,
                    settingReq: res.settingReq,
                    editReq: res.editReq,
                })  
            })
            .catch(e => {
                console.log(e);
            })
    }
    const logoutHandle = () => {
        LocalStorageService.removeItem("userToken")
        LocalStorageService.removeItem("user")
        setUserData(null)
        isLogin(false)
        navigate('/login')
    }
    return (
        <Layout style={{height: "100%"}}>
            <Sider
                theme='dark'
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={230}
                style={{
                    background: "#131c2b"
                }}
            >
                <div className="demo-logo-vertical sidebar-logo">
                <img src={`${process.env.REACT_APP_API_URL}white-label/logo/${user?._id}`} style={{width: `${collapsed ? "auto" : "100%"}`,height: `${collapsed ? "40px" : "auto"}`}} />
                </div>
                <Menu
                    mode="inline"
                    theme='dark'
                    selectedKeys={currentActive}
                    items={menuItems}
                    defaultOpenKeys={['4','9']}
                    style={{
                        background: "#131c2b",
                    }}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: '0PX 20PX',
                        background: "#131c2b",
                    }}
                >
                    <Flex justify='space-between'>
                        <Space size={'large'}>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined style={{color: "#fff"}} /> : <MenuFoldOutlined style={{color: "#fff"}} />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                        </Space>
                        <Space size={20}>
                            <Typography.Text style={{color: "#fff"}}>Level {whiteLabel?.level}</Typography.Text>
                            <Notification />
                            <Dropdown
                                menu={{
                                    items,
                                    onClick: menuClick
                                }}
                                trigger={['click']}

                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space style={{color: "#fff"}} className="user-name">
                                        {user?.fullName}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </Space>
                    </Flex>

                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        background: "rgb(220 220 220 / 52%)",
                        borderRadius: borderRadiusLG,
                        minHeight: "1000px"
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};
const mapStateToProps = ({login,url}) => {
    const {user, whiteLabel} = login
    const {currentRoute} = url
    return {user,currentRoute, whiteLabel}
}
const mapDispatchToProps = {
    isLogin,setUserData
}
export default connect(mapStateToProps,mapDispatchToProps)(WLLayout);