import React,{useEffect,useState} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    UserSwitchOutlined,
    TransactionOutlined,
    BankOutlined,
    BarChartOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    DownOutlined,
    UserDeleteOutlined,
    BookOutlined,
    SettingOutlined,
    NotificationOutlined,
    ProductOutlined,
    QrcodeOutlined,
    RobotOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import {Layout,Menu,Button,theme,Dropdown,Space,Flex,Avatar, Segmented, Spin} from 'antd';

import {Link,useNavigate} from 'react-router-dom';
import {useLocation} from "react-router-dom"
import {connect} from "react-redux"
import {LocalStorageService} from '../services/LocalStorage';
import {isLogin,setUserData} from '../actions';
import Notification from './Notification';
import useCustomer from '../hooks/useCustomer';
import AxiosService from '../services/APIService';
import webSocketService from '../services/websocketService';
import logo from "../assets/sidebarLogo.png"
import logoSm from "../assets/sidebarLogoSm.png"
const {Header,Sider,Content} = Layout;
const AdminLayout = ({children,user,isLogin,setUserData,currentRoute}) => {
    const [currentActive,setCurrentActive] = useState('1')
    const apiService = new AxiosService()
    const [collapsed,setCollapsed] = useState(false);
    const [headerMenu,setHeaderMenu] = useState('1');
    const [loading, setLoading] = useState(false);
    const [userCount,setUserCount] = useState({
        pendingUser: 0,
        user: 0,
        closeUser: 0,
        editReq: 0,
        settingReq: 0,
        wlList: 0,
        wlPending: 0,
        wlClosed: 0
    });
    const navigate = useNavigate()
    const {
        token: {colorBgContainer,borderRadiusLG},
    } = theme.useToken();

    const adminMenuItems = [
        {
            key: '12',
            icon: <ProductOutlined />,
            label: (<Link to={"/home"}>Dashboard</Link>),
        },
        {
            key: '1',
            icon: <UserOutlined />,
            label: (<Link to={"/users"}>Users ({userCount.user})</Link>),
        },
        {
            key: '2',
            icon: <UserSwitchOutlined />,
            label: (<Link to={"/pending-users"}>Pending Users ({userCount.pendingUser})</Link>),
        },
        {
            key: '3',
            icon: <UserDeleteOutlined />,
            label: (<Link to={"/close-user"}>Closed Users ({userCount.closeUser})</Link>),
        },
        {
            key: '4',
            icon: <TransactionOutlined />,
            label: "Transaction",
            children: [
                {
                    key: '4-1',
                    label: (<Link to={"/deposit-report"}>Deposit Report</Link>),
                },
                {
                    key: '4-2',
                    label: (<Link to={"/withdraw-report"}>Withdraw Report</Link>),
                },
                {
                    key: '4-3',
                    label: (<Link to={"/botcharge-report"}>Bot Charge</Link>),
                }
            ]
        },
        {
            key: '5',
            icon: <BarChartOutlined />,
            label: (<Link to={"/profit"}>Profit</Link>),
        },
        {
            key: '6',
            icon: <BankOutlined />,
            label: (<Link to={"/broker"}>Brokers</Link>),
        },
        {
            key: '7',
            icon: <DatabaseOutlined />,
            label: (<Link to={"/server"}>VPS Server</Link>),
        },
        (user?.role === "ADMIN" && {
            key: '8',
            icon: <DeploymentUnitOutlined />,
            label: (<Link to={"/team"}>Sub Admin</Link>),
        }),
        {
            key: '9',
            icon: <SettingOutlined />,
            label: `Service Request (${userCount.editReq + userCount.settingReq})`,
            children: [
                {
                    key: '9-1',
                    label: (<Link to={"/note-request"}>Note Request ({userCount.editReq})</Link>),
                },
                {
                    key: '9-2',
                    label: (<Link to={"/setting-request"}>Setting Request ({userCount.settingReq})</Link>),
                },
            ]
        },
        {
            key: '10',
            icon: <BookOutlined />,
            label: (<Link to={"/bot-file"}>Bot File</Link>),
        },
        {
            key: '11',
            icon: <NotificationOutlined />,
            label: (<Link to={"/announcement"}>Announcement</Link>),
        },
        {
            key: '13',
            icon: <QrcodeOutlined />,
            label: (<Link to={"/qr-code"}>QR Code</Link>),
        },
        {
            key: '14',
            icon: <RobotOutlined />,
            label: (<Link to={"/bot-performance"}>Bot Performance</Link>),
        },
    ]
    const whiteLabelMenuItems = [
        {
            key: '12',
            icon: <ProductOutlined />,
            label: (<Link to={"/white-label/home"}>Dashboard</Link>),
        },
        {
            key: '1',
            icon: <UserOutlined />,
            label: `White Label (${userCount.wlList + userCount.wlPending + userCount.wlClosed})`,
            children: [
                {
                    key: '1-1',
                    label: (<Link to={"/white-label/list"}>List ({userCount.wlList})</Link>),
                },
                {
                    key: '1-2',
                    label: (<Link to={"/white-label/pending"}>Pending ({userCount.wlPending})</Link>),
                },
                {
                    key: '1-3',
                    label: (<Link to={"/white-label/close"}>Closed ({userCount.wlClosed})</Link>),
                }
            ]
        },
        {
            key: '2',
            icon: <UserOutlined />,
            label: `Users`,
            children: [
                {
                    key: '2-1',
                    label: (<Link to={"/white-label/user-list"}>List</Link>),
                },
                {
                    key: '2-2',
                    label: (<Link to={"/white-label/pending-user"}>Pending</Link>),
                },
                {
                    key: '2-3',
                    label: (<Link to={"/white-label/close-user"}>Closed</Link>),
                }
            ]
        },
        {
            key: '4',
            icon: <TransactionOutlined />,
            label: "Transaction",
            children: [
                {
                    key: '4-1',
                    label: (<Link to={"/white-label/deposit-report"}>Deposit Report</Link>),
                },
                {
                    key: '4-2',
                    label: (<Link to={"/white-label/withdraw-report"}>Withdraw Report</Link>),
                },
                {
                    key: '4-3',
                    label: (<Link to={"/white-label/botcharge-report"}>Bot Charge</Link>),
                }
            ]
        },
        {
            key: '15',
            icon: <BookOutlined />,
            label: (<Link to={"/white-label/bill-report"}>Bill Report</Link>),
        },
        {
            key: '5',
            icon: <BarChartOutlined />,
            label: (<Link to={"/white-label/profit"}>Profit</Link>),
        },
        {
            key: '6',
            icon: <BankOutlined />,
            label: (<Link to={"/white-label/broker"}>Broker</Link>),
        },
        {
            key: '7',
            icon: <DatabaseOutlined />,
            label: (<Link to={"/white-label/server"}>VPS Server</Link>),
        },
        {
            key: '10',
            icon: <BookOutlined />,
            label: (<Link to={"/white-label/bot-file"}>Bot File</Link>),
        },
        {
            key: '11',
            icon: <NotificationOutlined />,
            label: (<Link to={"/white-label/announcement"}>Announcement</Link>),
        },
        {
            key: '13',
            icon: <QrcodeOutlined />,
            label: (<Link to={"/white-label/qr-code"}>QR Code</Link>),
        },
        {
            key: '14',
            icon: <QrcodeOutlined />,
            label: (<Link to={"/white-label/level"}>Level</Link>),
        },
    ]
    const activeLink = (route) => {

        switch(route) {
            case "/users":
                setCurrentActive("1");
                setHeaderMenu('1')
                break;
            case "/pending-users":
                setCurrentActive("2");
                setHeaderMenu('1')
                break;
            case "/close-user":
                setCurrentActive("3");
                setHeaderMenu('1')
                break;
            case "/deposit-report":
                setCurrentActive("4-1");
                setHeaderMenu('1')
                break;
            case "/withdraw-report":
                setCurrentActive("4-2");
                setHeaderMenu('1')
                break;
            case "/botcharge-report":
                setCurrentActive("4-3");
                setHeaderMenu('1')
                break;
            case "/profit":
                setCurrentActive("5");
                setHeaderMenu('1')
                break;
            case "/broker":
                setCurrentActive("6");
                setHeaderMenu('1')
                break;
            case "/server":
                setCurrentActive("7");
                setHeaderMenu('1')
                break;
            case "/team":
                setCurrentActive("8");
                setHeaderMenu('1')
                break;
            case "/note-request":
                setCurrentActive("9-1");
                setHeaderMenu('1')
                break;
            case "/setting-request":
                setCurrentActive("9-2");
                setHeaderMenu('1')
                break;
            case "/bot-file":
                setCurrentActive("10");
                setHeaderMenu('1')
                break;
            case "/announcement":
                setCurrentActive("11");
                setHeaderMenu('1')
                break;
            case "/home":
                setCurrentActive("12");
                setHeaderMenu('1')
                break;
            case "/qr-code":
                setCurrentActive("13");
                setHeaderMenu('1')
                break;
            case "/bot-performance":
                setCurrentActive("14");
                setHeaderMenu('1')
                break;
            case "/white-label/list":
                setCurrentActive("1-1");
                setHeaderMenu('2')
                break;
            case "/white-label/pending":
                setCurrentActive("1-2");
                setHeaderMenu('2')
                break;
            case "/white-label/close":
                setCurrentActive("1-3");
                setHeaderMenu('2')
                break;
            case "/white-label/user-list":
                setCurrentActive("2-1");
                setHeaderMenu('2')
                break;
            case "/white-label/pending-user":
                setCurrentActive("2-2");
                setHeaderMenu('2')
                break;
            case "/white-label/close-user":
                setCurrentActive("2-3");
                setHeaderMenu('2')
                break;
            case "/white-label/deposit-report":
                setCurrentActive("4-1");
                setHeaderMenu('2')
                break;
            case "/white-label/withdraw-report":
                setCurrentActive("4-2");
                setHeaderMenu('2')
                break;
            case "/white-label/botcharge-report":
                setCurrentActive("4-3");
                setHeaderMenu('2')
                break;
            case "/white-label/profit":
                setCurrentActive("5");
                setHeaderMenu('2')
                break;
            case "/white-label/broker":
                setCurrentActive("6");
                setHeaderMenu('2')
                break;
            case "/white-label/server":
                setCurrentActive("7");
                setHeaderMenu('2')
                break;
            case "/white-label/bot-file":
                setCurrentActive("10");
                setHeaderMenu('2')
                break;
            case "/white-label/announcement":
                setCurrentActive("11");
                setHeaderMenu('2')
                break;
            case "/white-label/home":
                setCurrentActive("12");
                setHeaderMenu('2')
                break;
            case "/white-label/qr-code":
                setCurrentActive("13");
                setHeaderMenu('2')
                break;
            case "/white-label/level":
                setCurrentActive("14");
                setHeaderMenu('2')
                break;
            case "/white-label/bill-report":
                setCurrentActive("15");
                setHeaderMenu('2')
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        activeLink(currentRoute)
    },[currentRoute])


    const items = [
        {
            label: "Logout",
            key: '0',
        },
        {
            label: "Download Database",
            key: '1',
        },
    ];
    const menuClick = (val) => {
        switch(val?.key) {
            case "0":
                logoutHandle()
                break;
                case "1":
                    downloadDatabase();
                    break;
            default:
                break;
        }
    }
    const downloadDatabase = () => {
        setLoading(true)
        apiService.get('/database/download')
            .then(res => {
                if(res) {
                    const dataStr = JSON.stringify(res,null,2);
                    const blob = new Blob([dataStr],{type: "application/json"});
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = "data.json";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            })
            .catch(e => console.log(e))
        .finally(() => setLoading(false))
    }
    useEffect(() => {
        webSocketService.onMessage('counterHit',() => {
            fetchCounters()
        });
        fetchCounters()
    }, [user]);
    const fetchCounters = () => {
        apiService.get('/counter')
            .then(res => {
                setUserCount({
                    closeUser: res.closedUsers,
                    pendingUser: res.pendingUsers,
                    user: res.users,
                    settingReq: res.settingReq,
                    editReq: res.editReq,
                    wlList: res.whiteLabel,
                    wlPending: res.pendingWhiteLabel,
                    wlClosed: res.closedWhiteLabel,
                })  
            })
            .catch(e => {
                console.log(e);
            })
    }
    const logoutHandle = () => {
        LocalStorageService.removeItem("userToken")
        LocalStorageService.removeItem("user")
        setUserData(null)
        isLogin(false)
        navigate('/login')
    }
    const horizontalMenuItem = [
        {
            value: '1',
            label: 'Contract Base',
        },
        {
            value: '2',
            label: 'White Label',
        },
    ]
    return (
        <Layout style={{height: "100%"}}>
            <Sider
                theme='dark'
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={230}
                style={{
                    background: "#131c2b"
                }}
            >
                <div className="demo-logo-vertical sidebar-logo" style={{fontSize: 22, color: "#fff"}}>
                    {collapsed ? <img src={logoSm} /> : <img src={logo} />}
                </div>
                <Menu
                    mode="inline"
                    theme='dark'
                    selectedKeys={currentActive}
                    items={headerMenu == '1' ? adminMenuItems : whiteLabelMenuItems}
                    defaultOpenKeys={['4','9']}
                    style={{
                        background: "#131c2b",
                    }}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: '0PX 20PX',
                        background: "#131c2b",
                        lineHeight: '63px'
                    }}
                >
                    <Flex justify='space-between'>
                        <Space size={'large'}>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined style={{color: "#fff"}} /> : <MenuFoldOutlined style={{color: "#fff"}} />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <Segmented
                                options={horizontalMenuItem}
                                value={headerMenu}
                                onChange={(e) => {
                                    setHeaderMenu(e)
                                    setCurrentActive('12')
                                    navigate(e == '1' ? '/home' : '/white-label/home')
                                }}
                            />
                        </Space>
                        <Space size={20}>
                            <Notification />
                            <Dropdown
                                menu={{
                                    items,
                                    onClick: menuClick
                                }}
                                trigger={['click']}

                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space style={{color: "#fff"}} className="user-name">
                                        {user?.fullName}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </Space>
                    </Flex>

                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        background: "rgb(220 220 220 / 52%)",
                        borderRadius: borderRadiusLG,
                        minHeight: "1000px"
                    }}
                >
                    {children}
                </Content>
            </Layout>
            {loading && <div className='loader-cust'>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>}
        </Layout>
    );
};
const mapStateToProps = ({login,url}) => {
    const {user} = login
    const {currentRoute} = url
    return {user,currentRoute}
}
const mapDispatchToProps = {
    isLogin,setUserData
}
export default connect(mapStateToProps,mapDispatchToProps)(AdminLayout);