import {Button, Card, Col, DatePicker, Descriptions, Flex, Form, Image, Input, InputNumber, message, Modal, Row, Select, Space, Table, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import AxiosService from '../../services/APIService';
import {connect} from 'react-redux';
import moment from 'moment';
import {FilterOutlined, InboxOutlined} from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';

function PayBill({wallet,isLoading,billDetail,totalProfit,customer,botCharge}) {
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [transactionList,setTransactionList] = useState([]);
    const [filterShow,setFilterShow] = useState(false);
    const [qrData, setQrData] = useState()
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        fetchTransaction()
        fetchQrData()
    },[]);
    const apiService = new AxiosService()
    const columns = [
        {
            title: "#",
            key: "index",
            dataIndex: "index",
            width: "50px",
            fixed: 'left',
        },
        {
            title: "Transaction Id",
            dataIndex: "transactionId",
            key: "transactionId",
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
            key: "paymentMethod"
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
        },
        {
            title: "Request Date",
            dataIndex: "dateTime",
            key: "dateTime",
            render: (e, rec) => rec.paymentMethod === "OFFLINE" ? "-" : moment(e).format("DD-MM-YYYY HH:mm")
        },
        {
            title: "Action Date",
            dataIndex: "actionDate",
            key: "actionDate",
            render: (e) => e ? moment(e).format("DD-MM-YYYY HH:mm") : "-"
        },
        {
            title: "Parment Transaction Hash",
            dataIndex: "transactionHash",
            key: "transactionHash"
        },
        {
            title: "Proof",
            key: "proof",
            dataIndex: "proof",
            render: (val, rec) => val && <Image width={50} src={`${process.env.REACT_APP_API_URL}/transaction/doc/${rec.key}`} />
        }
    ];
    const fetchTransaction = (params) => {
        apiService.get("/transaction/my-transaction",{
            ...params,
            type: "WL_CHARGE"
        })
            .then(res => {
                const data = res.data.map((dt, index) => ({
                    index: index + 1,
                    key: dt?._id,
                    transactionId: dt?.transaction_id,
                    dateTime: dt?.created_at,
                    amount: dt?.amount,
                    status: dt?.status,
                    type: dt?.trans_type,
                    transactionHash: dt?.paymentId || "-",
                    proof: dt?.doc,
                    actionDate: dt?.action_date,
                    paymentMethod: dt?.via
                }))
                setTransactionList(data)
            })
            .catch(e => console.log(e))
    }
    const fetchQrData = () => {
        apiService.get('/qr/get?wl=true')
            .then(res => {
                setQrData(res.data)
            })
    }
    const formSubmit = (val) => {
        const formField = new FormData()

        formField.append("amount", val.amount)
        formField.append("paymentID", val.paymentID)
        formField.append("paymentHash", val.paymentHash)
        formField.append("document",val?.doc?.file)
        
        apiService.post("/transaction/pay-bill-user",formField)
            .then(res => {
                form.resetFields()
                setIsModalOpen(false);
                fetchTransaction({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const handleFileUpload = (file) => {
        return false;
    };
    const fileUploadProps = {
        name: 'file',
        multiple: false,
        beforeUpload: handleFileUpload,
    };
    const filterHandle = (val) => {
        fetchTransaction({
            actionDate: val.actionDate ? moment(val.actionDate.toString()).format("DD-MM-YYYY") : undefined,
            reqDate: val.reqDate ? moment(val.reqDate.toString()).format("DD-MM-YYYY") : undefined,
            status: val.status
        })
    }
    const resetFilter = () => {
        fetchTransaction({})
        form1.resetFields()
    }
    return (
        <>
            <Modal title="Pay Bill"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={600}
            >
                <Flex justify='center' style={{marginBottom: "30px"}}>
                    {customer?.white_label ? <Image preview={false} src={`${process.env.REACT_APP_API_URL}qr/wl-qr/${customer?.white_label}`} /> : qrData && <Image preview={false} src={`${process.env.REACT_APP_API_URL}qr/img/${qrData._id}`} />}
                </Flex>
                <Typography.Title style={{textAlign: "center"}} level={5} copyable>{qrData?.walletID}</Typography.Title>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="Amount" name="amount" rules={[{required: true, type: "number", message: 'Amount Required!'}]} extra={`Due Amount: ${billDetail?.dueAmount || 0}`}>
                        <InputNumber placeholder="Add Amount" min={1} max={billDetail?.dueAmount || 0} style={{width: "100%"}} />
                    </Form.Item>
                    <Form.Item label="Documents" name={"doc"}>
                        <Dragger {...fileUploadProps} maxCount={1}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag files to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                    <Form.Item label="Payment Transaction Hash" name="paymentID">
                        <Input placeholder="Payment Transaction Hash" style={{width: "100%"}} />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">Bill History</Typography.Title>
            <div style={{maxWidth: "500px"}}>
                <Descriptions style={{marginBottom: "20px"}} title="Flow Information" bordered>
                    <Descriptions.Item span={3} label={"Due Amount"}>
                        {billDetail?.dueAmount?.toLocaleString("hi-IN")}
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <Flex justify="end" align='center' style={{marginBottom: "20px"}}>
            <Button onClick={() => setFilterShow(!filterShow)} type="primary" style={{marginRight: 5}} ><FilterOutlined /></Button>
                <Button type="primary" onClick={showModal}>
                    Pay Bill
                </Button>
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={(val) => filterHandle(val)}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="reqDate">
                                <DatePicker placeholder='Request Date' style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="actionDate">
                                <DatePicker placeholder='Action Date' style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="status">
                                <Select allowClear placeholder="Select Status">
                                    <Select.Option value={"APPROVED"}>Approve</Select.Option>)
                                    <Select.Option value={"REJECTED"}>Rejected</Select.Option>)
                                    <Select.Option value={"PENDING"}>Pending</Select.Option>)
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            <Table scroll={{ x: 2000}} loading={isLoading} columns={columns} dataSource={transactionList} />

        </>
    )
}
const mapStateToProps = ({login, loading, billInfo, charge}) => {
    const {wallet, customer} = login
    const {isLoading} = loading
    const {billDetail, totalProfit} = billInfo
    const {botCharge} = charge
    return {wallet, isLoading, billDetail, totalProfit, customer, botCharge}
  }
export default connect(mapStateToProps, {}) (PayBill)