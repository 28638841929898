import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Flex, Form, Image, Input, Modal, Row, Table, Typography, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import AxiosService from '../services/APIService';
import moment from 'moment';
import { render } from '@testing-library/react';

function QrCode({isLoading}) {
    const [form] = Form.useForm();
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [fileList,setFileList] = useState([]);
    const apiService = new AxiosService()
    const [listData, setListData] = useState([])
    const formSubmit = (val) => {
        if(val.qrCode.fileList.length === 0){
            message.error("Please Select File")
            return
        }
        const formData = new FormData()
        formData.append("walletID", val.walletId)
        formData.append("qrCode", val.qrCode.file)
        formData.append("forWhiteLabel", val.forWhiteLabel)
        apiService.post('/qr/update',formData)
            .then(res => {
                message.success(res.message)
                listFetch()
                handleCancel()
            })
            .catch(e => message.error(e.message))
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </button>
    );
    useEffect(() => {
        listFetch()
    }, [])
    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
        },
        {
            title: "Wallet Id",
            dataIndex: "walletId",
            key: "walletId",
        },
        {
            title: "QR Code",
            dataIndex: "qrCode",
            key: "qrCode",
            render: (id) => <Image width={50} src={`${process.env.REACT_APP_API_URL}qr/img/${id}`} />
        },
        {
            title: "For",
            dataIndex: "for",
            key: "for"
        },
        {
            title: "Updated At",
            dataIndex: "updatedAt",
            key: "updatedAt",
        },
    ]
    
    const listFetch = () => {
        apiService.get('/qr/list')
        .then(res => {
            const data = res.data.map((dt, i) => ({
                index: i + 1,
                walletId: dt.walletID,
                qrCode: dt._id,
                for: dt.for_white_label ? "White Label" : "User",
                updatedAt: moment(dt.created_at).format('DD-MM-YYYY HH:mm')
            }))

            setListData(data)
        })
        .catch(e => {
            console.log(e);
        })
    }
    return (
        <>
            <Modal title="Create QR Code" open={isModalOpen} onCancel={handleCancel} footer={[]}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Wallet Id" name={"walletId"} rules={[{ required: true, message: "Please enter Account Name" }]}>
                                <Input placeholder="Enter Wallet Id" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="QR Coded" name={"qrCode"} rules={[{ required: true, message: "Please enter Account Number" }]}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                    >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={"forWhiteLabel"} valuePropName="checked">
                                <Checkbox>For White Label</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">QR code</Typography.Title>
            <Flex justify="end" style={{marginBottom: "20px"}}>
                {/* <Button type="default" style={{marginRight: 5}}><FilterOutlined /></Button> */}
                <Button type="primary" onClick={showModal}>
                    Update Qr Code
                </Button>
            </Flex>
            <Table columns={columns} dataSource={listData} />
        </>

    )
}
const mapStateToProps = ({loading}) => {
    const {isLoading} = loading
    return { isLoading}
  }
export default connect(mapStateToProps, {}) (QrCode)