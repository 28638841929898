export const IS_LOGIN = "loginReducer/IS_LOGIN"
export const ON_LOGIN = "loginReducer/ON_LOGIN"
export const SET_PERMISSION = "loginReducer/SET_PERMISSION"
export const SET_CUSTOMER = "loginReducer/SET_CUSTOMER"
export const SET_WHITE_LABEL = "loginReducer/SET_WHITE_LABEL"
export const SET_WALLET = "loginReducer/SET_WALLET"

export const isLogin = (val) => (disptch) =>{
    return disptch({
        type: IS_LOGIN,
        payload: val
    });
}

export const setUserData = (val) => (disptch) =>{
  return disptch({
    type: ON_LOGIN,
    payload: val
  })
}

export const setPermission = (val) => (disptch) => {
  return disptch({
    type: SET_PERMISSION,
    payload: val
  })
}

export const setCustomerData = (val) => (disptch) => {
  return disptch({
    type: SET_CUSTOMER,
    payload: val
  })
}
export const setWhiteLabelData = (val) => (disptch) => {
  return disptch({
    type: SET_WHITE_LABEL,
    payload: val
  })
}

export const setWallet = (val) => (disptch) => {
  return disptch({
    type: SET_WALLET,
    payload: val
  })
}