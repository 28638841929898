import { SET_BOT_CHARGE } from "../actions/botChargeAction";

const initialState = {
  botCharge: 0
  };
const botChargeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOT_CHARGE:
        return {
            ...state,
            botCharge: action.payload,
        };
    default:
      return state;
  }
};

export default botChargeReducer;