import {Button, InputNumber, Space, Table, Tooltip} from 'antd';
import React,{useEffect,useState} from 'react'
import AxiosService from '../../services/APIService';
import {CheckOutlined, CloseOutlined, EditOutlined} from '@ant-design/icons';
const EditableItem = ({
    hoveredRow,
    record,
    data,
    handleSuccess,
}) => {
    const [editing,setEditing] = useState(false);
    const [inputVal,setinputVal] = useState(data);

    const cancelUpdate = () => {
        setEditing(false)
        setinputVal(data)
    }

    return (
        <>
            {editing ? (
                <>
                    <InputNumber onChange={(val) => setinputVal(val)} value={inputVal} className="mr-2" style={{width: "70px"}} />
                    <Button
                        type="default"
                        onClick={() => {
                            handleSuccess(record.data.level,inputVal)
                            setEditing(false)
                        }}
                        icon={<CheckOutlined />}
                        size={"small"}
                        className="mr-1" />
                    <Button
                        type="default"
                        onClick={cancelUpdate}
                        icon={<CloseOutlined />}
                        size={"small"} danger />
                </>
            ) : (
                <div className="cell-wrapper">
                    {data}
                    <Space size="small" className="edit-wrapper">
                        {hoveredRow === record.key && (
                            <Tooltip title="Edit">
                                <Button
                                    size="small"
                                    className="action-btn mt-1"
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => setEditing(true)}
                                />
                            </Tooltip>
                        )}
                    </Space>
                </div>)}
        </>
    )
}
function WLLevel() {
    const [listData,setListData] = useState();
    const apiService = new AxiosService()
    const [hoveredRow, setHoveredRow] = useState(null);
    const handleRowHover = (record) => {

        setHoveredRow(record.key);
      };
    
      const handleRowLeave = () => {
        setHoveredRow(null);
      };

    const columns = [
        {
            title: 'Levels',
            dataIndex: 'levels',
            key: 'levels',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (val, rec) => <EditableItem record={rec} data={val} dataKey={rec.levels} hoveredRow={hoveredRow} handleSuccess={updateLevelAmount} />
        },
        {
            title: 'Bot Charge',
            dataIndex: 'botcharge',
            key: 'botcharge',
            render: (val, rec) => <EditableItem record={rec} data={val} dataKey={rec.levels} hoveredRow={hoveredRow} handleSuccess={updateLevelCharge} />
        },
    ];
    const updateLevelAmount = (id, value) => {
        apiService.post(`/level/update-amount/${id}`,{
            amount: value
        })
            .then(() => {
                fetchLevelData()
            })
            .catch(e => console.log(e))
    }
    const updateLevelCharge = (id, value) => {
        apiService.post(`/level/update-charge/${id}`,{
            charge: value
        })
            .then(() => {
                fetchLevelData()
            })
            .catch(e => console.log(e))
    }
    useEffect(() => {
        fetchLevelData()
    },[]);
    const fetchLevelData = () => {
        apiService.get("/level/list")
            .then(res => {
                let listData = res.data.map((lvl,i) => ({
                    key: `${i}`,
                    levels: `Level ${lvl.level}`,
                    amount: lvl.amount,
                    botcharge: lvl.bot_charge,
                    data: lvl
                }))
                setListData(listData)
            })
        .catch(e => console.log(e))
    }
    return (
        <Table dataSource={listData} columns={columns} onRow={(record) => ({
            onMouseEnter: () => handleRowHover(record),
            onMouseLeave: handleRowLeave,
          })} />
    )
}

export default WLLevel