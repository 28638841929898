import {FilterOutlined} from '@ant-design/icons';
import {Button,Flex,Form,Input,message,Modal,Space,Table, Typography} from 'antd'
import React,{useEffect, useState} from 'react'
import {connect} from 'react-redux'
import AxiosService from '../services/APIService';
import moment from 'moment';

function BotFile({isLoading, user}) {
    const [searchText,setSearchText] = useState("");
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [listData,setListData] = useState([]);
    const [editId, setEditId] = useState("");
    const apiService = new AxiosService()
    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
        },
        {
            title: "File Name",
            dataIndex: "filename",
            key: "filename",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "By",
            key: "by",
            dataIndex: "by",
          },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (_, rec) => <Space>
                <Button size='small' onClick={() => editHandle(_, rec)}>Edit</Button>
                <Button size='small' onClick={() => deleteHandle(_)} danger>Delete</Button>
            </Space>,
            width: "100px"
        }
    ];
    const [form] = Form.useForm()
    const editHandle = (val,rec) => {
        setEditId(val)
        setIsModalOpen(true)
        form.setFieldValue("fileName", rec.filename)
    }
    const deleteHandle = (val) => {
        apiService.delete(`/files/delete/${val}`)
        .then(res => {
            message.success(res.message)
            fetchData({})
        })
        .catch(e => {
            message.error(e.message)    
        })
    }
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
    };
    useEffect(() => {
        fetchData({})
    },[]);
    
    const fetchData = () => {
        apiService.get('/files/list')
        .then(res => {
            const data = res.data.map((dt, i) => ({
                key: dt._id,
                index: i + 1,
                filename: dt.file,
                date: moment(dt.created_at).format("DD-MM-YYYY"),
                by: `${dt?.created_by?.fullName} (${dt?.created_by?.role})`,
                action: dt._id
            }))
            setListData(data)
        })
        .catch(e => console.log(e))
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setEditId("")
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditId("")
    };
    const formSubmit = (val) => {
        if(editId) {
            apiService.put(`/files/update/${editId}`,{
                fileName: val.fileName,
            })
            .then(res => {
                message.success(res.message)
                setIsModalOpen(false)
                fetchData({})
                setEditId("")
            })
            .catch(e => {
                message.error(e.message)    
            })
        } else {
            apiService.post('/files/create',{
                fileName: val.fileName,
            })
            .then(res => {
                message.success(res.message)
                setIsModalOpen(false)
                fetchData({})
            })
            .catch(e => {
                message.error(e.message)    
            })
        }
    }
    const filterData = (searchTxt) => {
        return listData.filter(dt => dt.filename.toLowerCase().includes(searchTxt.toLowerCase()))
    }
    return (
        <div>
            <Modal title="Create File" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="File Name" name={"fileName"} required>
                        <Input placeholder="Enter File Name" />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">Bot File</Typography.Title>

            <Flex justify="end" style={{marginBottom: "20px"}}>
                <Input style={{marginRight: 5,maxWidth: 200}} placeholder="Search by File Name..." onChange={(e) => setSearchText(e.target.value)} />
                {/* <Button type="default" style={{marginRight: 5}}><FilterOutlined /></Button> */}
                <Button type="primary" onClick={showModal}>
                    Create File
                </Button>
            </Flex>
            <Table loading={isLoading} columns={columns} dataSource={searchText ? filterData(searchText) :listData} />
        </div>
    )
}
const mapStateToProps = ({loading, login}) => {
    const {isLoading} = loading
    const {user} = login
    return {isLoading, user}
}
export default connect(mapStateToProps,{})(BotFile)