import React,{useEffect,useState} from 'react'
import {Table,Select,Button,Modal,Form,Row,Col,Input,message,Alert,Flex,Card,Space,Typography,Popconfirm} from "antd";
import AxiosService from '../../services/APIService';
import {CloseOutlined,EditOutlined,FilterOutlined} from '@ant-design/icons';
import useBroker from '../../hooks/useBroker';
import useServer from '../../hooks/useServer';
import {connect} from 'react-redux';
import useCustomer from '../../hooks/useCustomer';
import {Link} from 'react-router-dom';
const {Option} = Select;
const wlCol = [
    {
        title: "#",
        dataIndex: "index",
        key: "index",
        width: 50
    },
    {
        title: "White Label",
        dataIndex: "whiteLabel",
        key: "whiteLabel",
    }
]
function WLPendingUserList({permission,user,isLoading}) {
    const [isModalOpen,setIsModalOpen] = useState(false);
    const axiosService = new AxiosService()
    const [userList,setUserList] = useState();
    const {brokerList} = useBroker({allWhiteLabel: true})
    const {serverList} = useServer({allWhiteLabel: true})
    const [editData,seteditData] = useState();
    const [searchText,setSearchText] = useState("");
    const [filterShow,setFilterShow] = useState(false);
    const {customerList} = useCustomer({})
    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            width: "50px"
        },
        {
            title: "Bot Id",
            dataIndex: "cutId",
            key: "custId",
            width: "100px"
        },
        {
            title: "Account Name",
            dataIndex: "name",
            key: "name",
            render: (e,rec) => <Link to={`/user-profile?userId=${rec.key}`}>{e}</Link>
        },
        {
            title: "Reference By",
            dataIndex: "reference",
            key: "reference",
        },
        {
            title: "IB Name",
            dataIndex: "ibName",
            key: "ibName",
        },
        {
            title: "Broker",
            dataIndex: "broker",
            key: "broker",
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (e,rec) => (permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Select
                defaultValue={e}
                value={e}
                disabled={!rec.action.server_vps}
                onChange={(val) => statusChange(val,rec)}
                options={[
                    {value: 'PENDING',label: 'Pending'},
                    {value: 'CREATED',label: 'Create'}
                ]}
            />
        },{
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e,rec) => (permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Space>
                <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => editOpen(e)} />
                <Popconfirm
                    description="Are you sure you want to delete this account?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteCustomer(rec.key)}
                >
                    <Button icon={<CloseOutlined />} danger size={"middle"} />
                </Popconfirm>
            </Space>
        }
    ];
    const [form] = Form.useForm()
    const [form1] = Form.useForm()

    useEffect(() => {
        fetchPendingUser({})

    },[]);

    const deleteCustomer = (id) => {
        axiosService.delete(`/customer/delete-customer/${id}`)
            .then((res) => {
                fetchPendingUser({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }

    const statusChange = (val,rec) => {
        axiosService.post(`/customer/change-status/${rec?.key}`,{
            status: val
        }).then(res => {
            message.success(res.message)
            fetchPendingUser({})
        })
            .catch(e => message.error(e.message))
    }

    const editOpen = (val) => {
        form.setFieldValue("brokerName",val?.broker?._id)
        form.setFieldValue("accountNumber",val?.account_number)
        form.setFieldValue("masterPassword",val?.master_password)
        form.setFieldValue("investorPassword",val?.investor_password)
        form.setFieldValue("portalLink",val?.portal_link)
        form.setFieldValue("portalPassword",val?.portal_password)
        form.setFieldValue("brokerServer",val?.server)
        form.setFieldValue("vpsServer",val?.server_vps?._id)
        seteditData(val)
        setIsModalOpen(true)
    }

    const fetchPendingUser = (params) => {
        axiosService.get('/customer/whitelabel-user',{
            status: ["PENDING"],
            ...params
        }).then(res => {
            const data = res.data.map((dt,i) => ({
                count: i + 1,
                key: dt?._id,
                whiteLabel: dt?.wl?.name,
                data: dt
            }));
            setUserList(data)

        }).catch(e => {
            console.log(e);
        })
    }

    const handleOk = () => {
        setIsModalOpen(false);
        form.resetFields()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };
    const formSubmit = (val) => {
        const formData = new FormData()
        formData.append("server_vps",val?.vpsServer)

        axiosService.put(`/customer/admin-update/${editData._id}`,formData)
            .then(res => {
                message.success(res.message)
                handleOk()
                fetchPendingUser({})
                seteditData("")
            })
            .catch(e => message.error(e.message))
    }
    const filterData = (key) => {
        return userList.filter(dt => dt.name.toLowerCase().includes(key.toLowerCase()) ||
            dt.cutId.toLowerCase().includes(key.toLowerCase()))
    }
    const filterHandle = (val) => {
        fetchPendingUser(val)
    }
    const resetFilter = () => {
        form1.resetFields()
        fetchPendingUser({})

    }
    const expandedRowRender = (val) => {
        const data = val.data.customers.map((dt, i) => ({
            key: dt?._id,
            count: i + 1,
            cutId: dt?.cust_code,
            name: `${dt?.user?.preference || ''} ${dt?.user?.fullName}`,
            reference: dt?.reference_by?.fullName || "-",
            broker: dt?.broker ? `${dt?.broker?.name}` : "-",
            ibName: dt?.broker ? `${dt?.broker?.ib_name}` : "-",
            status: dt?.status,
            action: dt
        }))
        return <Table loading={isLoading} columns={columns} dataSource={data} />
    }
    return (
        <>
            <Modal title="Update User"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={1000}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Col span={12}>
                        <Form.Item label="Vps Server" name="vpsServer" rules={[{required: true,message: 'Please select VPS server!'}]}>
                            <Select placeholder="Select Vps Server" showSearch options={serverList.filter(sr => sr.white_label === editData?.white_label).map(srvr => ({
                                value: srvr._id,
                                label: srvr.vps_ip
                            }))} filterOption={(input,option) =>
                                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } />
                        </Form.Item>
                    </Col>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">Pending Users</Typography.Title>
            <Flex justify="end" style={{marginBottom: "20px"}}>
                <Input style={{marginRight: 5,maxWidth: 250}} placeholder="Search by Bot Id / Account Name..." onChange={(e) => setSearchText(e.target.value)} />
                {/* <Button type="primary" style={{marginRight: 5}} onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button> */}
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="refName">
                                <Select placeholder="Select Reference" allowClear showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="brokerName">
                                <Select allowClear placeholder="Select Broker">
                                    {brokerList.map(bkr => <Option key={bkr._id} value={bkr._id}>{bkr.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="ibName">
                                <Select allowClear placeholder="Select IB">
                                    {[...new Set(brokerList.map(item => item.ib_name))].map(bkr => <Option key={bkr} value={bkr}>{bkr}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            <Table loading={isLoading} columns={wlCol} dataSource={userList} expandable={{expandedRowRender}} />
        </>
    );
}
const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(WLPendingUserList)