import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import loadingReducer from "./loadingReducer";
import billReducer from "./billReducer";
import botChargeReducer from "./botChargeReducer";
import routeReducer from "./routeReducer";

const rootReducer= combineReducers({
    login: loginReducer,
    loading: loadingReducer,
    billInfo: billReducer,
    charge: botChargeReducer,
    url: routeReducer
});
export default rootReducer;