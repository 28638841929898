import React,{useEffect,useState} from 'react'
import AxiosService from '../services/APIService';
import {Alert,Button,Card,Col,DatePicker,Flex,Form,Input,InputNumber,message,Modal,notification,Row,Select,Space,Table,Tooltip,Typography} from 'antd';
import moment from 'moment';
import {connect} from 'react-redux';
import useBills from '../hooks/useBills';
import useCustomer from '../hooks/useCustomer';
import {FilterOutlined} from '@ant-design/icons';
import useBroker from '../hooks/useBroker';

function Profit({isLoading,permission,user}) {
	const apiService = new AxiosService()
	const {brokerList} = useBroker()
	const [form] = Form.useForm()
	const [form1] = Form.useForm()
	const [form2] = Form.useForm()
	const [listData,setListData] = useState([]);
	const {billList,fetchBills} = useBills()
	const {customerList} = useCustomer({status: `["CREATED"]`,closed_user: false})
	const [searchText,setSearchText] = useState("");
	const [filterShow,setFilterShow] = useState(false);
	const [isEditModalOpen,setIsEditModalOpen] = useState(false);
	useEffect(() => {
		fetchProfit()
	},[billList]);

	const columns = [
		{
			title: "#",
			dataIndex: "index",
			key: "index",
			fixed: 'left',
			width: 50
		},
		{
			title: "Bot Id",
			dataIndex: "botId",
			key: "botId",
			fixed: 'left',
			width: 100
		},
		{
			title: "Account Name",
			dataIndex: "name",
			key: "name",
			width: 300
		},
		{
			title: "Account Number",
			dataIndex: "acNo",
			key: "acNo",
			width: 170
		},
		{
			title: "Broker",
			dataIndex: "broker",
			key: "broker",
			width: 150
		},
		{
			title: "Profit Amount",
			key: "amount",
			dataIndex: "amount",
			width: 170
		},
		{
			title: "Bot Charge",
			key: "botCharge",
			dataIndex: "botCharge",
			render: (_) => `${_}%`,
			width: 170
		},
		{
			title: "Bot Charge Amount",
			key: "botChargeAmount",
			dataIndex: "botChargeAmount",
			width: 200
		},
		{
			title: "Due Amount",
			key: "dueAmount",
			dataIndex: "dueAmount",
			width: 150
		},
		{
			title: "Due Date",
			key: "dueDate",
			dataIndex: "dueDate",
			width: 170
		},
	];
	const fetchProfit = (params) => {
		apiService.get('/profit/list',params)
			.then(res => {
				setListData(res.data)
			})
			.catch(e => console.log(e))
	}
	const filterData = (searchTxt) => {
		return listData.filter(dt => dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
			dt.botId.toLowerCase().includes(searchTxt.toLowerCase()) ||
			dt.acNo.toLowerCase().includes(searchTxt.toLowerCase()))
	}
	const expandedRowRender = (rec) => {
		const innerColumns = [
			{
				title: "#",
				dataIndex: "index",
				key: "index",
				fixed: 'left',
				width: 50
			},
			{
				title: "Date and Time",
				dataIndex: "dateTime",
				key: "dateTime",
				width: 170
			},
			{
				title: "Profit Amount",
				key: "amount",
				dataIndex: "amount",
				width: 170
			},
			{
				title: "Bot Charge",
				key: "botCharge",
				dataIndex: "botCharge",
				render: (_) => `${_}%`,
				width: 170
			},
			{
				title: "Bot Charge Amount",
				key: "botChargeAmount",
				dataIndex: "botChargeAmount",
				width: 200
			}
		];
		const data = rec.chld.map((d,i) => ({
			index: i + 1,
			key: d._id,
			dateTime: moment(d.created_at).format("DD-MM-YYYY HH:mm"),
			botId: rec.botId,
			name: rec.name,
			acNo: rec.acNo,
			broker: rec.broker,
			amount: d.profit,
			botCharge: d.charge_pr,
			botChargeAmount: d.bot_charg,
			dueAmount: rec.dueAmount,
			dueDate: rec.dueDate,
		}))

		return <Table dataSource={data} columns={innerColumns} pagination={false} className='inner-table' />
	}
	const profitFormSubmit = (val) => {
		apiService.post('/profit/update',{
			userId: val.customer,
			amount: val.amount
		}).then(res => {
			message.success(res.message)
			fetchBills()
			form.resetFields()
		})
			.catch(e => message.error(e.message))
	}
	const filterHandle = (val) => {
		fetchProfit({
			date: val.date ? moment(val.date.toString()).format("DD-MM-YYYY") : undefined,
			dueDate: val.dueDate ? moment(val.dueDate.toString()).format("DD-MM-YYYY") : undefined,
			maxDue: val.maxDue,
			maxProf: val.maxProf,
			minDue: val.minDue,
			minProf: val.minProf,
			broker: val.broker
		})

	}
	const resetFilter = () => {
		fetchProfit({})
		form2.resetFields()
	}
	const revertEntry = (val) => {
		if(!val.custId) {
			message.error("Please Select Account")
			return
		}
		apiService.post('/profit/revert-entry',{cust_id: val.custId})
			.then(res => {
				message.success("Entry Reverted")
				fetchBills()
				form1.resetFields()
				setIsEditModalOpen(false)
			})
			.catch(e => {
				message.error(e.message)
			})
	}
	const handleEditCancel = () => {
		setIsEditModalOpen(false);
	};
	return (<>
		<Modal title="Revert Entry"
			open={isEditModalOpen}
			onCancel={() => setIsEditModalOpen(false)}
			footer={[]}
		>
			<Form
				form={form1}
				layout="vertical"
				onFinish={revertEntry}
				name='revertEntry'
			>
				<Form.Item name="custId" style={{marginBottom: 7}} rules={[{required: true,message: 'Select Customer!'}]} >
					<Select
						placeholder="Select Account"
						showSearch
						style={{width: "100%"}}
						options={customerList.map(cst => ({
							value: cst?._id,
							label: `${cst?.account_number} (${cst?.user?.fullName})`
						}))}
						filterOption={(input,option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
							option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
						} />
				</Form.Item>
				<Flex justify='end' style={{marginTop: 7}}>
					<Button type='primary' htmlType='submit' >Revert</Button>
				</Flex>
			</Form>

		</Modal>
		<Typography.Title level={3} className="page-title">Profit</Typography.Title>
		{(permission?.profit == "EDIT" || permission?.profit == "VIEW" || user?.role != "SUBADMIN") ? <>
			<Flex justify='space-between' align='center' style={{marginBottom: "15px"}}>
				<Form
					form={form}
					layout="vertical"
					style={{width: "50%"}}
					onFinish={profitFormSubmit}
				>
					<Row gutter={16}>
						<Col span={10}>
							<Form.Item name="customer" style={{marginBottom: 0}} rules={[{required: true,message: 'Select Customer!'}]} >
								<Select placeholder="Select Account" showSearch options={customerList.map(cst => ({
									value: cst?._id,
									label: `${cst?.account_number} (${cst?.user?.fullName})`
								}))} filterOption={(input,option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
									option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
								} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="amount" style={{marginBottom: 0}} rules={[{required: true,message: 'Please Enter Profit!'}]} >
								<InputNumber min={0} style={{width: "100%"}} placeholder='Enter Profit' />
							</Form.Item>
						</Col>
						<Col>
							<Space>
								<Button htmlType='submit' type='primary'>Add</Button>
								<Button type='primary' onClick={() => setIsEditModalOpen(true)} >Revert Entry</Button>
							</Space>
						</Col>
					</Row>
				</Form>
				<Space>
					<Input value={searchText} style={{marginRight: 5,width: 240}} placeholder="Search by Bot Id / Account Name..." onChange={(e) => setSearchText(e.target.value)} />
					<Button type="primary" style={{marginRight: 5}} onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button>
				</Space>
			</Flex>
			{filterShow && (<Card style={{marginBottom: "20px"}}>
				<Form
					form={form2}
					layout="vertical"
					onFinish={filterHandle}
				>
					<Row gutter={16}>
						<Col span={8}>
							<Form.Item name="date">
								<DatePicker placeholder='Transaction Date' style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name={'minDue'}>
								<InputNumber placeholder='Min Due Amount' style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name={'maxDue'}>
								<InputNumber placeholder="Max Due Amount" style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="dueDate">
								<DatePicker placeholder='Due Date' style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name={'minProf'}>
								<InputNumber placeholder='Min Profit Amount' style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name={'maxProf'}>
								<InputNumber placeholder="Max Profit Amount" style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="broker" style={{marginBottom: 0}}>
								<Select placeholder="Select Broker" showSearch options={brokerList.map(brk => ({
									value: brk?._id,
									label: `${brk?.name} (${brk?.ib_name})`
								}))} filterOption={(input,option) =>
									option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
									option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
								} />
							</Form.Item>
						</Col>
						<Col>
							<Space>
								<Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
								<Button type="default" onClick={resetFilter}>Reset</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</Card>)}
			<Table
				scroll={{x: 1500}}
				loading={isLoading}
				columns={columns}
				dataSource={searchText ? filterData(searchText) : listData}
				expandable={{expandedRowRender,rowExpandable: (rec) => rec?.chld?.length > 0}}
			/>
		</> : <Alert message="You haven't Enough Permission" />}
	</>
	)
}
const mapStateToProps = ({login,loading}) => {
	const {permission,user} = login
	const {isLoading} = loading
	return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(Profit)