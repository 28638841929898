import {Button, Card, Col, DatePicker, Form, Input, message, Row, Select, Upload} from 'antd';
import React, {useState} from 'react'
import AxiosService from '../services/APIService';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import ContactInput from '../components/ContactInput';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

function WlRegister() {
    const apiService = new AxiosService()
    const [form] = Form.useForm()
    const [fileList,setFileList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userPreference,setuserPreference] = useState("Mr.");
    const navigate = useNavigate()
    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const formSubmit = (val) => {
        if(!val.logo || val.logo.fileList.length === 0) {
            message.error("Please upload Logo.")
            return
        }
        if(val.docs.fileList.length === 0) {
            message.error("Please upload at least 1 Document.")
            return
        }
        const formData = new FormData()

        formData.append("fullName",val?.fullname)
        if(val?.phoneNo) formData.append("phone_no",val?.phoneNo)
        formData.append("whatsapp_no",val?.whatsappNo)
        formData.append("street",val?.street)
        formData.append("city",val?.city)
        formData.append("state",val?.state)
        formData.append("country",val?.country)
        formData.append("pincode",val?.pincode)
        formData.append("email",val?.username)
        formData.append("business_email",val?.email)
        formData.append("dob",val?.dob)
        formData.append("password",val?.password)
        formData.append("reference_by",val?.refName)
        formData.append("pref",userPreference)
        if(val?.account_created_date) formData.append("account_created_date",val?.account_created_date)
        formData.append("name", val.whiteLabel)
        formData.append("logo", val.logo.file)        

        val.docs.fileList.forEach((fl,i) => {
            formData.append(`doc${i}`,fl.originFileObj)
        });
        setIsLoading(true)

        apiService.post('auth/create-white-label',formData)
            .then(() => {
                message.success(`User created successfully`);
                navigate('/login')
            })
            .catch((e) => message.error(e.message))
        .finally(() => setIsLoading(false))
    }
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    return (
        <div className="register-wrapper">
            <Card style={{width: "800px"}} title="Registration">
            <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="White label" name="whiteLabel" rules={[{required: true,message: 'Please Enter White label Name!'}]}>
                                <Input placeholder="Enter White label Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Logo" name="logo" rules={[{required: true,message: 'Please Add Logo'}]}>
                                <Upload
                                    beforeUpload={() => false}
                                    accept=".png"
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Full Name" name="fullname" rules={[{required: true,message: 'Please Enter full name!'}]}>
                                <Input addonBefore={<Select style={{width: 70}} onChange={setuserPreference} defaultValue={userPreference} options={[
                                    {value: "Mr.",label: "Mr."},
                                    {value: "Ms.",label: "Ms."},
                                    {value: "Mrs.",label: "Mrs."},
                                ]} />} placeholder="Enter Full Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email Address" name="email" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Street" name={"street"} rules={[{required: true,message: 'Please Enter Street!'}]}>
                                <Input placeholder="Street" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="City" name={"city"} rules={[{required: true,message: 'Please Enter City!'}]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="State" name={"state"} rules={[{required: true,message: 'Please Enter State!'}]}>
                                        <Input placeholder="State" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Country" name={"country"} rules={[{required: true,message: 'Please Enter Country!'}]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Pincode" name={"pincode"} rules={[{required: true,message: 'Please Enter Pincode!'}]}>
                                        <Input placeholder="Pincode" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <ContactInput form={form} name='phoneNo' label='Phone No.' />
                        </Col>
                        <Col span={12}>
                            <ContactInput form={form} name='whatsappNo' label='Whatsapp No.' require={true} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Documents" name="docs" rules={[{required: true,message: 'Documents requires'}]}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Created At" name="account_created_date" >
                                <DatePicker style={{width: '100%'}} defaultValue={dayjs()} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>  
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item label="Date of Birth" name="dob" rules={[{required: true,message: 'Please select date of birth!'}]}>
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Name (Email id)" name="username" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password (user)" name="password" rules={[{required: true,message: 'Please Enter password!'}]}>
                                <Input.Password placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Confirm Password" name="confirmPassword" dependencies={['password']} rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_,value) {
                                        if(!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}>
                                <Input.Password placeholder="Confirm your Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                </Form>
            </Card>
        </div>
    )
}

export default WlRegister