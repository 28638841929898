import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import AxiosService from '../services/APIService';
import {Button, Card, Col, DatePicker, Descriptions, Flex, Form, Image, Input, InputNumber, List, message, Modal, Row, Select, Space, Typography} from 'antd';
import moment from 'moment';
import {connect} from 'react-redux';
import {DownloadOutlined, EditOutlined, EyeOutlined, FileTextOutlined, LeftOutlined} from '@ant-design/icons';
import useFile from '../hooks/useFile';
import {getDocURL} from '../utils/s3Service';
import icon1 from '../assets/icon1.svg';
import icon2 from '../assets/icon2.svg';
import icon3 from '../assets/icon3.svg';
import icon4 from '../assets/icon4.svg';

const UserProfile = ({user,customer,isLoading}) => {
    const { fileList} = useFile()
    const [searchParams] = useSearchParams()
    const userId = searchParams.get("userId")
    const [userData,setUserData] = useState(null);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [botEdit, setBotEdit] = useState(false);
    const [settingEdit, setSettingEdit] = useState(false);
    const [chargeEdit,setChargeEdit] = useState(false);
    const [profitData, setProfitData] = useState(null);
    const axiosService = new AxiosService()
    const [previewImg,setPreviewImg] = useState("");
    const [previewShow,setPreviewShow] = useState(false);
    const editAllowRole = ["ADMIN", "WHITE_LABEL"]
    useEffect(() => {
        if (user?.role === "ADMIN" || user?.role === "WHITE_LABEL") {
            fetchCustomerDetail(userId)
            
        } else if(user?.role === "CUSTOMER"){
            fetchCustomerDetail(customer?._id)
        }
    },[user, userId]);
    useEffect(() => {
        
        if(userData) {
            fetchDashboardData({})
        }
    }, [userData]);
    
    const fetchCustomerDetail = (userID) => {
        axiosService.get(`/customer/get/${userID}`)
            .then(res => {
                const data = res.data
                
                setUserData(data)
            })
        .catch(e => console.log(e))
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const formSubmit = (val) => {
        axiosService.post('/customer/generate-edit-req',{
            note: val.request
        })
        .then(res => {
            message.success(res.message)
            setIsModalOpen(false)
            form.resetFields()
        })
        .catch(e => message.error(e.message))
    }
    const fileSubmit = (val) => {
        axiosService.put(`/customer/set-file/${userData._id}`,{
            file: val.fileName
        })
            .then(res => {
                message.success(res.message)
                setBotEdit(false)
                form1.resetFields()
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const settingSubmit = (val) => {
        axiosService.put(`/customer/save-setting/${userData._id}`,{
            setting: val.setting
        })
            .then(res => {
                message.success(res.message)
                setSettingEdit(false)
                form1.resetFields()
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const chargeSubmit = (val) => {
        axiosService.put(`/customer/set-botcharge/${userData._id}`,{
            charge: val.charge
          })
            .then(res => {
              message.success(res.message)
                setChargeEdit(false)
                form1.resetFields()
                fetchCustomerDetail(userId)
            })
            .catch(e => message.error(e.message))
    }
    const dueDateChange = (val) => {
        axiosService.put(`/profit/bill-date/${userData._id}`,{
            date: val
        })
        .then(res => {
            message.success(res.message)
            fetchCustomerDetail(userId)
        })
        .catch(e => message.error(e.message))
    }
    const imagePreviewOpen = (doc, rec) => {
        let url = getDocURL(`user_doc/${rec}/${doc}`)
        let ext = doc.split('.')[1];
        let imgex = ['jpg', 'jpeg', 'png']
        if (imgex.includes(ext)) {
            setPreviewImg(url)
            setPreviewShow(true)
        } else {
            window.open(url, "_blank")
        }
    }

    const fetchDashboardData = ({startDate,endDate}) => {
        axiosService.get(`/customer/dashboard/${userData?.user?._id}`,{
          startDate,
          endDate
        })
          .then(res => {
            setProfitData(res)
          })
            .catch(e => {
              console.log(e);
        })
      }
    return (
        <div style={{paddingBottom: "30px"}}>
            <div>
                <Link to={user?.role === "ADMIN" ? '/users' : user?.role === "WHITE_LABEL" ? `/wl/users?${searchParams}` : `/dashboard?${searchParams}`} style={{fontSize: 18}}><LeftOutlined /> Back</Link>
            </div>
            <Image
                width={200}
                style={{ display: 'none' }}
                src={previewImg}
                preview={{
                    visible: previewShow,
                    src: previewImg,
                    onVisibleChange: (value) => {
                        setPreviewShow(value);
                    },
                }}
            />
            <Modal title="Edit Request"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={600}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="Request" name="request">
                        <Input.TextArea placeholder="Edit Request" />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
                
            </Modal>
            <Modal title="Update File Name"
                open={botEdit}
                onCancel={() => setBotEdit(false)}
                footer={[]}
                width={600}
            >
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={fileSubmit}
                >
                    <Form.Item label="File Name" name="fileName">
                        <Select
                        placeholder="Select File"
                            options={fileList.map(dt => ({
                                label: dt.file,
                                value: dt._id
                            }))}
                        />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Changes Saved</Button>
                </Form>
            </Modal>
            <Modal title="Update Setting"
                open={settingEdit}
                onCancel={() => setSettingEdit(false)}
                footer={[]}
                width={600}
            >
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={settingSubmit}
                >
                    <Form.Item label="Setting" name="setting">
                        <Select
                        placeholder="Select Setting"
                            options={[
                                {
                                    value: "5-10",
                                    label: "5-10%",
                                },
                                {
                                    value: "10-15",
                                    label: "10-15%",
                                },
                                {
                                    value: "15-20",
                                    label: "15-20%",
                                },
                            ]}
                        />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Changes Saved</Button>
                </Form>
            </Modal>
            <Modal title="Update Charge PR"
                open={chargeEdit}
                onCancel={() => setChargeEdit(false)}
                footer={[]}
                width={600}
            >
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={chargeSubmit}
                >
                    <Form.Item label="Charge" name="charge">
                        <InputNumber placeholder='Enter Charge Amount' style={{width: "100%"}} min={1} max={100}/>
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Changes Saved</Button>
                </Form>
            </Modal>
            {(user?.role === 'CUSTOMER') && <Flex justify='end' style={{marginBottom: 20}} onClick={() => setIsModalOpen(true)}><Button type='primary'>Edit Profile Request</Button></Flex>}
            {(user?.role === 'ADMIN' || user?.role === 'WHITE_LABEL') && <>
                <Card style={{marginBottom: 20}}>
                    <Row gutter={[30,30]}>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block red'>
                                <div className='icon red'><img src={icon1} /></div>
                                <Typography.Title level={2}>{profitData?.accountBalance.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Deposit Balance</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block yellow'>
                                <div className='icon yellow'><img src={icon2} /></div>
                                <Typography.Title level={2}>{profitData?.totalProfit.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Profit</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block green'>
                                <div className='icon green'><img src={icon3} /></div>
                                <Typography.Title level={2}>{profitData?.yourProfit.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Your Profit</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block purple'>
                                <div className='icon purple'><img src={icon4} /></div>
                                <Typography.Title level={2}>{profitData?.botCharge.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Bot Charge</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block purple'>
                                <div className='icon purple'><img src={icon4} /></div>
                                <Typography.Title level={2}>{(profitData?.accountBalance + profitData?.totalProfit).toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Account balance</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block green'>
                                <div className='icon green'><img src={icon3} /></div>
                                <Typography.Title level={2}>{profitData?.totalwithdraw.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Withdraw</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block red'>
                                <div className='icon red'><img src={icon1} /></div>
                                <Typography.Title level={2}>{profitData?.withdrawProfit.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Pending Withdraw From Profit</Typography.Text>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </>}
            
            <Row gutter={16}>
                <Col span={8}>
                    <Card style={{marginBottom: 15}}>
                        <Typography.Title level={5} style={{textAlign: "center"}}>Personal Info</Typography.Title>
                        <List
                            itemLayout="horizontal"
                        >
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Full Name: </Typography.Text>{userData?.user?.fullName}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Email (Username): </Typography.Text>{userData?.user?.fullName}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Password: </Typography.Text>{userData?.user?.password}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Status: </Typography.Text>{userData?.user?.status}</Typography.Paragraph>
                            </List.Item>
                        </List>
                    </Card>
                    <Card style={{marginBottom: 15}} color='#FFE2E5'>
                        <Typography.Title level={5} style={{textAlign: "center"}}>Broker Info</Typography.Title>
                        <List itemLayout="horizontal">
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Name: </Typography.Text>{userData?.broker?.name}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>IB Name: </Typography.Text>{userData?.broker?.ib_name}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>IB Link: </Typography.Text>{userData?.broker?.ib_link}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Portal Link: </Typography.Text>{userData?.broker?.portal_link}</Typography.Paragraph>
                            </List.Item>
                        </List>
                    </Card>
                    <Card>
                        <Typography.Title level={5}>Documents</Typography.Title>
                        <Space>
                            {userData?.docs?.map((d,i) =>
                                <div className='doc-card'>
                                    <Flex>
                                        <Button type="text" onClick={() => imagePreviewOpen(d,userData?.cust_code)}>
                                            <EyeOutlined />
                                        </Button>
                                        <Button type="text" onClick={() => window.open(getDocURL(`user_doc/${userData?.cust_code}/${d}`))}>
                                            <DownloadOutlined />
                                        </Button>
                                    </Flex>
                                </div>
                            )}
                        </Space>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card style={{marginBottom: 15}}>
                        <Typography.Title level={5} style={{textAlign: "center"}}>Other Info</Typography.Title>
                        <List
                            itemLayout="horizontal"
                        >
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Customer Code: </Typography.Text>{userData?.cust_code}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Account Number: </Typography.Text>{userData?.account_number}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Business Email: </Typography.Text>{userData?.business_email}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Phone Number: </Typography.Text>{userData?.contact_no?.phone_no}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Whatsapp Number: </Typography.Text>{userData?.contact_no?.whatsapp_no}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Date of Birth: </Typography.Text>{moment(userData?.dob).format("DD-MM-YYYY")}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Investor's Password: </Typography.Text> {userData?.investor_password}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Master Password: </Typography.Text>{userData?.master_password}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Portal Link: </Typography.Text>{userData?.portal_link}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Portal Password: </Typography.Text> {userData?.portal_password}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Reference By: </Typography.Text>{userData?.reference_by?.fullName}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Server: </Typography.Text>{userData?.server}</Typography.Paragraph>
                            </List.Item>
                        </List>
                    </Card>
                    <Row gutter={16}>
                        {user?.role === 'ADMIN' && <Col span={12}>
                            <Card style={{marginBottom: 15}}>
                                <Typography.Title level={5} style={{textAlign: "center"}}>VPS Server</Typography.Title>
                                <List
                                    itemLayout="horizontal"
                                >
                                    <List.Item>
                                        <Typography.Paragraph className='list-text'><Typography.Text strong>VPS IP: </Typography.Text>{userData?.server_vps?.vps_ip}</Typography.Paragraph>
                                    </List.Item>
                                    <List.Item>
                                        <Typography.Paragraph className='list-text'><Typography.Text strong>Username: </Typography.Text>{userData?.server_vps?.username}</Typography.Paragraph>
                                    </List.Item>
                                    <List.Item>
                                        <Typography.Paragraph className='list-text'><Typography.Text strong>Password: </Typography.Text>{userData?.server_vps?.password}</Typography.Paragraph>
                                    </List.Item>
                                </List>
                            </Card>
                        </Col>}
                        <Col span={12}>
                            <Card>
                                <Typography.Title level={5} style={{textAlign: "center"}}>Bot Performance</Typography.Title>
                                    <List
                                        itemLayout="horizontal"
                                    >
                                        <List.Item>
                                            <Typography.Paragraph className='list-text'><Typography.Text strong>File Name: </Typography.Text>{userData?.file_name?.file}{user?.role == "ADMIN" && <>
                                                <Button onClick={() => setBotEdit(true)} type='text' icon={<EditOutlined />}></Button></>}
                                            </Typography.Paragraph>
                                        </List.Item>
                                        <List.Item>
                                            <Typography.Paragraph className='list-text'><Typography.Text strong>Bot Setting: </Typography.Text>{userData?.setting}% {user?.role == "ADMIN" && <Button onClick={() => setSettingEdit(true)} type='text' icon={<EditOutlined />}></Button>}</Typography.Paragraph>
                                        </List.Item>
                                        <List.Item>
                                            <Typography.Paragraph className='list-text'><Typography.Text strong>Bot Charge: </Typography.Text>{userData?.bot_charge && `${userData?.bot_charge}%`} {editAllowRole.includes(user?.role) && <Button onClick={() => setChargeEdit(true)} type='text' icon={<EditOutlined />}></Button>}</Typography.Paragraph>
                                    </List.Item>
                                    {user?.role === 'ADMIN' &&
                                        <List.Item>
                                            <Typography.Paragraph className='list-text'><Typography.Text strong>Due Date: </Typography.Text><Select
                                            onChange={dueDateChange}
                                            value={userData?.bill_due_date}
                                            style={{
                                                width: "190px"
                                            }}
                                            options={[
                                                {
                                                    value: "",
                                                    label: "None"
                                                },
                                                {
                                                    value: "5",
                                                    label: "5th day of the month"
                                                },
                                                {
                                                    value: "10",
                                                    label: "10th day of the month"
                                                },
                                                {
                                                    value: "15",
                                                    label: "15th day of the month"
                                                },
                                            ]}
                                            /></Typography.Paragraph>
                                        </List.Item>
                                    }
                                    </List>
                            </Card>
                        </Col>
                        
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
const mapStateToProps = ({login, loading}) => {
    const {user,customer} = login
    const {isLoading} = loading
    return {user, customer, isLoading}
  }
export default connect(mapStateToProps, {}) (UserProfile);
