import './App.scss';
import AdminLayout from './components/AdminLayout';
import {Routes,Route,Navigate,useLocation} from "react-router-dom"
import Login from './pages/Login';
import Users from './pages/Users';
import PendingUser from './pages/PendingUser';
import Brokers from './pages/Brokers';
import Server from './pages/Server';
import Team from './pages/Team';
import Profit from './pages/Profit';
import ProtectedRoute from './security/ProtectedRoute';
import AxiosService from './services/APIService';
import {LocalStorageService} from './services/LocalStorage';
import {useEffect} from 'react';
import {isLogin,setUserData,setCustomerData,setWallet,setPermission,setBill,setProfit,setRoute, setWhiteLabelData} from "./actions"
import {connect} from "react-redux"
import UserLayout from './components/UserLayout';
import UserRegistration from './pages/UserRegistration';
import UserDashboard from './pages/UserDashboard';
import UserProfile from './pages/UserProfile';
import CloseUser from './pages/CloseUser';
import DepositHistory from './pages/DepositHistory';
import WithdrawHistory from './pages/WithdrawHistory';
import BotCharge from './pages/BotCharge';
import BotPerformance from './pages/BotPerformance';
import SettingReq from './pages/SettingReq';
import NoteReq from './pages/NoteReq';
import BotFile from './pages/BotFile';
import WithdrawReport from './pages/WithdrawReport';
import DepositReport from './pages/DepositReport';
import BotReport from './pages/BotReport';
import {setBotcharge} from './actions/botChargeAction';
import Announcement from './pages/Announcement';
import AdminDashboard from './pages/AdminDashboard';
import QrCode from './pages/QrCode';
import WhiteLabelLayout from "./components/WhiteLabelLayout"
import AWhiteLabelDashboard from "./pages/whiteLabelAdmin/Dashboard"
import WhiteLabels from './pages/whiteLabelAdmin/WhiteLabels';
import PendingWhiteLabel from './pages/whiteLabelAdmin/PendingWhiteLabel';
import CloseWhiteLabel from './pages/whiteLabelAdmin/CloseWhiteLabel';
import WLDepositReport from './pages/whiteLabelAdmin/WLDepositReport';
import WLWithdrawReport from './pages/whiteLabelAdmin/WLWithdrawReport';
import WLBotChargeReport from './pages/whiteLabelAdmin/WLBotChargeReport';
import WLProfit from './pages/whiteLabelAdmin/WLProfit';
import WLBotFile from './pages/whiteLabelAdmin/WLBotFile';
import WLAnnouncement from './pages/whiteLabelAdmin/WLAnnouncement';
import WLLayout from './components/WLLayout';
import webSocketService from './services/websocketService';
import WLQrCode from './pages/whiteLabelAdmin/WLQrCode';
import WLUserList from './pages/whiteLabelAdmin/WLUserList';
import WLPendingUserList from './pages/whiteLabelAdmin/WLPendingUserList';
import WLCloseUserList from './pages/whiteLabelAdmin/WLCloseUserList';
import WLBroker from './pages/whiteLabelAdmin/WLBroker';
import WLServer from './pages/whiteLabelAdmin/WLServer';
import WLLevel from './pages/whiteLabelAdmin/WLLevel';
import PayBill from './pages/whiteLabel/PayBill';
import WLBillReport from './pages/whiteLabelAdmin/WLBillReport';
import WlRegister from './pages/WlRegister';
import WhiteLabelProfile from './pages/whiteLabel/WLProfile';
import Leads from './pages/whiteLabel/Leads';
import Inquiry from './pages/whiteLabel/Inquiry';
import BotSettings from './pages/BotSettings';
import ResetPassword from './pages/ResetPassword';

function App({isLogin,setUserData, setWhiteLabelData,setWallet,setPermission,user,setBill,setProfit,setBotcharge,setRoute, setCustomerData}) {
    const location = useLocation();
    const axiosService = new AxiosService();
    useEffect(() => {
        const token = LocalStorageService.getItem("userToken")
        document.title = 'AIFXBRAIN V1.0';
        if(token) {
            checkAuthentication()
        } else {
            isLogin(false)
            setUserData(null)
        }
        webSocketService.connect(process.env.REACT_APP_SOCKET_URL)
    },[]);
    useEffect(() => {
        setRoute(location.pathname);
    },[location])

    const checkAuthentication = async () => {
        axiosService.get("/auth/me").then((res) => {
            isLogin(true)
            setUserData(res?.user)
            if(res?.user?.role === "CUSTOMER") {
                setCustomerData(res?.customer)
                setWallet(res?.wallet)
                setBill(res?.bill)
                setProfit(res?.totalProfit)
                setBotcharge(res?.botCharge)
            }
            if(res?.user?.role === "WHITE_LABEL") {
                setWhiteLabelData(res?.white_label)
                setBill(res?.bill)
            }
            if(res?.user?.role === "SUBADMIN") {
                setPermission(res?.permission)
            }
        })
            .catch(() => {
                isLogin(false)
                setUserData(null)
                LocalStorageService.removeItem("userToken")
            })
    }
    const navigationTo = () => {
        const userData = LocalStorageService.getItem("user");
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        const token = tokenParam || LocalStorageService.getItem("userToken")
        if(userData && token) {
            if(userData?.role === "ADMIN" && !tokenParam) {
                return "/home"
            } else if(userData?.role === "ADMIN" && tokenParam) {
                return `/dashboard?token=${tokenParam}`

            } else {
                return "/dashboard"
            }
        }
        return "/login"
    }
    return (
        <Routes>
            <Route path="/login" Component={Login} />
            <Route path="/reset-password" Component={ResetPassword} />

            <Route path='/home' element={<ProtectedRoute layout={AdminLayout} component={AdminDashboard} role='admin' />} />
            <Route path='/users' element={<ProtectedRoute layout={AdminLayout} component={Users} role='admin' />} />
            <Route path='/pending-users' element={<ProtectedRoute layout={AdminLayout} component={PendingUser} role='admin' />} />
            <Route path='/close-user' element={<ProtectedRoute layout={AdminLayout} component={CloseUser} role='admin' />} />
            <Route path='/deposit-report' element={<ProtectedRoute layout={AdminLayout} component={DepositReport} role='admin' />} />
            <Route path='/withdraw-report' element={<ProtectedRoute layout={AdminLayout} component={WithdrawReport} role='admin' />} />
            <Route path='/botcharge-report' element={<ProtectedRoute layout={AdminLayout} component={BotReport} role='admin' />} />
            <Route path='/broker' element={<ProtectedRoute layout={AdminLayout} component={Brokers} role='admin' />} />
            <Route path='/user-profile' element={<ProtectedRoute layout={AdminLayout} component={UserProfile} role='admin' />} />
            <Route path='/server' element={<ProtectedRoute layout={AdminLayout} component={Server} role='admin' />} />
            {user?.role === "ADMIN" && <Route path='/team' element={<ProtectedRoute layout={AdminLayout} component={Team} role='admin' />} />}
            <Route path='/profit' element={<ProtectedRoute layout={AdminLayout} component={Profit} role='admin' />} />
            <Route path='/note-request' element={<ProtectedRoute layout={AdminLayout} component={NoteReq} role='admin' />} />
            <Route path='/setting-request' element={<ProtectedRoute layout={AdminLayout} component={SettingReq} role='admin' />} />
            <Route path='/bot-file' element={<ProtectedRoute layout={AdminLayout} component={BotFile} role='admin' />} />
            <Route path='/announcement' element={<ProtectedRoute layout={AdminLayout} component={Announcement} role='admin' />} />
            <Route path='/qr-code' element={<ProtectedRoute layout={AdminLayout} component={QrCode} role='admin' />} />
            <Route path='/bot-performance' element={<ProtectedRoute layout={AdminLayout} component={BotSettings} role='admin' />} />

            {/* admin white label */}

            <Route path='/wl-registration' Component={WlRegister} />

            <Route path='/white-label/home' element={<ProtectedRoute layout={AdminLayout} component={AWhiteLabelDashboard} role='admin' />} />
            <Route path='/white-label/list' element={<ProtectedRoute layout={AdminLayout} component={WhiteLabels} role='admin' />} />
            <Route path='/white-label/pending' element={<ProtectedRoute layout={AdminLayout} component={PendingWhiteLabel} role='admin' />} />
            <Route path='/white-label/close' element={<ProtectedRoute layout={AdminLayout} component={CloseWhiteLabel} role='admin' />} />
            <Route path='/white-label/user-list' element={<ProtectedRoute layout={AdminLayout} component={WLUserList} role='admin' />} />
            <Route path='/white-label/pending-user' element={<ProtectedRoute layout={AdminLayout} component={WLPendingUserList} role='admin' />} />
            <Route path='/white-label/close-user' element={<ProtectedRoute layout={AdminLayout} component={WLCloseUserList} role='admin' />} />
            <Route path='/white-label/deposit-report' element={<ProtectedRoute layout={AdminLayout} component={WLDepositReport} role='admin' />} />
            <Route path='/white-label/withdraw-report' element={<ProtectedRoute layout={AdminLayout} component={WLWithdrawReport} role='admin' />} />
            <Route path='/white-label/botcharge-report' element={<ProtectedRoute layout={AdminLayout} component={WLBotChargeReport} role='admin' />} />
            <Route path='/white-label/profit' element={<ProtectedRoute layout={AdminLayout} component={WLProfit} role='admin' />} />
            <Route path='/white-label/bot-file' element={<ProtectedRoute layout={AdminLayout} component={WLBotFile} role='admin' />} />
            <Route path='/white-label/announcement' element={<ProtectedRoute layout={AdminLayout} component={WLAnnouncement} role='admin' />} />           
            <Route path='/white-label/qr-code' element={<ProtectedRoute layout={AdminLayout} component={WLQrCode} role='admin' />} />           
            <Route path='/white-label/broker' element={<ProtectedRoute layout={AdminLayout} component={WLBroker} role='admin' />} />
            <Route path='/white-label/server' element={<ProtectedRoute layout={AdminLayout} component={WLServer} role='admin' />} />
            <Route path='/white-label/level' element={<ProtectedRoute layout={AdminLayout} component={WLLevel} role='admin' />} />
            <Route path='/white-label/bill-report' element={<ProtectedRoute layout={AdminLayout} component={WLBillReport} role='admin' />} />

            {/* White Label */}

            <Route path='/leads' element={<Leads />} />
            <Route path='/wl/home' element={<ProtectedRoute layout={WLLayout} component={AdminDashboard} role='white_label' />} />
            <Route path='/wl/users' element={<ProtectedRoute layout={WLLayout} component={Users} role='white_label' />} />
            <Route path='/wl/pending-users' element={<ProtectedRoute layout={WLLayout} component={PendingUser} role='white_label' />} />
            <Route path='/wl/close-user' element={<ProtectedRoute layout={WLLayout} component={CloseUser} role='white_label' />} />
            <Route path='/wl/deposit-report' element={<ProtectedRoute layout={WLLayout} component={DepositReport} role='white_label' />} />
            <Route path='/wl/withdraw-report' element={<ProtectedRoute layout={WLLayout} component={WithdrawReport} role='white_label' />} />
            <Route path='/wl/botcharge-report' element={<ProtectedRoute layout={WLLayout} component={BotReport} role='white_label' />} />
            <Route path='/wl/user-profile' element={<ProtectedRoute layout={WLLayout} component={UserProfile} role='white_label' />} />
            <Route path='/wl/broker' element={<ProtectedRoute layout={WLLayout} component={Brokers} role='white_label' />} />
            <Route path='/wl/profit' element={<ProtectedRoute layout={WLLayout} component={Profit} role='white_label' />} />
            <Route path='/wl/note-request' element={<ProtectedRoute layout={WLLayout} component={NoteReq} role='white_label' />} />
            <Route path='/wl/setting-request' element={<ProtectedRoute layout={WLLayout} component={SettingReq} role='white_label' />} />
            <Route path='/wl/announcement' element={<ProtectedRoute layout={WLLayout} component={Announcement} role='white_label' />} />
            <Route path='/wl/my-bill' element={<ProtectedRoute layout={WLLayout} component={PayBill} role='white_label' />} />
            <Route path='/wl/my-profile' element={<ProtectedRoute layout={WLLayout} component={WhiteLabelProfile} role='white_label' />} />
            <Route path='/wl/inquiry' element={<ProtectedRoute layout={WLLayout} component={Inquiry} role='white_label' />} />
            
            {/* user routes */}

            <Route path='/registration' Component={UserRegistration} />


            <Route path='/dashboard' element={<ProtectedRoute layout={UserLayout} component={UserDashboard} role='user' />} />
            <Route path='/deposit' element={<ProtectedRoute layout={UserLayout} component={DepositHistory} role='user' />} />
            <Route path='/withdraw' element={<ProtectedRoute layout={UserLayout} component={WithdrawHistory} role='user' />} />
            <Route path='/bot-charge' element={<ProtectedRoute layout={UserLayout} component={BotCharge} role='user' />} />
            <Route path='/bot-performance' element={<ProtectedRoute layout={UserLayout} component={BotPerformance} role='user' />} />
            <Route path='/profile' element={<ProtectedRoute layout={UserLayout} component={UserProfile} role='user' />} />
            <Route path='/' element={<Navigate to={navigationTo()} />} />
        </Routes>
    );
}
const mapStateToProps = ({login}) => {
    const {user} = login
    return {user}
}
const mapDispatchToProps = {
    setUserData,
    isLogin,
    setCustomerData,
    setWallet,
    setPermission,
    setBill,
    setProfit,
    setBotcharge,
    setRoute,
    setWhiteLabelData
}
export default connect(mapStateToProps,mapDispatchToProps)(App);
