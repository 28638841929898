import { SET_BILL, SET_PROFIT } from "../actions/billAction";

const initialState = {
  billDetail: null,
  totalProfit: 0
  };
const billReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BILL:
      return {
        ...state,
        billDetail: action.payload,
      };
    case SET_PROFIT:
      return {
        ...state,
        totalProfit: action.payload,
      };

    default:
      return state;
  }
};

export default billReducer;