import {Button, Flex, Form, Input, message, Modal, Select, Space, Table, Typography} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import AxiosService from '../../services/APIService';
import {connect} from 'react-redux';
import useWhiteLabel from '../../hooks/useWhiteLabel';
import dayjs from 'dayjs';

function WLBotFile({isLoading, user}) {
    const [editId,setEditId] = useState("");
    const {whiteLabel} = useWhiteLabel()
    const apiService = new AxiosService()
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [form] = Form.useForm()
    const [listData,setListData] = useState([]);
    useEffect(() => {
        fetchData({})
    },[]);
    const fetchData = () => {
        apiService.get('/files/list-wl')
        .then(res => {
            const data = res.data.map((dt,i) => ({
                key: i,
                index: i + 1,
                whiteLabel: dt.white_label.name,
                data: dt.files
            }))
            setListData(data)
        })
        .catch(e => console.log(e))
    }
    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            width: 50
        },
        {
            title: "White Label",
            dataIndex: "whiteLabel",
            key: "whiteLabel",
        }
    ]
    const expandedRowRender = (val) => {
        const col = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
            },
            {
                title: "File Name",
                dataIndex: "filename",
                key: "filename",
            },
            {
                title: "Date",
                dataIndex: "date",
                key: "date",
            },
            {
                title: "By",
                key: "by",
                dataIndex: "by",
            },
            {
                title: "Action",
                key: "action",
                dataIndex: "action",
                render: (_, rec) => <Space>
                    <Button size='small' onClick={() => editHandle(_, rec)}>Edit</Button>
                    <Button size='small' onClick={() => deleteHandle(_)} danger>Delete</Button>
                </Space>,
                width: "100px"
            }
            
        ];
        const fileList = val.data.map((dt, i) => ({
            index: i + 1,
            filename: dt.file,
            date: dayjs(dt.created_at).format("DD-MM-YYYY"),
            by: `${dt?.created_by?.fullName} (${dt?.created_by?.role})`,
            action: dt._id
        }))

        return <Table columns={col} dataSource={fileList} />
    }
    const editHandle = (val,rec) => {
        setEditId(val)
        setIsModalOpen(true)
        form.setFieldValue("fileName", rec.filename)
    }
    const deleteHandle = (val) => {
        apiService.delete(`/files/delete/${val}`)
        .then(res => {
            message.success(res.message)
            fetchData({})
        })
        .catch(e => {
            message.error(e.message)    
        })
    }
    const formSubmit = (val) => {
        if(editId) {
            apiService.put(`/files/update/${editId}`,{
                fileName: val.fileName,
            })
            .then(res => {
                message.success(res.message)
                setIsModalOpen(false)
                fetchData({})
                setEditId("")
            })
            .catch(e => {
                message.error(e.message)    
            })
        } else {
            apiService.post('/files/create',{
                fileName: val.fileName,
                whiteLabel: val.whiteLabel
            })
            .then(res => {
                message.success(res.message)
                setIsModalOpen(false)
                fetchData({})
            })
            .catch(e => {
                message.error(e.message)    
            })
        }
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setEditId("")
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setEditId("")
    };
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
    };
    return (
        <>
            <Typography.Title level={3} className="page-title">Bot File</Typography.Title>
            <Modal title={editId ? "Update File" :"Create File"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="File Name" name={"fileName"} rules={[{required: true, message: "File Name required"}]}>
                        <Input placeholder="Enter File Name" />
                    </Form.Item>
                    {!editId && <Form.Item label="White Label" name="whiteLabel" rules={[{required: true, message: "White Label required"}]}>
                        <Select placeholder="Select White Label" showSearch options={whiteLabel.map(wl => ({
                            value: wl?._id,
                            label: `${wl?.name}`
                        }))}
                            filterOption={(input,option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } />
                    </Form.Item>}
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Flex justify="end" style={{marginBottom: "20px"}}>
                {/* <Input style={{marginRight: 5,maxWidth: 200}} placeholder="Search by File Name..." onChange={(e) => setSearchText(e.target.value)} /> */}
                {/* <Button type="default" style={{marginRight: 5}}><FilterOutlined /></Button> */}
                <Button type="primary" onClick={showModal}>
                    Create File
                </Button>
            </Flex>
            <Table
                columns={columns}
                dataSource={listData}
                expandable={{expandedRowRender}}
            />
        </>
    )
}
const mapStateToProps = ({loading, login}) => {
    const {isLoading} = loading
    const {user} = login
    return {isLoading, user}
}
export default connect(mapStateToProps,{}) (WLBotFile)