import React, {useEffect, useState} from 'react'
import APIService from "../services/APIService"
export default function useServer(params) {
    const apiService = new APIService()
    const [serverList, setServerList] = useState([])
    useEffect(() => {
        fetchServer()
    },[])
    
    const fetchServer = () => {
        apiService.get('/server/list', params)
            .then(res => {
                setServerList(res.data)
            })
            .catch(e => {
                console.log(e);
            })

    }

    return {
        serverList,
        fetchServer
    }
  
}
