import AWS from "aws-sdk"
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
});
const s3 = new AWS.S3();

const bucketName = 'aifxbrain';
export const getDocURL = (key) => {
    try {
        const urlParams = {
            Bucket: bucketName,
            Key: key
          };
        const url = s3.getSignedUrl('getObject',urlParams);
        return url
    } catch (error) {
        return false
    }
}

export const deleteDoc = (key) => {
    try {
        const params = {
            Bucket: bucketName,
            Key: key
        };
        s3.deleteObject(params);
        return true
    } catch (error) {
        return false
    }
}