import React, {useEffect, useState} from 'react'
import APIService from "../services/APIService"
export default function useBills() {
    const apiService = new APIService()
    const [billList, setBillList] = useState([])
    useEffect(() => {
        fetchBills()
    },[])
    
    const fetchBills = (params) => {
        apiService.get('/profit/bill-list',params)
            .then(res => {
                setBillList(res.data)
            })
            .catch(e => {
                console.log(e);
            })
    }

    return {
        billList,
        fetchBills
    }
  
}
