import React, {useEffect, useState} from 'react'
import AxiosService from '../services/APIService';

function useFile() {
    const [fileList,setFileList] = useState([]);
    const apiService = new AxiosService()

    useEffect(() => {
        fetchFileList({})
    }, []);

    const fetchFileList = ({status}) => {
        apiService.get('/files/list',{
            status: status || "APPROVE"
        })
        .then(res => {
            setFileList(res.data)
        })
        .catch(e => console.log(e))
    }

    return {
        fileList,
        fetchFileList
    }
}

export default useFile