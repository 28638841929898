import React, {useEffect, useState} from 'react'
import APIService from "../services/APIService"
export default function useCustomer(query) {
    const apiService = new APIService()
    const [customerList, setCustomerList] = useState([])
    useEffect(() => {
        fetchCustomer()
    },[])
    
    const fetchCustomer = () => {
        apiService.get('/customer/list',{
            status: query?.status,
            ...query
        })
            .then(res => {
                setCustomerList(res.data)
            })
            .catch(e => {
                console.log(e);
            })

    }

    return {
        customerList,
        fetchCustomer
    }
  
}
