import React,{useEffect,useState} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    UserSwitchOutlined,
    TransactionOutlined,
    BankOutlined,
    BarChartOutlined,
    DatabaseOutlined,
    DeploymentUnitOutlined,
    DownOutlined,
    UserDeleteOutlined,
    BookOutlined,
    SettingOutlined,
    NotificationOutlined,
    ProductOutlined,
    QrcodeOutlined
} from '@ant-design/icons';
import {Layout,Menu,Button,theme,Dropdown,Space,Flex,Avatar} from 'antd';
import logo from '../assets/whiteLogo.png';
import logoSm from '../assets/logoSm.png';
import {Link,useNavigate} from 'react-router-dom';
import {useLocation} from "react-router-dom"
import {connect} from "react-redux"
import {LocalStorageService} from '../services/LocalStorage';
import {isLogin,setUserData} from '../actions';
import Notification from './Notification';
import useCustomer from '../hooks/useCustomer';
import AxiosService from '../services/APIService';
import webSocketService from '../services/websocketService';

const { Header, Sider, Content } = Layout;

function WhiteLabelLayout({currentRoute, user, children}) {
    const [currentActive,setCurrentActive] = useState('1')
    const apiService = new AxiosService()
    const [collapsed,setCollapsed] = useState(false);
    const [userCount,setUserCount] = useState({
        pendingUser: 0,
        user: 0,
        closeUser: 0,
        editReq: 0,
        settingReq: 0
    });
    const navigate = useNavigate()
    const {
        token: {colorBgContainer,borderRadiusLG},
    } = theme.useToken();

    const activeLink = (route) => {

        switch(route) {
            case "/white-label/list":
                setCurrentActive("1");
                break;
            case "/white-label/pending":
                setCurrentActive("2");
                break;
            case "/white-label/close":
                setCurrentActive("3");
                break;
            case "/white-label/deposit-report":
                setCurrentActive("4-1");
                break;
            case "/white-label/withdraw-report":
                setCurrentActive("4-2");
                break;
            case "/white-label/botcharge-report":
                setCurrentActive("4-3");
                break;
            case "/white-label/profit":
                setCurrentActive("5");
                break;
            case "/white-label/bot-file":
                setCurrentActive("10");
                break;
            case "/white-label/announcement":
                setCurrentActive("11");
                break;
            case "/white-label/home":
                setCurrentActive("12");
                break;
            case "/white-label/qr-code":
                setCurrentActive("13");
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        activeLink(currentRoute)
    },[currentRoute])


    const items = [
        {
            label: "Logout",
            key: '0',
        }
    ];
    const menuClick = (val) => {
        switch(val?.key) {
            case "0":
                logoutHandle()
                break;

            default:
                break;
        }
    }
    useEffect(() => {
        webSocketService.onMessage('counterHit',() => {
            fetchCounters()
        });
        fetchCounters()
    }, []);
    const fetchCounters = () => {
        apiService.get('/counter')
            .then(res => {
                setUserCount({
                    closeUser: res.closedUsers,
                    pendingUser: res.pendingUsers,
                    user: res.users,
                    settingReq: res.settingReq,
                    editReq: res.editReq,
                })  
            })
            .catch(e => {
                console.log(e);
            })
    }
    const logoutHandle = () => {
        LocalStorageService.removeItem("userToken")
        LocalStorageService.removeItem("user")
        setUserData(null)
        isLogin(false)
        navigate('/login')
    }
    const horizontalMenuItem = [
        {
            key: '1',
            label: ( <Link style={{color: "#fff", fontFamily: "faster"}} to={'/home'}>Contract Base</Link> ),
        },
        {
            key: '2',
            label: (<Link style={{color: "#fff", fontFamily: "faster"}} to={'/white-label/home'}>White Label</Link>),
        },
    ]
    return (
        <Layout style={{height: "100%"}}>
            <Sider
                theme='dark'
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={230}
                style={{
                    background: "#131c2b"
                }}
            >
                <div className="demo-logo-vertical sidebar-logo">
                    <img src={collapsed ? logoSm : logo} style={{width: `${collapsed ? "auto" : "100%"}`,height: `${collapsed ? "40px" : "auto"}`}} />
                </div>
                {/* <Menu
                    mode="inline"
                    theme='dark'
                    selectedKeys={currentActive}
                    items={menuItems}
                    defaultOpenKeys={['4','9']}
                    style={{
                        background: "#131c2b",
                    }}
                /> */}
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: '0PX 20PX',
                        background: "#131c2b",
                        lineHeight: '63px'
                    }}
                >
                    <Flex justify='space-between'>
                        <Space size={'large'}>
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined style={{color: "#fff"}} /> : <MenuFoldOutlined style={{color: "#fff"}} />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <Menu theme='dark' selectedKeys={['2']} mode="horizontal" items={horizontalMenuItem} />
                        </Space>
                        <Space size={20}>
                            <Notification />
                            <Dropdown
                                menu={{
                                    items,
                                    onClick: menuClick
                                }}
                                trigger={['click']}

                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space style={{color: "#fff"}} className="user-name">
                                        {user?.fullName}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </Space>
                    </Flex>

                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        background: "rgb(220 220 220 / 52%)",
                        borderRadius: borderRadiusLG,
                        minHeight: "1000px"
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}
const mapStateToProps = ({login,url}) => {
    const {user} = login
    const {currentRoute} = url
    return {user,currentRoute}
}
const mapDispatchToProps = {
    isLogin,
    setUserData
}
export default connect(mapStateToProps,mapDispatchToProps) (WhiteLabelLayout)