import React,{useEffect,useState} from 'react';
import {Link,useSearchParams} from 'react-router-dom';
import AxiosService from '../../services/APIService';
import {Button,Card,Col,Flex,Image,List,Row,Space,Typography} from 'antd';
import moment from 'moment';
import {connect} from 'react-redux';
import {DownloadOutlined,EyeOutlined,LeftOutlined} from '@ant-design/icons';
import {getDocURL} from '../../utils/s3Service';
import icon1 from '../../assets/icon1.svg';
import icon2 from '../../assets/icon2.svg';
import icon3 from '../../assets/icon3.svg';
import icon4 from '../../assets/icon4.svg';

const WhiteLabelProfile = ({user,whiteLabel}) => {
    const [searchParams] = useSearchParams()
    const userId = searchParams.get("userId")
    const [userData,setUserData] = useState(null);
    const [profitData,setProfitData] = useState(null);
    const axiosService = new AxiosService()
    const [previewImg,setPreviewImg] = useState("");
    const [previewShow,setPreviewShow] = useState(false);
    useEffect(() => {
        if(user?.role === "ADMIN") {
            fetchWhiteLabelDetail(userId)

        } else if(user?.role === "WHITE_LABEL") {
            fetchWhiteLabelDetail(whiteLabel?._id)
        }
    },[user,userId]);
    useEffect(() => {

        if(userData) {
            fetchDashboardData({})
        }
    },[userData]);

    const fetchWhiteLabelDetail = (userID) => {
        axiosService.get(`/white-label/get/${userID}`)
            .then(res => {
                const data = res.data
                setUserData(data)
            })
            .catch(e => console.log(e))
    }
    const imagePreviewOpen = (doc,rec) => {
        let url = getDocURL(`user_doc/${rec}/${doc}`)
        let ext = doc.split('.')[1];
        let imgex = ['jpg','jpeg','png']
        if(imgex.includes(ext)) {
            setPreviewImg(url)
            setPreviewShow(true)
        } else {
            window.open(url,"_blank")
        }
    }

    const fetchDashboardData = ({startDate,endDate}) => {
        axiosService.get(`/white-label/dashboard/${userData?.user?._id}`,{
            startDate,
            endDate
        })
            .then(res => {
                setProfitData(res)
            })
            .catch(e => {
                console.log(e);
            })
    }
    return (
        <div style={{paddingBottom: "30px"}}>
            <div>
                <Link to={user?.role === "ADMIN" ? '/users' : user?.role === "WHITE_LABEL" ? `/wl/users?${searchParams}` : `/dashboard?${searchParams}`} style={{fontSize: 18}}><LeftOutlined /> Back</Link>
            </div>
            <Image
                width={200}
                style={{display: 'none'}}
                src={previewImg}
                preview={{
                    visible: previewShow,
                    src: previewImg,
                    onVisibleChange: (value) => {
                        setPreviewShow(value);
                    },
                }}
            />
            {user?.role === 'ADMIN' && <>
                <Card style={{marginBottom: 20}}>
                    <Row gutter={[30,30]}>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block red'>
                                <div className='icon red'><img src={icon1} /></div>
                                <Typography.Title level={2}>{profitData?.accountBalance.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Deposit Balance</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block yellow'>
                                <div className='icon yellow'><img src={icon2} /></div>
                                <Typography.Title level={2}>{profitData?.totalProfit.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Profit</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block green'>
                                <div className='icon green'><img src={icon3} /></div>
                                <Typography.Title level={2}>{profitData?.yourProfit.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Your Profit</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block purple'>
                                <div className='icon purple'><img src={icon4} /></div>
                                <Typography.Title level={2}>{profitData?.botCharge.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Bot Charge</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block purple'>
                                <div className='icon purple'><img src={icon4} /></div>
                                <Typography.Title level={2}>{(profitData?.accountBalance + profitData?.totalProfit).toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Account balance</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block green'>
                                <div className='icon green'><img src={icon3} /></div>
                                <Typography.Title level={2}>{profitData?.totalwithdraw.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Total Withdraw</Typography.Text>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={24}>
                            <div className='summery-block red'>
                                <div className='icon red'><img src={icon1} /></div>
                                <Typography.Title level={2}>{profitData?.withdrawProfit.toLocaleString("hi-IN")}</Typography.Title>
                                <Typography.Text>Pending Withdraw From Profit</Typography.Text>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </>}

            <Row gutter={16}>
                <Col span={8}>
                    <Card style={{marginBottom: 15}}>
                        <Typography.Title level={5} style={{textAlign: "center"}}>Personal Info</Typography.Title>
                        <List
                            itemLayout="horizontal"
                        >
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Full Name: </Typography.Text>{userData?.user?.fullName}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Email (Username): </Typography.Text>{userData?.user?.email}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Password: </Typography.Text>{userData?.user?.password}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Status: </Typography.Text>{userData?.user?.status}</Typography.Paragraph>
                            </List.Item>
                        </List>
                    </Card>
                    <Card>
                        <Typography.Title level={5}>Documents</Typography.Title>
                        <Space>
                            {userData?.docs?.map((d,i) =>
                                <div className='doc-card'>
                                    <Flex>
                                        <Button type="text" onClick={() => imagePreviewOpen(d,userData?.cust_code)}>
                                            <EyeOutlined />
                                        </Button>
                                        <Button type="text" onClick={() => window.open(getDocURL(`user_doc/${userData?.cust_code}/${d}`))}>
                                            <DownloadOutlined />
                                        </Button>
                                    </Flex>
                                </div>
                            )}
                        </Space>
                    </Card>
                </Col>
                <Col span={16}>
                    <Card style={{marginBottom: 15}}>
                        <Typography.Title level={5} style={{textAlign: "center"}}>Other Info</Typography.Title>
                        <List
                            itemLayout="horizontal"
                        >
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Business Email: </Typography.Text>{userData?.business_email}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Phone Number: </Typography.Text>{userData?.contact_no?.phone_no}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Whatsapp Number: </Typography.Text>{userData?.contact_no?.whatsapp_no}</Typography.Paragraph>
                            </List.Item>
                            <List.Item>
                                <Typography.Paragraph className='list-text'><Typography.Text strong>Date of Birth: </Typography.Text>{moment(userData?.dob).format("DD-MM-YYYY")}</Typography.Paragraph>
                            </List.Item>
                        </List>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
const mapStateToProps = ({login,loading}) => {
    const {user,whiteLabel} = login
    const {isLoading} = loading
    return {user,isLoading,whiteLabel}
}
export default connect(mapStateToProps,{})(WhiteLabelProfile);