import React,{useEffect,useState} from "react";
import {Table,Select,Form,Input,Modal,Button,Flex,Row,Col,Upload,message,DatePicker,Space,Alert,Card,Popconfirm,Image,Typography,Tooltip} from "antd";
import {InboxOutlined,CloseOutlined,EditOutlined,FilterOutlined,PlusOutlined,LoginOutlined} from "@ant-design/icons";
import useBroker from "../../hooks/useBroker";
import useServer from "../../hooks/useServer";
import useCustomer from "../../hooks/useCustomer";
import AxiosService from "../../services/APIService";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {deleteDoc,getDocURL} from "../../utils/s3Service";
import dayjs from 'dayjs'
import ContactInput from "../../components/ContactInput";

const {Option} = Select;
const wlCol = [
    {
        title: "#",
        dataIndex: "index",
        key: "index",
        width: 50
    },
    {
        title: "White Label",
        dataIndex: "whiteLabel",
        key: "whiteLabel",
    }
]
function WLUserList({permission,user,isLoading}) {
    const apiService = new AxiosService()
    const [isEditModalOpen,setIsEditModalOpen] = useState(false);
    const [createdUserList,setCreatedUserList] = useState([]);
    const {brokerList} = useBroker({allWhiteLabel: true})
    const {serverList} = useServer({allWhiteLabel: true})
    const {customerList} = useCustomer({})
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [form2] = Form.useForm()
    const [setUpdateData,setSetUpdateData] = useState(null);
    const [filterShow,setFilterShow] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [userPreference,setuserPreference] = useState("Mr.");
    const [fileList,setFileList] = useState([]);
    const [previewImg,setPreviewImg] = useState("");
    const [previewShow,setPreviewShow] = useState(false);

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            fixed: 'left',
            width: "50px"
        },
        {
            title: "Bot Id",
            dataIndex: "cutId",
            key: "custId",
            fixed: 'left',
            width: "100px"
        },
        {
            title: "Account Name",
            dataIndex: "name",
            key: "name",
            fixed: 'left',
            render: (e,rec) => <Link to={`/user-profile?userId=${rec.key}`}> {e}</Link>
        },
        {
            title: "Account Number",
            dataIndex: "accNo",
            key: "accNo",
        },
        {
            title: "Bot Status",
            key: "botStatus",
            dataIndex: "botStatus",
            render: (val,rec) => <>
                <Flex align="center">
                    <span style={{height: 10,minWidth: 10,width: 10,borderRadius: "50%",backgroundColor: `${val ? "#37ff21" : "#ff3b3b"}`,marginRight: 5}}></span>
                    <Select
                        value={val}
                        style={{width: "100%"}}
                        onChange={(e) => changeBotStatus(e,rec)}
                        options={[{value: true,label: "Activate"},{value: false,label: "Deactivate"}]}
                    />
                </Flex>
            </>,
            width: "170px"
        },
        {
            title: "Account Status",
            key: "status",
            dataIndex: "status",
            render: (e,rec) => <Select
                defaultValue={e}
                value={e}
                style={{width: "100%"}}
                onChange={(val) => statusChange(val,rec)}
                options={[
                    {value: 'ACTIVE',label: 'Activate'},
                    {value: 'DEACTIVE',label: 'Deactivate'}
                ]}
            />,
            width: "170px"
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e,rec) => (permission?.customer === "EDIT" || user?.role !== "SUBADMIN") &&
                <Space>
                    <Tooltip title="Login">
                        <Button type="default" icon={<LoginOutlined />} size={"middle"} onClick={() => loginHandle(rec)} />
                    </Tooltip>
                    <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => editOpen(rec)} />
                    <Popconfirm
                        description="Are you sure you want to close this account ?"

                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => onCloseCustomer(rec.key)}
                    >
                        <Tooltip title="Deactivate account status and bot status to close this account">
                            <Button icon={<CloseOutlined />} danger size={"middle"} disabled={rec.record.botStatus || rec.record.user.status == "ACTIVE"} />
                        </Tooltip>
                    </Popconfirm>
                </Space>
        },
        {
            title: "IB Name",
            dataIndex: "ibName",
            key: "ibName",
        },
        {
            title: "Broker",
            dataIndex: "broker",
            key: "broker",
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Password",
            dataIndex: "password",
            key: "password",
        },
        {
            title: "Account Reference By",
            dataIndex: "reference",
            key: "reference",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Master Password",
            dataIndex: "masterPassword",
            key: "masterPassword",
        },
        {
            title: "Investor Password",
            dataIndex: "investorPassword",
            key: "investorPassword",
        },
        {
            title: "Portal Link",
            dataIndex: "portalLink",
            key: "portalLink",
        },
        {
            title: "Password (Portal)",
            dataIndex: "portalPassword",
            key: "portalPassword",
        },
        {
            title: "Server Broker",
            dataIndex: "brokerServer",
            key: "brokerServer",
        },
        {
            title: "VPS Server",
            dataIndex: "vpsServer",
            key: "vpsServer",
        },
        {
            title: "Phone Number",
            dataIndex: "phNo",
            key: "phNo",
        },
        {
            title: "Whatsapp Number",
            dataIndex: "wpNum",
            key: "wpNum",
        },
        {
            title: "Document",
            dataIndex: "doc",
            key: "doc",
            render: (_,rec) => <Space>
                {rec.record.docs.slice(0,2).map((d,i) => <Button type="default" onClick={() => imagePreviewOpen(d,rec)}>Doc {i + 1}</Button>)}
            </Space>
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
        },
        {
            title: "Pincode",
            dataIndex: "pincode",
            key: "pincode",
        },
    ];

    const onCloseCustomer = (id) => {
        apiService.post(`/customer/close-customer/${id}`)
            .then((res) => {
                fetchCreatedUsers({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const imagePreviewOpen = (doc,rec) => {
        let url = getDocURL(`user_doc/${rec.cutId}/${doc}`)
        let ext = doc.split('.')[1];
        let imgex = ['jpg','jpeg','png']
        if(imgex.includes(ext)) {
            setPreviewImg(url)
            setPreviewShow(true)
        } else {
            window.open(url,"_blank")
        }
    }
    const editOpen = (rec) => {

        const data = rec.record
        setSetUpdateData(data)
        form1.setFieldValue("fullname",data?.user?.fullName)
        form1.setFieldValue("email",data?.business_email)
        form1.setFieldValue("street",data?.address?.street)
        form1.setFieldValue("city",data?.address?.city)
        form1.setFieldValue("state",data?.address?.state)
        form1.setFieldValue("country",data?.address?.country)
        form1.setFieldValue("pincode",data?.address?.pincode)
        form1.setFieldValue("refName",data?.reference_by?._id)
        form1.setFieldValue("contactNo",data?.contact_no)
        form1.setFieldValue("dob",dayjs(data?.dob))
        form1.setFieldValue("brokerName",data?.broker?._id)
        form1.setFieldValue("accountNumber",data?.account_number)
        form1.setFieldValue("masterPassword",data?.master_password)
        form1.setFieldValue("investorPassword",data?.investor_password)
        form1.setFieldValue("portalLink",data?.portal_link)
        form1.setFieldValue("portalPassword",data?.portal_password)
        form1.setFieldValue("brokerServer",data?.server)
        form1.setFieldValue("vpsServer",data?.server_vps?._id)
        form1.setFieldValue("whatsappNo",data?.contact_no?.whatsapp_no)
        form1.setFieldValue("phoneNo",data?.contact_no?.phone_no)
        form1.setFieldValue("username",data?.user?.email)
        form1.setFieldValue("account_created_date",dayjs(data?.account_created_date))
        let list = data?.docs?.map((d,i) => {
            return {
                uid: `-${i}`,
                name: d,
                status: 'done',
                url: getDocURL(`user_doc/${data.cust_code}/${d}`),
            }
        })
        setFileList(list)
        form1.setFieldValue("docs",list)
        setuserPreference(data?.user?.preference)
        setIsEditModalOpen(true)

    }
    const loginHandle = (rec) => {

        apiService.post('/auth/admin-login',{id: rec.record.user._id})
            .then(res => {
                window.open(`/dashboard?token=${res.token}`)
            })
            .catch(e => console.log(e))
    }
    const statusChange = (val,rec) => {
        apiService.post(`/auth/change-status`,{
            status: val,
            id: rec?.record?.user?._id
        }).then(res => {
            message.success(res.message)
            fetchCreatedUsers({})
        })
            .catch(e => message.error(e.message))
    }

    const changeBotStatus = (val,rec) => {
        apiService.post(`/customer/change-botstatus/${rec.key}`,{
            status: val
        }).then(res => {
            message.success(res.message)
            fetchCreatedUsers()
        })
            .catch(e => message.error(e.message))
    }

    const handleEditCancel = () => {
        setIsEditModalOpen(false);
    };

    useEffect(() => {
        fetchCreatedUsers()
    },[]);

    const edithandler = (val) => {
        const formData = new FormData()
        if(val.docs.hasOwnProperty('fileList') && val.docs.fileList.length === 0) {
            message.error("Please Select at least 1 Document")
        }

        formData.append("fullName",val?.fullname)
        formData.append("phone_no",val?.phoneNo || null)
        formData.append("whatsapp_no",val?.whatsappNo)
        formData.append("street",val?.street)
        formData.append("city",val?.city)
        formData.append("state",val?.state)
        formData.append("country",val?.country)
        formData.append("pincode",val?.pincode)
        formData.append("email",val?.username)
        formData.append("business_email",val?.email)
        formData.append("dob",val?.dob)
        if(val?.password) formData.append("password",val?.password)
        if(val?.refName) formData.append("reference_by",val?.refName)
        formData.append("pref",userPreference)
        formData.append("broker",val?.brokerName)
        formData.append("account_number",val?.accountNumber)
        formData.append("master_password",val?.masterPassword)
        formData.append("investor_password",val?.investorPassword)
        formData.append("portal_link",val?.portalLink)
        formData.append("portal_password",val?.portalPassword)
        formData.append("server",val?.brokerServer)
        formData.append("server_vps",val?.vpsServer)
        if(val?.account_created_date) formData.append("account_created_date",val?.account_created_date)

        let lastCount = 0;

        if(setUpdateData.docs.length > 0) {
            const lastExtraDoc = setUpdateData.docs[setUpdateData.docs.length - 1];
            const matchResult = lastExtraDoc.match(/\d+/g);
            if(matchResult) {
                lastCount = Number(matchResult[0]);
            }
        }

        for(const file of val?.docs?.fileList || []) {
            if(file.hasOwnProperty("originFileObj")) {
                lastCount++;
                const fieldName = `doc${lastCount}`;
                const fileObject = file.originFileObj;
                formData.append(fieldName,fileObject);
            }
        }

        apiService.put(`/customer/admin-update/${setUpdateData._id}`,formData)
            .then(res => {
                message.success(res.message)
                fetchCreatedUsers()
                handleEditCancel()
                form1.resetFields()
            })
            .catch(e => message.error(e.message))
    }

    const fetchCreatedUsers = (val) => {
        apiService.get('/customer/whitelabel-user',{
            status: ["CREATED"],
            closed_user: false,
            ...val
        })
            .then(res => {
                console.log(res);
                const list = res.data.map((cst,index) => ({
                    count: index + 1,
                    key: cst?._id,
                    whiteLabel: cst?.wl?.name,
                    data: cst
                }))
                setCreatedUserList(list)
                // const list = res.data.map((cst,index) => ({
                
                // }))
                // setCreatedUserList(list)
            })
            .catch(e => {console.log(e)})
    }
    const filterHandle = (val) => {
        fetchCreatedUsers({
            botStatus: val.botStatus,
            brokerName: val.brokerName,
            ibName: val.ibName,
            refName: val.refName,
            usrStatus: val.status,
            vpsServer: val.vpsServer
        })
    }
    const resetFilter = () => {
        fetchCreatedUsers({})
        form2.resetFields()
    }
    const removeDoc = (val) => {
        if(!val.hasOwnProperty("originFileObj")) {
            let res = deleteDoc(val.name,setUpdateData.cust_code)
            if(res) {
                apiService.post(`/customer/remove-doc/${setUpdateData._id}/${val.name}`)
                    .then(res => {
                        message.success(res.message)
                    })
                    .catch(e => message.error(e.message))
            } else {
                message.error("Innternal Server Error")
            }


        }
    }
    const filterData = (searchTxt) => {
        return createdUserList.filter(dt => dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.state.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.country.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.pincode.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.city.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.cutId.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.accNo.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.username.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.password.toLowerCase().includes(searchTxt.toLowerCase()))
    }
    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const expandedRowRender = (val) => {
        const data = val.data.customers.map((cst, index) => ({
            count: index + 1,
            key: cst?._id,
            cutId: cst?.cust_code,
            vpsServer: cst?.server_vps?.vps_ip,
            name: cst?.user?.preference + cst?.user?.fullName,
            accNo: cst?.account_number || "-",
            botStatus: cst?.botStatus,
            status: cst?.user?.status,
            reference: cst?.reference_by?.fullName || "-",
            ibName: cst?.broker?.ib_name || "-",
            broker: cst?.broker?.name || "-",
            diposit: 0,
            record: cst,
            username: cst?.user?.email,
            password: cst?.user?.password,
            dob: dayjs(cst.dob).format("DD-MM-YYYY"),
            masterPassword: cst?.master_password,
            investorPassword: cst?.investor_password,
            portalLink: cst?.portal_link,
            portalPassword: cst?.portal_password,
            brokerServer: cst?.server,
            address: cst?.address?.street,
            city: cst?.address?.city,
            state: cst?.address?.state,
            country: cst?.address?.country,
            pincode: cst?.address?.pincode,
            phNo: cst?.contact_no?.phone_no,
            wpNum: cst?.contact_no?.whatsapp_no
        }))
        return <Table loading={isLoading} columns={columns} dataSource={data} />
    }
    return (
        <>
            <Image
                width={200}
                style={{display: 'none'}}
                src={previewImg}
                preview={{
                    visible: previewShow,
                    src: previewImg,
                    onVisibleChange: (value) => {
                        setPreviewShow(value);
                    },
                }}
            />
            <Modal title="Edit User"
                open={isEditModalOpen}
                onCancel={handleEditCancel}
                footer={[]}
                width={1000}
            >
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={edithandler}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Full Name" name="fullname" rules={[{required: true,message: 'Please Enter full name!'}]}>
                                <Input addonBefore={<Select style={{width: 70}} onChange={setuserPreference} defaultValue={userPreference} options={[
                                    {value: "Mr.",label: "Mr."},
                                    {value: "Ms.",label: "Ms."},
                                    {value: "Mrs.",label: "Mrs."},
                                ]} />} placeholder="Enter Full Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email Address" name="email" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Street" name={"street"} rules={[{required: true,message: 'Please Enter Street!'}]}>
                                <Input placeholder="Street" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="City" name={"city"} rules={[{required: true,message: 'Please Enter City!'}]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="State" name={"state"} rules={[{required: true,message: 'Please Enter State!'}]}>
                                        <Input placeholder="State" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Country" name={"country"} rules={[{required: true,message: 'Please Enter Country!'}]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Pincode" name={"pincode"} rules={[{required: true,message: 'Please Enter Pincode!'}]}>
                                        <Input placeholder="Pincode" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Reference by (Name)" name="refName">
                                <Select placeholder="Select Customer" showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <ContactInput form={form1} name='phoneNo' label='Phone No.' />
                        </Col>
                        <Col span={8}>
                            <ContactInput form={form1} name='whatsappNo' label='Whatsapp No.' require={true} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Documents" name="docs" rules={[{required: true,message: 'Documents requires'}]}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    onRemove={removeDoc}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Created At" name="account_created_date" >
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Date of Birth" name="dob" rules={[{required: true,message: 'Please select date of birth!'}]}>
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Name (Email id)" name="username" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password (user)" name="password">
                                <Input.Password placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Confirm Password" name="confirmPassword" dependencies={['password']} rules={[
                                ({getFieldValue}) => ({
                                    validator(_,value) {
                                        if(!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}>
                                <Input.Password placeholder="Confirm your Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Broker Name" name="brokerName" rules={[{required: true,message: 'Please Enter broker name!'}]}>
                                <Select
                                    placeholder="Select Broker"
                                    onChange={(val,dt) => {form2.setFieldValue("portalLink",dt.link)}}
                                    options={brokerList.filter(br => br.white_label === setUpdateData?.white_label).map(bkr => ({
                                        label: `${bkr.name} (${bkr.ib_name})`,
                                        value: bkr._id,
                                        link: bkr.ib_link
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Server (Broker)" name="brokerServer" rules={[{required: true,message: 'Please Enter broker server!'}]}>
                                <Input placeholder="Enter Broker Server" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Number" name="accountNumber" rules={[{required: true,message: 'Please Enter account number!'}]}>
                                <Input placeholder="Enter Account Number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Master Password" name="masterPassword" rules={[{required: true,message: 'Please Enter master password!'}]}>
                                <Input.Password placeholder="Enter Master Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Investor Password" name="investorPassword" rules={[{required: true,message: 'Please Enter investor password!'}]}>
                                <Input.Password placeholder="Enter Investor Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Portal Link" name="portalLink">
                                <Input placeholder="Enter Portal Link" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password (Broker Portal)" name="portalPassword">
                                <Input.Password placeholder="Enter Portal Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Vps Server" name="vpsServer" rules={[{required: true,message: 'Please select VPS server!'}]}>
                                <Select placeholder="Select Vps Server" showSearch options={serverList.filter(sr => sr.white_label === setUpdateData?.white_label).map(srvr => ({
                                    value: srvr._id,
                                    label: srvr.vps_ip
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">White Label Users</Typography.Title>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form2}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="refName">
                                <Select placeholder="Select Reference" allowClear showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="brokerName">
                                <Select allowClear placeholder="Select Broker">
                                    {brokerList.map(bkr => <Option key={bkr._id} value={bkr._id}>{bkr.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="ibName">
                                <Select allowClear placeholder="Select IB">
                                    {[...new Set(brokerList.map(item => item.ib_name))].map(bkr => <Option key={bkr} value={bkr}>{bkr}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="botStatus">
                                <Select allowClear placeholder="Select Bot Status">
                                    <Option value={"ACTIVE"}>Active</Option>)
                                    <Option value={"DEACTIVE"}>Deactivate</Option>)
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="status">
                                <Select allowClear placeholder="Select Status">
                                    <Option value={"ACTIVE"}>Active</Option>
                                    <Option value={"DEACTIVE"}>Deactivate</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="vpsServer">
                                <Select allowClear placeholder="Select VPS Server">
                                    {serverList.map(dt => <Option value={dt._id}>{dt.vps_ip}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            {/* {(permission?.customer == "EDIT" || permission?.customer == "VIEW" || user?.role != "SUBADMIN") ?
                <Table loading={isLoading} scroll={{x: 5000}} columns={columns} dataSource={searchText ? filterData(searchText) : createdUserList} /> :
                <Alert message="You havn't Enough Permission" />
            } */}

            <Table scroll={{x: 5000}} columns={wlCol} dataSource={createdUserList} expandable={{expandedRowRender}}/>

        </>
    );
}
const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(WLUserList);