import {connect} from "react-redux";
import {Navigate,useLocation} from "react-router-dom";
import {LocalStorageService} from "../services/LocalStorage";

const ProtectedRoute = ({layout: Layout,component: Component,user,role,isUserLogin,...rest}) => {
    const userData = user || LocalStorageService.getItem("user")
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');
    const location = useLocation();
    const searchParams = location.search;
    const isAdmin = () => {
        return userData.role === "ADMIN" || userData.role === "SUBADMIN";
    };
    const isCustomer = () => {
        return userData.role === "CUSTOMER";
    };
    const isWhiteLabel = () => {
        return userData.role === "WHITE_LABEL";
    };

    const isAuthenticated = () => {
        const token = LocalStorageService.getItem('userToken')
        return (isUserLogin || token)
    };
    if(!isAuthenticated()) {
        return <Navigate to="/login" />;
    }
    if((role === "admin" && !isAdmin()) ||
        (role === "user" && !isCustomer()) ||
        (role === "white_label" && !isWhiteLabel())) {
        if(isAdmin() && !tokenParam) {
            return <Navigate to="/users" />;
        } else if(isAdmin() && tokenParam) {
            return <Navigate to={`/dashboard?token=${tokenParam}`} />;
        }
        else if(isCustomer()) {
            return <Navigate to="/dashboard" />;
        } else if(isWhiteLabel()) {
            return <Navigate to={`/wl/users${searchParams}`} />;
        } else {
            return <Navigate to="/login" />;
        }
    }

    return (
        <Layout>
            <Component {...rest} />
        </Layout>
    );
};
const mapStateToProps = ({login}) => {
    const {user,isUserLogin} = login;
    return {user,isUserLogin}
};
export default connect(mapStateToProps,{})(ProtectedRoute);