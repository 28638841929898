import React,{useState} from "react";
import {Card,Image,Form,Input,Button,Flex,message,Typography} from "antd";
import APIService from "../services/APIService"
import {connect} from "react-redux"
import {isLogin,setUserData,setCustomerData,setWallet,setBill,setProfit,setPermission,setWhiteLabelData} from "../actions"
import {LocalStorageService} from "../services/LocalStorage";
import {Link,useNavigate} from "react-router-dom"
import {setBotcharge} from "../actions/botChargeAction";
import image from "../assets/loginImg.png"
import loginIcon from "../assets/loginIcon.png"
function Login({isLogin,setUserData,setWhiteLabelData,isLoading,setCustomerData,setWallet,setBill,setProfit,setPermission,setBotcharge}) {
    const axiosService = new APIService();
    const [forgotPassword,setForgotPassword] = useState(false);
    const navigate = useNavigate()
    const onFinish = (val) => {
        axiosService.post('/auth/signin',{
            email: val.email,
            password: val.password,
        })
            .then(res => {
                isLogin(true)
                setUserData(res.data)
                LocalStorageService.setItem("userToken",res.token)
                LocalStorageService.setItem("user",res.data)
                if(res?.data?.role === "SUBADMIN") {
                    setPermission(res?.permission)
                }
                if(res?.data?.role === "ADMIN" || res?.data?.role === "SUBADMIN") {
                    navigate('/users')
                } else if(res?.data?.role === "WHITE_LABEL") {

                    navigate('/wl/users')
                    setWhiteLabelData(res?.white_label)
                    setBill(res?.bill)
                } else {
                    navigate('/dashboard')
                    setCustomerData(res?.customer)
                    setWallet(res?.wallet)
                    setBill(res?.bill)
                    setProfit(res?.totalProfit || 0)
                    setBotcharge(res?.botCharge)
                }

            })
            .catch(e => {
                message.error(e.message || "Something went wrong")
                isLogin(false)
                setUserData(null)
            })
    };
    const forgotPasswordForm = (val) => {
        axiosService.post('/auth/forgot-password',val)
            .then(res => {
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper-col left">
                <div style={{width: "60%",padding: 24}}>
                    <div className="card-wrapper">
                        <Flex style={{marginBottom: "60px"}} justify="center">
                            <img src={loginIcon} style={{width: 250}} />
                        </Flex>
                        {forgotPassword ? <Form
                            name="basic"
                            onFinish={forgotPasswordForm}
                            autoComplete="off"
                            layout="vertical"
                            className="login-form"
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Email!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Flex justify="space-between" style={{marginBottom: 30}}>
                                <Typography.Text style={{color: "#fff"}}><Button onClick={() => setForgotPassword(false)} type="text" style={{color: '#BFFE67',padding: 0}}>Login</Button></Typography.Text>
                            </Flex>
                            <Flex justify="center" style={{marginBottom: "20px"}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    size="large"
                                    className="login-btn"
                                >
                                    Send Email
                                </Button>
                            </Flex>
                        </Form> : <Form
                            name="basic"
                            onFinish={onFinish}
                            autoComplete="off"
                            layout="vertical"
                            className="login-form"
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Email!",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>


                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Password!",
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Flex justify="space-between" style={{marginBottom: 30}}>
                                <Typography.Text style={{color: "#fff"}}><Button onClick={() => setForgotPassword(true)} type="text" style={{color: '#BFFE67',padding: 0}}>Forgot Password?</Button></Typography.Text>
                                <Typography.Text style={{color: "#fff"}}>Don't have an account? <Link to={'/registration'} style={{color: '#BFFE67'}}>Sign up</Link></Typography.Text>
                            </Flex>
                            <Flex justify="center" style={{marginBottom: "20px"}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    size="large"
                                    className="login-btn"
                                >
                                    Login
                                </Button>
                            </Flex>
                        </Form>}
                    </div>
                </div>
            </div>
            <div className="auth-wrapper-col right">
                <div style={{textAlign: "center",maxWidth: "560px",width: "100%"}}>
                    <img style={{width: "200px"}} src={image} />
                    <h2>Welcome to AIFXBrain</h2>
                    <Typography.Paragraph style={{marginBottom: "120px"}}>Empowering Your Forex Success with AI and Autotrading Excellence.</Typography.Paragraph>
                    {/* <img src={Logo} /> */}
                    <Typography.Paragraph>Unlock seamless trading with our AI-driven CRM system designed to automate and enhance your forex strategies.</Typography.Paragraph>
                    <Typography.Paragraph>EMPOWERING FOREX SUCCESS WITH AUTOTRADING EXCELLENCE.</Typography.Paragraph>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = ({loading}) => {
    const {isLoading} = loading
    return {
        isLoading
    }
}
const mapDispatchToProps = {
    isLogin,
    setUserData,
    setCustomerData,
    setWallet,
    setBill,
    setProfit,
    setPermission,
    setBotcharge,
    setWhiteLabelData
}
export default connect(mapStateToProps,mapDispatchToProps)(Login)