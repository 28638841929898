import axios from "axios";
import { setLoading } from "../actions";
import { store } from "..";
import { LocalStorageService } from "./LocalStorage";

class AxiosService {
    constructor(options) {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        const token = tokenParam || LocalStorageService.getItem("userToken");
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: options?.timeout,
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
    }

    get(url, params, loading = true) {
        if (loading) store.dispatch(setLoading(true));
        return this.instance
            .get(url, { params })
            .then((response) => {
                if (loading) store.dispatch(setLoading(false));
                return response.data;
            })
            .catch((error) => {
                if (loading) store.dispatch(setLoading(false));
                throw new Error(`Error fetching data: ${error.message}`);
            });
    }

    post(url, data, loading = true) {
        if (loading) store.dispatch(setLoading(true));
        return this.instance
            .post(url, data)
            .then((response) => {
                if (loading) store.dispatch(setLoading(false));
                return response.data;
            })
            .catch((error) => {
                if (loading) store.dispatch(setLoading(false));
                if (error.response?.data) {
                    throw error.response?.data;
                } else {
                    throw new Error(`Error posting data: ${error.message}`);
                }
            });
    }

    put(url, data, loading = true) {
        if (loading) store.dispatch(setLoading(true));
        return this.instance
            .put(url, data)
            .then((response) => {
                if (loading) store.dispatch(setLoading(false));
                return response.data;
            })
            .catch((error) => {
                if (loading) store.dispatch(setLoading(false));
                throw new Error(`Error putting data: ${error.message}`);
            });
    }

    delete(url, loading = true) {
        if (loading) store.dispatch(setLoading(true));
        return this.instance
            .delete(url)
            .then((response) => {
                if (loading) store.dispatch(setLoading(false));
                return response.data;
            })
            .catch((error) => {
                if (loading) store.dispatch(setLoading(false));
                throw new Error(`Error deleting data: ${error.message}`);
            });
    }
}

export default AxiosService;
