import {io,Socket} from "socket.io-client";

class WebSocketService {
    socket = null;

    connect(url) {
        this.socket = io(url);

        this.socket.on('connect',() => {
            console.log('WebSocket connected');
        });

        this.socket.on('disconnect',() => {
            console.log('WebSocket disconnected');
        });
    }

    disconnect() {
        if(this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    sendMessage(event,message) {
        if(this.socket) {
            this.socket.emit(event,message);
        }
    }

    onMessage(event,callback) {
        if(this.socket) {
            this.socket.on(event,callback);
        }
    }

    offMessage(event) {
        if(this.socket) {
            this.socket.off(event);
        }
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;