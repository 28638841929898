import {Button, Card, Col, DatePicker, Flex, Form, Image, Input, InputNumber, message, Modal, Row, Select, Space, Table, Typography, Upload} from 'antd'
import React, {useEffect, useState} from 'react'
import AxiosService from '../services/APIService';
import {FilterOutlined, InboxOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import moment from 'moment';
const { Dragger } = Upload;

function WithdrawHistory({isLoading, customer}) {
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [transactionList,setTransactionList] = useState([]);
    const [filterShow,setFilterShow] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleFileUpload = (file) => {
        return false; // Prevent default upload behavior
    };
    useEffect(() => {
        fetchTransaction({})
    }, []);
    const apiService = new AxiosService()
    const fileUploadProps = {
        name: 'file',
        multiple: false,
        beforeUpload: handleFileUpload,
      };
      const columns = [
        {
            title: "#",
            key: "index",
            dataIndex: "index",
            width: "50px",
            fixed: 'left',
        },
        {
            title: "Bot Id",
            key: "botId",
            render: () => customer?.cust_code,
            width: "100px",
            fixed: 'left',
        },
        {
            title: "Transaction Id",
            dataIndex: "transactionId",
            key: "transactionId",
        },
        {
            title: "Payment Method",
            dataIndex: "paymentMethod",
            key: "paymentMethod"
        },
        {
            title: "Amount",
            key: "amount",
            dataIndex: "amount",
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
            render: () => "my note"
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
        },
        {
            title: "Request Date",
            dataIndex: "dateTime",
            key: "dateTime",
            render: (e, rec) => rec.paymentMethod === "OFFLINE" ? "-" : moment(e).format("DD-MM-YYYY HH:mm")
        },
        {
            title: "Action Date",
            dataIndex: "actionDate",
            key: "actionDate",
            render: (e) =>  e ? moment(e).format("DD-MM-YYYY HH:mm") : "-"
        },
        {
            title: "Payment Transaction Hash",
            dataIndex: "transactionHash",
            key: "transactionHash",
        },
        {
            title: "Proof",
            key: "proof",
            dataIndex: "proof",
            render: (val, rec) => val && <Image width={50} src={`${process.env.REACT_APP_API_URL}/transaction/doc/${rec.key}`} />
        }
    ];
    const formSubmit = (val) => {
        const formField = new FormData()

        formField.append("amount", val.amount)
        formField.append("type", "WITHDRAW")
        if(val.paymentID) formField.append("paymentID",val.paymentID)
        formField.append("document",val?.doc?.file)
        formField.append("from",val?.from)
        
        apiService.post("/transaction/create-by-user",formField)
            .then(res => {
                form.resetFields()
                setIsModalOpen(false);
                fetchTransaction({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const fetchTransaction = (params) => {
        apiService.get("/transaction/my-transaction",{
            ...params,
            type: "WITHDRAW",
        })
            .then(res => {
                const data = res.data.map((dt, index) => ({
                    index: index + 1,
                    key: dt?._id,
                    transactionId: dt?.transaction_id,
                    dateTime: dt?.created_at,
                    amount: dt?.amount,
                    status: dt?.status,
                    type: dt?.trans_type,
                    transactionHash: dt?.paymentId || "-",
                    proof: dt?.doc,
                    actionDate: dt?.action_date,
                    paymentMethod: dt?.via
                }))
                setTransactionList(data)
            })
            .catch(e => console.log(e))
    }
    const filterHandle = (val) => {
        fetchTransaction({
            actionDate: val.actionDate ? moment(val.actionDate.toString()).format("DD-MM-YYYY") : undefined,
            reqDate: val.reqDate ? moment(val.reqDate.toString()).format("DD-MM-YYYY") : undefined,
            status: val.status
        })
    }
    const resetFilter = () => {
        fetchTransaction({})
        form1.resetFields()
    }
    return (
        <>
            <Modal title="Create Transaction"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={600}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="Amount" name="amount" rules={[{required: true, type: "number", message: 'Amount Required!'}]}>
                        <InputNumber placeholder="Add Amount" min={1} style={{width: "100%"}} />
                    </Form.Item>
                    <Form.Item label="Documents" name={"doc"}>
                        <Dragger {...fileUploadProps} maxCount={1}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag files to this area to upload</p>
                        </Dragger>
                    </Form.Item>
                    <Form.Item label="Withdraw from" name="from" rules={[
                        {required: true, message: "Please Withdraw Type"}
                    ]}>
                        <Select options={[
                            {
                                label: "Profit",
                                value: "PROFIT"
                            },
                            {
                                label: "Deposit",
                                value: "DEPOSIT"
                            }
                        ]} />
                    </Form.Item>
                    <Form.Item label="Payment Transaction Hash" name="paymentID">
                        <Input placeholder="Payment Transaction Hash" style={{width: "100%"}} />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
                
            </Modal>
            <Typography.Title level={3} className="page-title">Withdraw History</Typography.Title>
            <Flex justify="end" align='center' style={{marginBottom: "20px"}}>
                <Button onClick={() => setFilterShow(!filterShow)} type="primary" style={{marginRight: 5}}><FilterOutlined /></Button>                
                <Button type="primary" onClick={showModal}>
                    Create Transaction
                </Button>
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={(val) => filterHandle(val)}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="reqDate">
                                <DatePicker placeholder='Request Date' style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="actionDate">
                                <DatePicker placeholder='Action Date' style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="status">
                                <Select allowClear placeholder="Select Status">
                                    <Select.Option value={"APPROVED"}>Approve</Select.Option>)
                                    <Select.Option value={"REJECTED"}>Rejected</Select.Option>)
                                    <Select.Option value={"PENDING"}>Pending</Select.Option>)
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            <Table scroll={{ x: 2000}} loading={isLoading} columns={columns} dataSource={transactionList} />

        </>
    )
}
const mapStateToProps = ({login, loading, billInfo}) => {
    const {wallet, customer} = login
    const {isLoading} = loading
    const {billDetail, totalProfit} = billInfo
    return {wallet, isLoading,customer, billDetail, totalProfit}
  }
export default connect(mapStateToProps, {}) (WithdrawHistory)