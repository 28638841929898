import React, {useEffect, useState} from 'react'
import APIService from "../services/APIService"
export default function useWhiteLabel() {
    const apiService = new APIService()
    const [whiteLabel, setWhiteLabel] = useState([])
    useEffect(() => {
        fetchWhiteLabel()
    },[])
    
    const fetchWhiteLabel = () => {
        apiService.get('/white-label/list')
            .then(res => {
                setWhiteLabel(res.data)
            })
            .catch(e => {
                console.log(e);
            })
    }

    return {
        whiteLabel,
        fetchWhiteLabel
    }
  
}