import {Button, Image, Space, Table, Typography} from 'antd'
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import AxiosService from '../../services/APIService';

function WLDepositReport() {
    const apiService = new AxiosService()
    const [listData, setListData] = useState([]);
    useEffect(() => {
        transactionFetch()
    }, []);
    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            width: 50
        },
        {
            title: "White Label",
            dataIndex: "whiteLabel",
            key: "whiteLabel",
        }
    ]
    const expandedRowRender = (val) => {
        const reqColumns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
            },
            {
                title: "Date and Time",
                dataIndex: "dateTime",
                key: "dateTime",
            },
            {
                title: "Bot Id",
                dataIndex: "botId",
                key: "botId",
            },
            {
                title: "Account Name",
                dataIndex: "accName",
                key: "accName",
            },
            {
                title: "Transaction Id",
                dataIndex: "transactionId",
                key: "transactionId",
            },
            {
                title: "Proof",
                key: "proof",
                dataIndex: "proof",
                render: (val,rec) => val && <Image width={50} src={`${process.env.REACT_APP_API_URL}/transaction/doc/${rec.key}`} />
            },
            {
                title: "Amount",
                key: "amount",
                dataIndex: "amount",
            },
            {
                title: "Via",
                key: "via",
                dataIndex: "via",
            },
            {
                title: "By",
                key: "by",
                dataIndex: "by",
            },
            {
                title: "Transaction Hash",
                key: "hashId",
                dataIndex: "hashId",
            }
        ];
        const transData = val.chld.map(ch => ({
            index: 1,
            dateTime: moment(ch.created_at).format("DD-MM-YYYY HH:mm"),
            botId: ch.customer.cust_code,
            accName: ` ${ch.user.preference} ${ch.user.fullName}`,
            transactionId: ch.transaction_id,
            amount: ch.amount,
            via: ch.via,
            by: `${ch?.by?.fullName} (${ch?.by?.role})`,
            hashId: ch.paymentId,
        }))
        return <Table className='inner-table' columns={reqColumns} dataSource={transData} />
    }

    const transactionFetch = () => {
        apiService.get('/white-label/transaction',{
            type: "DEPOSIT"
        })
        .then(res => {
            const listData = res?.data?.map((dt,i) => ({
                key: dt._id,
                index: i + 1,
                whiteLabel: dt?.White_label.name,
                chld: dt.list
            }))
            setListData(listData)
        })
    }
    return (
        <>
            <Typography.Title level={3} className="page-title">Deposit Report</Typography.Title>
            <Table
                columns={columns}
                dataSource={listData}
                expandable={{expandedRowRender}}
                scroll={{x: 1400}}
            />
        </>
    )
}

export default WLDepositReport