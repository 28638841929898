import React,{useEffect,useState} from "react";
import {Table,Modal,Button,Flex,Form,Input,message,Alert,Descriptions,Space,Row,Col,Typography} from "antd";
import APIService from "../services/APIService"
import useServer from "../hooks/useServer";
import {connect} from "react-redux";
import {DeleteOutlined,EditOutlined,EyeOutlined,FilterOutlined} from "@ant-design/icons";
function Server({permission,user,isLoading}) {
    const apiService = new APIService()
    const {fetchServer} = useServer()
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [messageApi,contextHolder] = message.useMessage();
    const [dataList,setDataList] = useState([]);
    const [detailModal,setDetailModal] = useState(false);
    const [editModal,setEditModal] = useState(false);
    const [viewData,setViewData] = useState(null);
    const [searchText,setSearchText] = useState("");
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const columns = [

        {
            title: "#",
            dataIndex: "index",
            key: "index",
        },
        {
            title: "VPS IP",
            dataIndex: "vpsip",
            key: "vpsip",
        },
        Table.EXPAND_COLUMN,
        {
            title: "User Name",
            key: "username",
            dataIndex: "username",
        },
        {
            title: "Password",
            key: "Password",
            dataIndex: "password",
        },
        {
            title: "Total Account",
            key: "noOfAcc",
            dataIndex: "noOfAcc",
        },
        {
            title: "Action",
            key: "view",
            dataIndex: "restData",
            render: (val) => <Space>
                <Button type="text" onClick={() => viewClickedHandle(val)}><EyeOutlined /></Button>
                <Button type="text" onClick={() => editClickedHandle(val)}><EditOutlined /></Button>
                <Button type="text" onClick={() => editClickedHandle(val)} danger><DeleteOutlined /></Button>
            </Space>,
            width: 100
        },
    ];
    const formSubmit = (val) => {
        apiService.post("/server/create",{
            password: val?.password,
            username: val?.username,
            vpsIp: val?.vpsip,
            acName: val?.acName,
            acNumber: val?.acNumber,
            brokerName: val?.brokerName,
            iBPassword: val.IBPassword,
            portalPassword: val.portalPassword
        })
            .then(() => {
                fetchServer()
                setIsModalOpen(false)
                messageApi.open({
                    type: 'success',
                    content: 'New Server Created',
                })
                fetchListServer()
            })
            .catch(e => {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                })
            })
    }

    const viewClickedHandle = (val) => {
        setViewData(val)
        setDetailModal(true)
    }
    const editClickedHandle = (val) => {
        setViewData(val)
        setEditModal(true)
        form2.setFieldValue("acName",val.acName)
        form2.setFieldValue("acNumber",val.acNumber)
        form2.setFieldValue("brokerName",val.brokerName)
        form2.setFieldValue("vpsip",val.vps_ip)
        form2.setFieldValue("username",val.username)
        form2.setFieldValue("password",val.password)
        form2.setFieldValue("IBPassword",val.ib_password)
        form2.setFieldValue("portalPassword",val.portal_password)
    }

    useEffect(() => {
        fetchListServer()
    },[])

    const fetchListServer = () => {
        apiService.get('/server/detail-list')
            .then(res => {
                const data = res.data.map((dt,i) => ({
                    key: dt._id,
                    index: i + 1,
                    vpsip: dt.vps_ip,
                    username: dt.username,
                    password: dt.password,
                    restData: dt,
                    noOfAcc: dt?.customers_detail?.length || 0,
                    ibPassword: dt?.ib_password,
                    portalPassword: dt?.portal_password
                }))
                setDataList(data)
            })
            .catch(e => console.log(e))
    }
    const expandedRowRender = (rec) => {
        const col = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
            },
            {
                title: "Account Number",
                dataIndex: "acNo",
                key: "name",
            },
            {
                title: "Account Name",
                dataIndex: "accName",
                key: "accName",
            },
            {
                title: "Broker Name",
                key: "brokerName",
                dataIndex: "brokerName",
            }
        ];
        const recDoc = rec.restData.customers_detail
        const data = [];
        for(let i = 0; i < recDoc.length; ++i) {
            data.push({
                index: i + 1,
                key: recDoc[i]._id,
                acNo: recDoc[i].account_number,
                accName: recDoc[i]?.user_detail?.fullName,
                brokerName: recDoc[i]?.broker_detail?.name,
            });
        }
        return (
            <Table
                columns={col}
                dataSource={data}
                pagination={false}
                className="mb-3 mt-3 mr-3 inner-table" />
        );
    };
    const modalCloseHandle = () => {
        setDetailModal(false)
        setViewData(null)
    }
    const updateDataHandle = (val) => {
        apiService.put(`/server/update/${viewData._id}`,{
            password: val?.password,
            username: val?.username,
            vps_ip: val?.vpsip,
            acName: val?.acName,
            acNumber: val?.acNumber,
            brokerName: val?.brokerName,
            iBPassword: val.IBPassword,
            portalPassword: val.portalPassword
        })
            .then((res) => {
                fetchServer()
                setIsModalOpen(false)
                messageApi.open({
                    type: 'success',
                    content: "Changes Updated",
                })
                fetchListServer()
            })
            .catch(e => {
                messageApi.open({
                    type: 'error',
                    content: e.message,
                })
            })
    }

    const handleUpdateCancel = () => {
        form2.resetFields()
        setEditModal(false)
    }

    const filterData = (searchTxt,data) => {
        return data.filter(dt => dt.vpsip.toLowerCase().includes(searchTxt.toLowerCase()))
    }

    return (
        <>
            {contextHolder}
            <Modal title="Create Server" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Account Name" name={"acName"} rules={[{required: true,message: "Please enter Account Name"}]}>
                                <Input placeholder="Enter Account Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Number" name={"acNumber"} rules={[{required: true,message: "Please enter Account Number"}]}>
                                <Input placeholder="Enter Account Number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Broker Name" name={"brokerName"} rules={[{required: true,message: "Please enter Broker Name"}]}>
                                <Input placeholder="Enter Broker Name" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="IB Password" name={"IBPassword"}>
                                <Input placeholder="Enter IB Password" />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item label="VPS IP" name={"vpsip"} rules={[{required: true,message: "Please enter VPS IP"}]}>
                                <Input placeholder="Enter VPS IP" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="Portal Password" name={"portalPassword"}>
                                <Input placeholder="Enter VPS IP" />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item label="User Name" name={"username"} rules={[{required: true,message: "Please enter User Name"}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password" name={"password"} rules={[{required: true,message: "Please enter Password"}]}>
                                <Input placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Modal title="Update Server" open={editModal} onCancel={handleUpdateCancel} footer={[]}>
                <Form
                    form={form2}
                    layout="vertical"
                    onFinish={updateDataHandle}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Account Name" name={"acName"} rules={[{required: true,message: "Please enter Account Name"}]}>
                                <Input placeholder="Enter Account Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Number" name={"acNumber"} rules={[{required: true,message: "Please enter Account Number"}]}>
                                <Input placeholder="Enter Account Number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Broker Name" name={"brokerName"} rules={[{required: true,message: "Please enter Broker Number"}]}>
                                <Input placeholder="Enter Broker Name" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="IB Password" name={"IBPassword"}>
                                <Input placeholder="Enter Broker Name" />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item label="VPS IP" name={"vpsip"} rules={[{required: true,message: "Please enter VPS IP"}]}>
                                <Input placeholder="Enter VPS IP" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="Portal Password" name={"portalPassword"}>
                                <Input placeholder="Enter VPS IP" />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item label="User Name" name={"username"} rules={[{required: true,message: "Please enter User Name"}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password" name={"password"} rules={[{required: true,message: "Please enter Password!"}]}>
                                <Input placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Modal title="Server Details" footer={[<Button onClick={modalCloseHandle} type="primary">OK</Button>]} open={detailModal} onOk={modalCloseHandle} onCancel={modalCloseHandle}>
                <Descriptions bordered>
                    <Descriptions.Item span={3} label={"Account Name"}>
                        {viewData?.acName}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={"Account Number"}>
                        {viewData?.acNumber}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={"Broker Name"}>
                        {viewData?.brokerName}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={"VPS IP"}>
                        {viewData?.vps_ip}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={"User Name"}>
                        {viewData?.username}

                    </Descriptions.Item>
                    <Descriptions.Item span={3} label={"Password"}>
                        {viewData?.password}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
            <Typography.Title level={3} className="page-title">Server</Typography.Title>
            <Flex justify="end" style={{marginBottom: "20px"}}>
                <Input style={{marginRight: 5,maxWidth: 200}} placeholder="Search by VPS IP..." onChange={(e) => setSearchText(e.target.value)} />
                {/* <Button type="default" style={{marginRight: 5}}><FilterOutlined /></Button> */}
                {(permission?.server_permission == "EDIT" || user?.role != "SUBADMIN") && <Button type="primary" onClick={showModal}>
                    Create Server
                </Button>}
            </Flex>
            {(permission?.server_permission == "EDIT" || permission?.server_permission == "VIEW" || user?.role != "SUBADMIN") ?
                <Table
                    loading={isLoading}
                    columns={columns}
                    expandable={{expandedRowRender,rowExpandable: (rec) => rec?.restData?.customers_detail?.length > 0}}
                    dataSource={searchText ? filterData(searchText,dataList) : dataList} /> :
                <Alert message="You havn't Enough Permission" />
            }

        </>
    );
}
const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(Server);