import {IS_LOGIN, ON_LOGIN, SET_PERMISSION, SET_CUSTOMER, SET_WALLET, SET_WHITE_LABEL} from "../actions/loginAction"

const initialState = {
    isUserLogin: false,
    user: null,
    permission: null,
    customer: null,
    wallet: 0,
    whiteLabel: null
}

const loginReducer = (state = initialState, action) =>{
    switch (action.type) {
        case IS_LOGIN:
            return {
                ...state,
                isUserLogin: action.payload
            }

        case ON_LOGIN:
            return {
                ...state,
                user: action.payload
            }

        case SET_PERMISSION:
            return {
                ...state,
                permission: action.payload
            }
        case SET_CUSTOMER:
            return {
                ...state,
                customer: action.payload
            }
        case SET_WALLET:
            return {
                ...state,
                wallet: action.payload
            }
        case SET_WHITE_LABEL:
            return {
                ...state,
                whiteLabel: action.payload
            }
        default:
            return state;
    }
}

export default loginReducer