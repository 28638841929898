import React,{useEffect, useState} from "react";
import {Form,Input,Button,Flex,message,Typography} from "antd";
import APIService from "../services/APIService"
import {connect} from "react-redux"
import {Link,useNavigate, useSearchParams} from "react-router-dom"
import image from "../assets/loginImg.png"
import loginIcon from "../assets/loginIcon.png"
function ResetPassword({isLoading}) {
    const axiosService = new APIService();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")
    useEffect(() => {
        
        if(!token) message.error("Invalid Link")
    }, []);
    const onFinish = (val) => {
        axiosService.post('/auth/reset-password',{
            token: token,
            password: val.password,
            confirmPassword: val.confirmPassword,
        })
            .then(res => {
                message.success(res.message)
                navigate('/login')
            })
            .catch(e => {
                message.error(e.message || "Something went wrong")
            })
    };
    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper-col left">
                <div style={{width: "60%",padding: 24}}>
                    <div className="card-wrapper">
                        <Flex style={{marginBottom: "60px"}} justify="center">
                            <img src={loginIcon} style={{width: 250}} />
                        </Flex>
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            autoComplete="off"
                            layout="vertical"
                            className="login-form"
                        >

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Password!",
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="Confirm Password" name="confirmPassword" dependencies={['password']} rules={[
                                ({getFieldValue}) => ({
                                    validator(_,value) {
                                        if(!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                                {
                                    required: true,
                                    message: "Please Enter Confirm Password!",
                                },
                            ]}>
                                <Input.Password placeholder="Confirm your Password" />
                            </Form.Item>
                            <Flex justify="center" style={{marginBottom: "20px"}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    size="large"
                                    className="login-btn"
                                >
                                    Save
                                </Button>
                            </Flex>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="auth-wrapper-col right">
                <div style={{textAlign: "center",maxWidth: "560px",width: "100%"}}>
                    <img style={{width: "200px"}} src={image} />
                    <h2>Welcome to AIFXBrain</h2>
                    <Typography.Paragraph style={{marginBottom: "120px"}}>Empowering Your Forex Success with AI and Autotrading Excellence.</Typography.Paragraph>
                    {/* <img src={Logo} /> */}
                    <Typography.Paragraph>Unlock seamless trading with our AI-driven CRM system designed to automate and enhance your forex strategies.</Typography.Paragraph>
                    <Typography.Paragraph>EMPOWERING FOREX SUCCESS WITH AUTOTRADING EXCELLENCE.</Typography.Paragraph>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = ({loading}) => {
    const {isLoading} = loading
    return {
        isLoading
    }
}
export default connect(mapStateToProps,{})(ResetPassword)