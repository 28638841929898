import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import rootReducer from './reducer';
import {thunk} from 'redux-thunk';
import {applyMiddleware,createStore} from 'redux';
import {Provider} from 'react-redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import {Button, ConfigProvider} from 'antd';
const root = ReactDOM.createRoot(document.getElementById('root'));
export const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)));
const themeConfig = {
    token: {
        colorPrimary: "#bffe66",
        colorTextBase: "#000"
    },
    components: {
        Segmented: {
            itemSelectedColor: "#000",
            itemSelectedBg: "#bffe66",
            trackBg: "transparent",
            itemColor: "#fff",
            itemHoverColor: "#fff"
        },
        Button: {
            primaryColor: "#000",
            defaultHoverBorderColor: "#d9d9d9",
            defaultHoverColor: "#000"
        },
        Menu: {
            darkItemSelectedColor: "#000",
            darkItemSelectedBg: "#bffe66"
        },
        Tabs: {
            itemHoverColor: "rgba(0, 0, 0, 0.88)",
            itemActiveColor: "rgba(0, 0, 0, 0.88)"
        },
    }
}
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider theme={themeConfig}>
                <App />
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
