import {Button, Card, Col, DatePicker, Flex, Form, Input, message, Row, Select, Space, Table, Tabs, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import AxiosService from '../services/APIService';
import moment from 'moment';
import {FilterOutlined} from '@ant-design/icons';

const ServiceNote = ({isLoading}) => {
    const [currentTab,setCurrentTab] = useState('1')
    const [noteData, setNoteData] = useState([]);
    const apiService = new AxiosService()
    const [form] = Form.useForm()
    const [filterShow,setFilterShow] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [pendingCounter,setPendingCounter] = useState(0);
    const [historyCounter,setHistoryCounter] = useState(0);
    const columns = [
        {
            title: "#",
            key: "index",
            dataIndex: "index"
        },
        {
            title: "Bot Id",
            key: "botId",
            dataIndex: "botId"
        },
        {
            title: "Account Name",
            dataIndex: "accName",
            key: "accName",
        },
        {
            title: "Account Number",
            dataIndex: "accNo",
            key: "accNo"
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note"
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status"
        },
        {
            title: "Request Date",
            dataIndex: "reqDate",
            key: "reqDate"
        },
        {
            title: "Action Date",
            dataIndex: "actionDate",
            key: "actionDate"
        },
        (currentTab === "2" && {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, rec) => <Space><Button onClick={() => statusUpdate(rec.key, "APPROVE")} size={"small"} >Approved</Button><Button onClick={() => statusUpdate(rec.key, "REJECT")} size={"small"} danger>Reject</Button></Space>
        }),
    ].filter(Boolean);
    const items = [
        {
          key: '1',
          label: `History (${historyCounter})`
        },
        {
          key: '2',
          label: `Pending (${pendingCounter})`
        }
    ];

    useEffect(() => {
        fetchNotes()
    }, []);
    const onChange = (key) => {
        setCurrentTab(key)
    };
    const fetchNotes = (query) => {
        apiService.get("/customer/edit-req", query)
            .then(res => {
                const data = res.data
                let listData = data.map((dt, i) => ({
                    key: dt?._id,
                    botId: dt?.customer?.cust_code,                  
                    accName: dt?.customer?.user?.fullName,                    
                    accNo: dt?.customer?.account_number,                    
                    note: dt?.note,                
                    reqDate: moment(dt?.created_at).format("DD-MM-YYYY"),                
                    actionDate: dt.action_date ? moment(dt?.action_date).format("DD-MM-YYYY") : "-",
                    status: dt.status
                }))
                setHistoryCounter(listData.filter(dt => dt.status !== "PENDING").length)
                setPendingCounter(listData.filter(dt => dt.status === "PENDING").length)
                
                setNoteData(listData)
            })
        .catch(e => console.log(e))
    }
    const statusUpdate = (id,status) => {
        apiService.post("/customer/edit-req-action",{
            reqId: id,
            status: status
        })
        .then(res => {
            message.success(res.message)
            fetchNotes()
        })
        .catch(e => {
            message.error(e.message)
        })
    }
    const filterHandle = (val) => {
        fetchNotes({
            actionDate: val.actionDate ? moment(val.actionDate.toString()).format("DD-MM-YYYY") : undefined,
            reqDate: val.reqDate ? moment(val.reqDate.toString()).format("DD-MM-YYYY") : undefined
        })
      }
      const resetFilter = () => {
        fetchNotes({})
        form.resetFields()
    }
    return (
        <div>
            <Typography.Title level={3} className="page-title">Note Request</Typography.Title>
            <Flex justify="end" style={{marginBottom: "20px"}}>
                <Input style={{marginRight: 5,maxWidth: 350}} placeholder="Search by Bot Id / Account name / Account Number..." onChange={(e) => setSearchText(e.target.value)} />
                <Button type="primary" style={{marginRight: 5}} onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button>
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="reqDate">
                                <DatePicker placeholder='Select Request Date' style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="actionDate">
                                <DatePicker placeholder='Select Action Date' style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            <Tabs type="card" defaultActiveKey="1" items={items} onChange={onChange} />
            {currentTab === "1" &&
                <Table loading={isLoading} columns={columns} dataSource={
                    noteData.filter(dt => dt.status !== "PENDING")
                    .filter(dt => dt?.botId?.toLowerCase().includes(searchText?.toLowerCase()) ||
                    dt?.accName?.toLowerCase().includes(searchText?.toLowerCase()) || 
                    dt?.accNo?.toLowerCase().includes(searchText?.toLowerCase()))
                        .map((dt,i) => ({...dt,index: i + 1}))} />}
            {currentTab === "2" && <Table loading={isLoading} columns={columns} dataSource={noteData.filter(dt => dt?.status === "PENDING")
                .filter(dt => dt?.botId?.toLowerCase().includes(searchText?.toLowerCase()) ||
                dt?.accName?.toLowerCase().includes(searchText?.toLowerCase()) || 
                dt?.accNo?.toLowerCase().includes(searchText?.toLowerCase()))
                .map((dt,i) => ({...dt,index: i + 1}))} />}
        </div>
    );
}
const mapStateToProps = ({loading}) => {
    const {isLoading} = loading
    return {isLoading}
  }
export default connect(mapStateToProps, {}) (ServiceNote);
