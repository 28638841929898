import {Col,Row,Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import AxiosService from '../../services/APIService'
import totalClientIcon from '../../assets/total_client.svg'
import activeClientIcon from '../../assets/active_client.svg'
import totalBalIcon1 from '../../assets/total_balance_i1.svg'
import totalBalIcon2 from '../../assets/total_balance_i2.svg'
import PendingUserIcon from '../../assets/total_pending_user.svg'
import totalProfitIcon from '../../assets/total_profit.svg'
import serverIcon1 from '../../assets/total_server_i1.svg'
import serverIcon2 from '../../assets/total_server_i2.svg'
import closeUserIcon1 from '../../assets/total_close_user_i1.svg'
import closeUserIcon2 from '../../assets/total_close_user_i2.svg'
import botChargeIcon from '../../assets/total_botcharge.svg'
import totalDepositIcon from '../../assets/total_deposite_balance.svg'
import brokerIcon from '../../assets/total_broker.svg'
import subAdminIcon from '../../assets/total_subadmin.svg'

function Dashboard() {
	const axiosService = new AxiosService()
	const [dashboard,setDashboard] = useState();
	useEffect(() => {
		fetchDashboardData()
	},[])

	const fetchDashboardData = () => {
		axiosService.get('/admin/dashboard-wl')
			.then(res => {
				setDashboard(res)
			})
			.catch(e => {
				console.log(e);
			})
	}
	return (
		<Row gutter={[20,20]}>
			<Col span={24}>
				<Row gutter={20}>
					<Col span={9}>
						<Row gutter={[20,20]}>
							<Col span={10}>
								<div className='dashboard-block' style={{backgroundColor: "#00C39A",padding: "45px 10px 45px 25px"}}>
									<h3>{dashboard?.totalWhiteLabels || 0}</h3>
									<span>Total White Label</span>
									<img src={totalClientIcon} style={{width: "40px",top: 13,right: 12}} className='position' />
								</div>
							</Col>
							<Col span={14}>
								<div className='dashboard-block' style={{backgroundColor: "#FF904B",padding: "45px 10px 45px 50px"}}>
									<h3>{dashboard?.clients || 0}</h3>
									<span>Total customers of whitelabel</span>
									<img src={activeClientIcon} style={{width: "50px",top: 13,right: 29}} className='position' />
								</div>
							</Col>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#FFD155",padding: "86px 10px 86px 50px"}}>
									<h3>${(dashboard?.totalAccountBalance)?.toLocaleString("hi-IN")}</h3>
									<span>Total Account Balance</span>
									<img src={totalBalIcon1} style={{width: "70px",top: 24,left: 24}} className='position' />
									<img src={totalBalIcon2} style={{width: "147px",bottom: 18,right: 25}} className='position' />
								</div>
							</Col>
						</Row>
					</Col>
					<Col span={9}>
						<Row gutter={[20,20]}>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#FCC699",padding: "40px 10px 20px 10px",textAlign: "center"}}>
									<h3>${dashboard?.profit?.toLocaleString("hi-IN")}</h3>
									<span style={{marginBottom: 34}}>Total Profit</span>
									<img src={totalProfitIcon} style={{width: "283px",margin: "auto"}} />
								</div>
							</Col>
						</Row>
					</Col>
					<Col span={6}>
						<Row gutter={[20,20]}>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#C89AFF",padding: "30px 20px 20px 30px"}}>
									<h3>${dashboard?.botCharge?.toLocaleString("hi-IN")}</h3>
									<span style={{marginBottom: 40}}>Total Bot Charge</span>
									<img src={botChargeIcon} style={{width: "72px",marginLeft: "auto"}} />
								</div>
							</Col>
							<Col span={24}>
								<div className='dashboard-block' style={{textAlign: "center",backgroundColor: "#FA7565",padding: "50px 20px 50px 20px"}}>
									<h3>${dashboard?.totalDeposit?.toLocaleString("hi-IN")}</h3>
									<span>Total Deposit</span>
									<img src={totalDepositIcon} style={{width: "51px",top: 11,left: 11}} className='position' />
									<img src={totalDepositIcon} style={{width: "51px",bottom: 11,right: 11}} className='position' />
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={20}>
					<Col span={9}>
						<Row gutter={[20,20]}>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#4B9FFF",padding: "25px 77px 11px 79px"}}>
									<h3>${dashboard?.totalWithdraw?.toLocaleString("hi-IN")}</h3>
									<span style={{marginBottom: 28}}>Total Withdraw</span>
									<img src={PendingUserIcon} style={{width: "244px",margin: "auto"}} />
								</div>
							</Col>
						</Row>
					</Col>
					<Col span={9}>
						<Row gutter={[20,20]}>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#E08FF4",padding: "30px 10px 90px 50px"}}>
									<h3>{dashboard?.servers || 0}</h3>
									<span>Total Servers</span>
									<img src={serverIcon1} style={{width: "50px",top: 33,right: 23}} className='position' />
									<img src={serverIcon2} style={{width: "40px",bottom: 0,left: 15}} className='position' />
								</div>
							</Col>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#F9A19E",padding: "50px 10px 50px 100px"}}>
									<h3>${dashboard?.totalDueCommission?.toLocaleString("hi-IN")}</h3>
									<span>Total Due Commission</span>
									<img src={closeUserIcon1} style={{width: "50px",top: 17,left: 16}} className='position' />
									<img src={closeUserIcon2} style={{width: "60px",bottom: 15,right: 15}} className='position' />
								</div>
							</Col>
						</Row>
					</Col>
					<Col span={6}>
						<Row gutter={[20,20]}>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#79DB70",padding: "20px 10px 100px 20px"}}>
									<h3>{dashboard?.brokers || 0}</h3>
									<span>Total Brokers</span>
									<img src={brokerIcon} style={{width: "57px",bottom: 4,right: 10}} className='position' />
								</div>
							</Col>
							<Col span={24}>
								<div className='dashboard-block' style={{backgroundColor: "#CCD469",padding: "50px 10px 50px 100px"}}>
									<h3>0</h3>
									<span>Total Sub-Admin</span>
									<img src={subAdminIcon} style={{width: "34px",top: 17,right: 20}} className='position' />
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Dashboard