import {Button, Input, message, Modal, Select,Table,Typography} from 'antd';
import React,{useEffect,useState} from 'react'
import {connect} from 'react-redux'
import APIService from "../../services/APIService"
import moment from 'moment';
import country from '../../data/country'
import {EyeFilled} from '@ant-design/icons';
const { TextArea } = Input;
function Inquiry({isLoading, whiteLabel}) {
    const apiService = new APIService()
    const [inquiry,setInquiry] = useState([]);
    const [noteContent,setNoteContent] = useState("");
    const [showNoteModal, setShowNoteModal] = useState("");
    useEffect(() => {
        fetchList()
    },[]);

    const fetchList = () => {
        apiService.get("/inquire/get")
            .then(res => {
                const data = res.data.map((dt,i) => ({
                    index: i + 1,
                    key: dt._id,
                    register_as: dt.register_as,
                    title: "-",
                    full_name: `${dt.prefix} ${dt.full_name}`,
                    phone_number: dt.phone_number,
                    email: dt.email,
                    status: dt.status,
                    note: dt.note,
                    date_of_birth: moment(dt.date_of_birth).format("DD-MM-YYYY"),
                    country: dt.country,
                    city: dt.city,
                    risk: dt.risk_pr,
                    remain_days: dt.exp_date ? moment(dt.exp_date).diff(moment(), "days") + "days" : "-"
                }))
                setInquiry(data)

            }).catch(e => console.log(e))
    }

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
        },
        {
            title: "Register as",
            dataIndex: "register_as",
            key: "register_as",
        },
        {
            title: "Full name",
            dataIndex: "full_name",
            key: "full_name",
            width:300
        },
        {
            title: "Phone number",
            key: "phone_number",
            dataIndex: "phone_number",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Risk Percentage",
            dataIndex: "risk",
            key: "risk",
            render: (val) => val ? `${val}%` : "-"
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (e,rec) => <>
                <Select
                    defaultValue={e}
                    disabled={e === "BOT_ACTIVATE"}
                    value={e}
                    style={{width: "100%"}}
                    onChange={(val) => statusChange(val,rec)}
                    options={[
                        {value: 'CALLING',label: 'Calling'},
                        {value: 'ACCOUNT_DETAILS',label: 'Received Account Details'},
                        {value: 'BOT_ACTIVATE',label: 'Bot Active'}
                    ]}
                />
            </>,
            width: 220
        },
        {
            title: "Note",
            key: "note",
            dataIndex: "note",
            render: (data, rec) => <Button onClick={() => {
                setShowNoteModal(rec.key)
                setNoteContent(data)
            }} icon={<EyeFilled />} />
        },
        {
            title: "DOB",
            key: "date_of_birth",
            dataIndex: "date_of_birth",
        },
        {
            title: "Country",
            key: "country",
            dataIndex: "country",
        },
        {
            title: "City",
            key: "city",
            dataIndex: "city",
        },
        {
            title: "Remain Days",
            key: "remain_days",
            dataIndex: "remain_days",
        },
    ];
    const statusChange = (val,rec) => {
        apiService.put("/inquire/status-update", {id:rec.key, status: val})
            .then(res => {
                message.success(res.message)
                fetchList()
            }).catch(e => message.error(e.message))
        }
        
        const saveNoteHandle = () => {
            apiService.put("/inquire/note-save", {id:showNoteModal, note: noteContent})
                .then(res => {
                    message.success(res.message)
                    fetchList()
                }).catch(e => message.error(e.message))
    }

    return (
        <>
            <Modal title="Note" open={showNoteModal} onCancel={() => setShowNoteModal("")} footer={[]}>
                <TextArea rows={4} value={noteContent} onChange={(e) => setNoteContent(e.target.value)} style={{marginBottom: 15}} />
                <Button type='primary' onClick={saveNoteHandle}>Save</Button>
            </Modal>
            <Typography.Title level={3} className="page-title">Inquiry</Typography.Title>
            <div style={{marginBottom: 25, textAlign: "right"}}>
                <Typography.Text copyable={{text: `${window.location.hostname}/leads?wlid=${whiteLabel?._id}`}}><b>Inquiry Link: </b>{window.location.hostname}/leads?wlid={whiteLabel?._id}</Typography.Text>
            </div>
            <Table scroll={{x: 2200}} loading={isLoading} columns={columns} dataSource={inquiry}  />

        </>
    );
}
const mapStateToProps = ({login, loading}) => {
    const {whiteLabel} = login
    const {isLoading} = loading
    return {whiteLabel, isLoading}
}
const mapDispatchToProps = {

}
export default connect(mapStateToProps,mapDispatchToProps)(Inquiry);