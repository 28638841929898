import React,{useState} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    UserSwitchOutlined,
    DownOutlined,
    SettingOutlined
} from '@ant-design/icons';
import {Layout,Menu,Button,theme,Dropdown,Space,Avatar,Alert,Flex} from 'antd';
import logo from "../assets/sidebarLogo.png"
import logoSm from "../assets/sidebarLogoSm.png"
import {Link,useNavigate} from 'react-router-dom';
import {useLocation} from "react-router-dom"
import {connect} from "react-redux"
import {LocalStorageService} from '../services/LocalStorage';
import {isLogin,setUserData} from '../actions';
import Notification from './Notification';
import AxiosService from '../services/APIService';

const {Header,Sider,Content} = Layout;

const UserLayout = ({children,user,isLogin,setUserData,customer,wallet}) => {
    
    const [collapsed,setCollapsed] = useState(false);
    const apiService = new AxiosService()
    const location = useLocation();
    const navigate = useNavigate();
    const {
        token: {borderRadiusLG},
    } = theme.useToken();

    // Extract query parameters
    const searchParams = location.search;

    const menuItems = [
        {
            key: '1',
            icon: <UserOutlined />,
            label: (<Link to={`/dashboard${searchParams}`}>Dashboard</Link>),
        },
        (!customer?.white_label && {
            key: '4',
            icon: <SettingOutlined />,
            label: (<Link to={`/bot-performance${searchParams}`}>Bot Performance</Link>),
        }),
        {
            key: '2',
            icon: <UserSwitchOutlined />,
            label: "Transaction History",
            children: [
                {
                    key: '2-1',
                    label: (<Link to={`/deposit${searchParams}`}>Deposit Report</Link>),
                },
                {
                    key: '2-2',
                    label: (<Link to={`/withdraw${searchParams}`}>Withdraw Report</Link>),
                },
                {
                    key: '2-3',
                    label: (<Link to={`/bot-charge${searchParams}`}>Bot Charge</Link>),
                }
            ]
        },
        {
            key: '3',
            icon: <UserSwitchOutlined />,
            label: (<Link to={`/profile${searchParams}`}>My Profile</Link>),
        }
    ]

    const activeLink = () => {
        const currentURL = location.pathname
        if(currentURL === "/dashboard") {
            return "1"
        } else if(currentURL === "/transaction-history") {
            return "2"
        }
    }

    const items = [
        {
            label: "Logout",
            key: '0',
        }
    ];

    const menuClick = (val) => {
        switch(val.key) {
            case "0":
                logoutHandle();
                break;
            default:
                break;
        }
    }

    const logoutHandle = () => {
        LocalStorageService.removeItem("userToken")
        LocalStorageService.removeItem("user")
        setUserData(null)
        isLogin(false)
        navigate('/login')
    }
    return (
        <Layout style={{height: "100%"}}>
            <Sider
                width={250}
                trigger={null}
                theme={"dark"}
                collapsible
                collapsed={collapsed}
                style={{
                    background: "#131c2b",
                }}
            >
                <div className="demo-logo-vertical sidebar-logo">
                    {customer?.white_label ? <img src={`${process.env.REACT_APP_API_URL}white-label/logo/${customer?.white_label}`} style={{width: `${collapsed ? "auto" : "100%"}`,height: `${collapsed ? "40px" : "auto"}`}} /> : 
                    <div style={{fontSize: 22, color: "#fff"}}>
                    {collapsed ? <img src={logoSm} /> : <img src={logo} />}
                </div>}
                </div>
                <Menu
                    mode="inline"
                    theme='dark'
                    defaultSelectedKeys={activeLink()}
                    items={menuItems}
                    style={{
                        background: "#131c2b",
                    }}
                />
            </Sider>
            <Layout>
                <Header
                    theme="light"
                    style={{
                        padding: '0PX 20PX',
                        background: "#131c2b",
                    }}
                >
                    <Flex justify='space-between'>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined style={{color: "#fff"}} /> : <MenuFoldOutlined style={{color: "#fff"}} />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        <Space size={20}>
                            <Notification type={"USER"} />
                            <Dropdown
                                menu={{
                                    items,
                                    onClick: menuClick
                                }}
                                trigger={['click']}

                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Space style={{color: "#fff"}} className="user-name">
                                        {user?.fullName}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </Space>
                    </Flex>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        background: "rgb(220 220 220 / 52%)",
                        borderRadius: borderRadiusLG,
                        minHeight: "800px"
                    }}
                >
                    {customer?.status === "PENDING" ? <Alert message="YourAccount is not created yet, you will access your portal after created account, pls contact with team to speed up" /> : children}
                </Content>
            </Layout>
        </Layout>
    );
};

const mapStateToProps = ({login}) => {
    const {user,customer,wallet} = login
    return {user,customer,wallet}
}

const mapDispatchToProps = {
    isLogin,
    setUserData
}

export default connect(mapStateToProps,mapDispatchToProps)(UserLayout);
