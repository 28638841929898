import React, {useEffect, useState} from 'react'
import { Table, Button, Modal, Form, Input, message,Flex, Select, Col, Row, Card, Space, Typography, Popconfirm } from "antd";
import {DeleteOutlined, FilterOutlined, LockOutlined} from "@ant-design/icons";
import APIService from "../services/APIService"
import {connect} from 'react-redux';
const {Option} = Select;

function Team({isLoading}) {
  const apiService = new APIService()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [permissionOpen, setPermissionOpen] = useState(false);
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [messageApi,contextHolder] = message.useMessage();
  const [memberList,setMemberList] = useState([])
  const [permissionId,setPermissionId] = useState("");
  const [searchText,setSearchText] = useState("");
  const [filterShow,setFilterShow] = useState(false);
  
  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields()
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
    const columns = [
        {
          title: "#",
          dataIndex: "index",
          key: "index",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Password",
          dataIndex: "password",
          key: "password",
        },
        {
          title: "Role",
            key: "roel",
            dataIndex: "role",
          },
          {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (e, rec) => <Select
              defaultValue={e}
              value={e}
              onChange={(val) => statusChange(val, rec)}
              style={{width: 110}}
              options={[
                {value: 'ACTIVE',label: 'Activate'},
                {value: 'DEACTIVE',label: 'Deactivate'}
              ]}
            />
          },
          {
            title: "Permission",
              key: "permission",
              dataIndex: "permission",
              render: (e, rec) => <Button onClick={() => fetchPermission(rec) }><LockOutlined /></Button>
          },
          {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e, rec) =>  <Popconfirm
              title="Delete the transaction"
              description="Are you sure to delete this Transaction?"
              onConfirm={() => deleteUser(e)}
              okText="Yes"
              cancelText="No"
            >
                    
              <Button danger type='default'><DeleteOutlined /></Button>
            </Popconfirm>
          }
  ];
  const deleteUser = (id) => {
    console.log(id);
    apiService.delete(`/subadmin/delete/${id}`)
      .then((res) => {
        fetchAdmins({})
        message.success(res.message)
      })
    .catch(e => message.success(e.message))
  }
  const fetchPermission = (val) => {
    apiService.get(`/subadmin/get-permission/${val.key}`)
      .then(res => {
        const data = res.data
        form2.setFieldValue("server_permission", data.server_permission || "NONE")
        form2.setFieldValue("pending_user", data.pending_user || "NONE")
        form2.setFieldValue("broker", data.broker || "NONE")
        form2.setFieldValue("transaction", data.transaction || "NONE")
        form2.setFieldValue("customer", data.customer || "NONE")
        form2.setFieldValue("profit", data.profit || "NONE")
        setPermissionId(data.user)
        setPermissionOpen(true)
      })
    .catch(e => console.log(e))
  }
  const statusChange = (val,rec) => {
    apiService.post(`/auth/change-status`,{
      status: val,
      id: rec.key
    }).then(res => {
      message.success(res.message)
    fetchAdmins({})
    })
    .catch(e => message.error(e.message))
  }
  useEffect(() => {
    fetchAdmins({})

  },[])
  
  const fetchAdmins = (params) => {
    apiService.get("/subadmin/list",{
      ...params
    })
      .then(res => {
        const dataList = res.data.map((data, i) => ({
          index: i+1,
          key: data?.id,
          name: data?.fullName,
          email: data?.email,
          password: data?.password,
          role: data?.role,
          status: data?.status,
          action: data?.id
        }))
        setMemberList(dataList)
      })
      .catch(e => {
      console.log(e);
    })
  }

  const permissionCancel = () => {
    setPermissionOpen(false)
  }
  
  const formSubmit = (val) => {
    apiService.post("/subadmin/create",val)
      .then(() => {
        fetchAdmins({})
        setIsModalOpen(false)
        messageApi.open({
          type: 'success',
          content: 'Member Created',
        })
      })
      .catch(e => {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong',
        })
      })
  }
  const permissionUpdate = (val) => {
    apiService.put(`/subadmin/update-permission/${permissionId}`,val)
      .then(res => {
        setPermissionOpen(false)
        message.success(res.message)
      })
      .catch(e => {
        message.success(e.message)
      })
  }
  const filterData = (searchTxt) => {
    return memberList.filter(dt => dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
      dt.email.toLowerCase().includes(searchTxt.toLowerCase()) ||
      dt.role.toLowerCase().includes(searchTxt.toLowerCase()))
  } 
  const filterHandle = (val) => {
    fetchAdmins(val)
  }
  const resetFilter = () => {
    fetchAdmins({})
    form1.resetFields()
  }
  
  return (
    <>
      {contextHolder}
      <Modal title="Create Member" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
          <Form
          form={form}
          layout="vertical"
          onFinish={formSubmit}
        >
          <Form.Item label="Full Name" name={"fullName"} required>
            <Input placeholder="Enter password" />
          </Form.Item>
          <Form.Item label="Email" name={"email"} required>
            <Input placeholder="Enter Email" />
          </Form.Item>
          <Form.Item label="Password" name={"password"} required>
            <Input.Password placeholder="Enter Password" />
          </Form.Item>
          <Form.Item label="Role Name" name={"roleName"} required>
            <Input placeholder="Enter Role" />
          </Form.Item>
          <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
      <Modal title="Permission For Team Member" open={permissionOpen} onCancel={permissionCancel} footer={[]}>
          <Form
          form={form2}
          layout="vertical"
          onFinish={permissionUpdate}
        >
          <Form.Item label="Server" name={"server_permission"} required>
            <Select options={[{label: "View", value: "VIEW"},{label: "Edit", value: "EDIT"}, {label: "None", value: "NONE"}]} />
          </Form.Item>
          <Form.Item label="Pending Users" name={"pending_user"} required>
            <Select options={[{label: "View", value: "VIEW"},{label: "Edit", value: "EDIT"}, {label: "None", value: "NONE"}]} />
          </Form.Item>
          <Form.Item label="Broker" name={"broker"} required>
            <Select options={[{label: "View", value: "VIEW"},{label: "Edit", value: "EDIT"}, {label: "None", value: "NONE"}]} />
          </Form.Item>
          <Form.Item label="Transaction" name={"transaction"} required>
            <Select options={[{label: "View", value: "VIEW"},{label: "Edit", value: "EDIT"}, {label: "None", value: "NONE"}]} />
          </Form.Item>
          <Form.Item label="Customer" name={"customer"} required>
            <Select options={[{label: "View", value: "VIEW"},{label: "Edit", value: "EDIT"}, {label: "None", value: "NONE"}]} />
          </Form.Item>
          <Form.Item label="Profit" name={"profit"} required>
            <Select options={[{label: "View", value: "VIEW"},{label: "Edit", value: "EDIT"}, {label: "None", value: "NONE"}]} />
          </Form.Item>
          
          <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
        </Form>
      </Modal>
      <Typography.Title level={3} className="page-title">Sub Admin</Typography.Title>
      <Flex justify="end" style={{marginBottom: "20px"}}>
        <Input style={{marginRight: 5, width: 240}} placeholder="Search by Name/Email/Role..." onChange={(e) => setSearchText(e.target.value)} />
        <Button type="primary" onClick={() => setFilterShow(!filterShow)} style={{marginRight: 5}}><FilterOutlined /></Button>
        <Button type="primary" onClick={showModal}>
          Create Member
        </Button>
      </Flex>
      {filterShow && (<Card style={{marginBottom: "20px"}}>
        <Form
          form={form1}
          layout="vertical"
          onFinish={filterHandle}
        >
          <Row gutter={16}>
            
            <Col span={4}>
              <Form.Item name="status">
                <Select
                  placeholder="Select Status"
                  options={[
                    {value: 'ACTIVE',label: 'Activate'},
                    {value: 'DEACTIVE',label: 'Deactivate'}
                  ]}
              />
              </Form.Item>
            </Col>
            <Col>
              <Space>
                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                <Button type="default" onClick={resetFilter}>Reset</Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>)}
      <Table loading={isLoading} columns={columns} dataSource={searchText ? filterData(searchText) : memberList} />
    </>

  )
}
const mapStateToProps = ({loading}) => {
  const {isLoading} = loading
  return {isLoading}
}
export default connect(mapStateToProps, {}) (Team)