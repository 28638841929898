import {CloseOutlined,InboxOutlined,PlusOutlined} from '@ant-design/icons';
import {Button,Card,Checkbox,Col,DatePicker,Flex,Form,Input,Row,Select,Space,Typography,Upload,message} from 'antd'
import React,{useEffect, useState} from 'react'
import useCustomer from '../hooks/useCustomer';
import AxiosService from '../services/APIService';
import useBroker from '../hooks/useBroker';
import useServer from '../hooks/useServer';
import {connect} from 'react-redux';
import {Link,useLocation,useNavigate,useSearchParams} from 'react-router-dom';
import ContactInput from '../components/ContactInput';


function UserRegistration({isLoading}) {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const apiService = new AxiosService()
    const [userPreference,setuserPreference] = useState("Mr.");
    const [searchParams] = useSearchParams();
    const wlId = searchParams.get("wlid")
    const [formInitData, setFormInitData] = useState(null);

    const [formExtender,setFormExtender] = useState(false);
    const [fileList,setFileList] = useState([]);

    useEffect(() => {
        fetchFormData()
    },[]);
    const fetchFormData = () => {
        apiService.get("/form-data", {wlId})
            .then(res => {
                setFormInitData(res.data)
            })
            .catch(e => {
            console.log(e);
        })
    }

    const formSubmit = (val) => {
        if(val.docs.fileList.length === 0) {
            message.error("Please upload at least 1 Document.")
            return
        }
        const formData = new FormData()
        formData.append("fullName",val?.fullname)
        if(val?.phoneNo) formData.append("phone_no",val?.phoneNo)
        formData.append("whatsapp_no",val?.whatsappNo)
        formData.append("street",val?.street)
        formData.append("city",val?.city)
        formData.append("state",val?.state)
        formData.append("country",val?.country)
        formData.append("pincode",val?.pincode)
        formData.append("email",val?.username)
        formData.append("business_email",val?.email)
        formData.append("dob",val?.dob)
        formData.append("password",val?.password)
        formData.append("reference_by",val?.refName)
        formData.append("pref",userPreference)
        val.docs.fileList.forEach((fl,i) => {
            formData.append(`doc${i}`,fl.originFileObj)
        });

        if(formExtender || wlId) {
            formData.append("broker",val?.brokerName)
            formData.append("account_number",val?.accountNumber)
            formData.append("master_password",val?.masterPassword)
            formData.append("investor_password",val?.investorPassword)
            formData.append("portal_link",val?.portalLink)
            formData.append("portal_password",val?.portalPassword)
            formData.append("server",val?.brokerServer)
            if(val?.vpsServer) formData.append("server_vps",val?.vpsServer)
        }

        if(wlId) formData.append("white_label", wlId)
        let apiUrl = wlId ? 'auth/create-wluser' : "auth/register"
        apiService.post(apiUrl,formData)
            .then((res) => {
                message.success(`Register Successfully`);
                form.resetFields()
                navigate('/login')
            })
            .catch((e) => message.error("Something went wrong"))
    }

    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <div className="register-wrapper">
            <Card style={{width: "800px"}}>
                {wlId ?
                <div style={{textAlign: "center", marginBottom: 20}}>
                    <img src={`${process.env.REACT_APP_API_URL}white-label/logo/${wlId}`} style={{width: "20%", margin: "auto"}} /> 
                </div>: null}
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Full Name" name="fullname" rules={[{required: true,message: 'Please Enter full name!'}]}>
                                <Input addonBefore={<Select onChange={setuserPreference} style={{width: 70}} defaultValue={userPreference} options={[
                                    {value: "Mr.",label: "Mr."},
                                    {value: "Ms.",label: "Ms."},
                                    {value: "Mrs.",label: "Mrs."},
                                ]} />} placeholder="Enter Full Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email Address" name="email" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Street" name={"street"} rules={[{required: true,message: 'Please Enter Street!'}]}>
                                <Input placeholder="Street" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="City" name={"city"} rules={[{required: true,message: 'Please Enter City!'}]}>
                                        <Input placeholder="City" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="State" name={"state"} rules={[{required: true,message: 'Please Enter State!'}]}>
                                        <Input placeholder="State" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Country" name={"country"} rules={[{required: true,message: 'Please Enter Country!'}]}>
                                        <Input placeholder="Country" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Pincode" name={"pincode"} rules={[{required: true,message: 'Please Enter Pincode!'}]}>
                                        <Input placeholder="Pincode" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        {!wlId && <Col span={8}>
                            <Form.Item label="Reference by (Name)" name="refName">
                                <Select placeholder="Select Customer" showSearch options={formInitData?.users?.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>}
                        <Col span={8}>
                            <ContactInput form={form} name='phoneNo' label='Phone No.' />
                        </Col>
                        <Col span={8}>
                            <ContactInput form={form} name='whatsappNo' label='Whatsapp No.' require={true} />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Documents" name="docs" rules={[{required: true,message: 'Documents requires'}]}>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={handleChange}
                                    beforeUpload={() => false}
                                    accept=".pdf, .jpg, .jpeg, .png"
                                >
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Date of Birth" name="dob" rules={[{required: true,message: 'Please select date of birth!'}]}>
                                <DatePicker style={{width: '100%'}} format={{
                                    format: "DD-MM-YYYY",
                                    type: "mask"
                                }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Name (Email id)" name="username" rules={[{required: true,type: 'email',message: 'Please Enter valid email address!'}]}>
                                <Input placeholder="Enter User Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Password (user)" name="password" rules={[{required: true,message: 'Please Enter password!'}]}>
                                <Input.Password placeholder="Enter Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="User Confirm Password" name="confirmPassword" dependencies={['password']} rules={[
                                {
                                    required: true,
                                    message: 'Please confirm password!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_,value) {
                                        if(!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}>
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!wlId && <Flex align='center' style={{marginBottom: 15}}>
                        <span>Have Server Details?</span> &nbsp;
                        <Checkbox value={formExtender} onChange={(e) => setFormExtender(e.target.checked)} />
                    </Flex>}

                    {(formExtender || wlId) && <>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Broker Name" name="brokerName" rules={[{required: true,message: 'Please Enter broker name!'}]}>
                                    <Select
                                        placeholder="Select Broker"
                                        onChange={(val,dt) => {
                                            
                                            form.setFieldValue("brokerServer",dt.server)
                                            form.setFieldValue("portalLink",dt.link)
                                        }}
                                        options={formInitData?.broker?.map(bkr => ({
                                            label: `${bkr.name} (${bkr.ib_name})`,
                                            value: bkr._id,
                                            link: bkr.ib_link,
                                            server: bkr.broker_server_name
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Server (Broker)" name="brokerServer" rules={[{required: true,message: 'Please Enter broker server!'}]}>
                                    <Input placeholder="Enter Broker Server" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Account Number" name="accountNumber" rules={[{required: true,message: 'Please Enter account number!'}]}>
                                    <Input placeholder="Enter Account Number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Master Password" name="masterPassword" rules={[{required: true,message: 'Please Enter master password!'}]}>
                                    <Input.Password placeholder="Enter Master Password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Investor Password" name="investorPassword" rules={[{required: true,message: 'Please Enter investor password!'}]}>
                                    <Input.Password placeholder="Enter Investor Password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Portal Link" name="portalLink">
                                    <Input placeholder="Enter Portal Link" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Password (Broker Portal)" name="portalPassword">
                                    <Input.Password placeholder="Enter Portal Password" />
                                </Form.Item>
                            </Col>
                            {!wlId && <Col span={12}>
                                <Form.Item label="Vps Server" name="vpsServer" rules={[{required: true,message: 'Please select VPS server!'}]}>
                                    <Select placeholder="Select Vps Server" showSearch options={formInitData?.server?.map(srvr => ({
                                        value: srvr._id,
                                        label: srvr.vps_ip
                                    }))} filterOption={(input,option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } />
                                </Form.Item>
                            </Col>}
                        </Row>
                    </>}
                    <Flex align="center" justify="space-between">
                        <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                        <Typography.Text>Already Have Account? <Link to={'/login'}>Sign In</Link></Typography.Text>
                    </Flex>
                </Form>
            </Card>
        </div>
    )
}
const mapStateToProps = ({loading}) => {
    const {isLoading} = loading
    return {isLoading}
}
export default connect(mapStateToProps,{})(UserRegistration)