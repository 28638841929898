import React, {useEffect, useState} from 'react'
import APIService from "../services/APIService"
export default function useBroker(params) {
    const apiService = new APIService()
    const [brokerList, setBrokerList] = useState([])
    useEffect(() => {
        fetchBroker({})
    },[])
    
    const fetchBroker = () => {
        
        apiService.get('/broker/list', params)
            .then(res => {
                setBrokerList(res.data)
            })
            .catch(e => {
                console.log(e);
            })

    }

    return {
        brokerList,
        fetchBroker
    }
  
}
