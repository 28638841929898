import React,{useEffect,useState} from 'react'
import {Table,Select,Button,Modal,Form,Row,Col,Input,message,Alert,Flex,Card,Space,Typography,Popconfirm} from "antd";
import AxiosService from '../../services/APIService';
import {CloseOutlined,EditOutlined,FilterOutlined} from '@ant-design/icons';
import useBroker from '../../hooks/useBroker';
import useServer from '../../hooks/useServer';
import {connect} from 'react-redux';
import useCustomer from '../../hooks/useCustomer';
import {Link} from 'react-router-dom';
import {downloadFileHandle} from '../../utils/commonFunc';
const {Option} = Select;

export const PendingWhiteLabel = ({permission,user,isLoading}) => {
    const [isModalOpen,setIsModalOpen] = useState(false);
    const axiosService = new AxiosService()
    const [userList,setUserList] = useState();
    const {brokerList} = useBroker()
    const {serverList} = useServer()
    const [editData,seteditData] = useState("");
    const [searchText,setSearchText] = useState("");
    const [filterShow,setFilterShow] = useState(false);
    const {customerList} = useCustomer({})
    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            width: "50px"
        },
        {
            title: "White Label Name",
            dataIndex: "whiteLabelName",
            key: "whiteLabelName",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (e,rec) => <Link to={`/user-profile?userId=${rec.key}`}>{e}</Link>
        },
        {
            title: "Reference By",
            dataIndex: "reference",
            key: "reference",
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            render: (e,rec) => (permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Select
                defaultValue={e}
                value={e}
                onChange={(val) => statusChange(val,rec)}
                options={[
                    {value: 'PENDING',label: 'Pending'},
                    {value: 'CREATED',label: 'Create'}
                ]}
            />
        },{
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e,rec) => (permission?.customer == "EDIT" || user?.role != "SUBADMIN") && <Space>
                {/* <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => editOpen(e)} /> */}
                <Popconfirm
                    description="Are you sure you want to delete this account?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => deleteWhiteLabel(rec.key)}
                >
                    <Button icon={<CloseOutlined />} danger size={"middle"} />
                </Popconfirm>
            </Space>
        }
    ];
    const [form] = Form.useForm()
    const [form1] = Form.useForm()

    useEffect(() => {
        fetchPendingUser({})

    },[]);

    const deleteWhiteLabel = (id) => {
        axiosService.delete(`/white-label/delete-wl/${id}`)
            .then((res) => {
                fetchPendingUser({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }

    const statusChange = (val,rec) => {
        axiosService.post(`/white-label/change-status/${rec?.key}`,{
            status: val
        }).then(res => {
            message.success(res.message)
            fetchPendingUser({})
        })
            .catch(e => message.error(e.message))
    }

    const editOpen = (val) => {
        form.setFieldValue("brokerName",val?.broker?._id)
        form.setFieldValue("accountNumber",val?.account_number)
        form.setFieldValue("masterPassword",val?.master_password)
        form.setFieldValue("investorPassword",val?.investor_password)
        form.setFieldValue("portalLink",val?.portal_link)
        form.setFieldValue("portalPassword",val?.portal_password)
        form.setFieldValue("brokerServer",val?.server)
        form.setFieldValue("vpsServer",val?.server_vps?._id)
        seteditData(val._id)
        setIsModalOpen(true)
    }

    const fetchPendingUser = (params) => {
        axiosService.get('/white-label/list',{
            status: `["PENDING"]`,
            ...params
        }).then(res => {
            const data = res.data.map((dt,i) => ({
                key: dt._id,
                count: i + 1,
                whiteLabelName: dt?.name,
                name: `${dt?.user?.preference || ''} ${dt?.user?.fullName}`,
                reference: dt?.reference_by?.fullName || "-",
                status: dt?.status,
                action: dt
            }));
            setUserList(data)

        }).catch(e => {
            console.log(e);
        })
    }

    const handleOk = () => {
        setIsModalOpen(false);
        form.resetFields()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };
    const formSubmit = (val) => {
        const formData = new FormData()

        formData.append("broker",val?.brokerName)
        formData.append("account_number",val?.accountNumber)
        formData.append("master_password",val?.masterPassword)
        formData.append("investor_password",val?.investorPassword)
        formData.append("portal_link",val?.portalLink)
        formData.append("portal_password",val?.portalPassword)
        formData.append("server",val?.brokerServer)
        formData.append("server_vps",val?.vpsServer)

        axiosService.put(`/customer/admin-update/${editData}`,formData)
            .then(res => {
                message.success(res.message)
                handleOk()
                fetchPendingUser({})
                seteditData("")
            })
            .catch(e => message.error(e.message))
    }
    const filterData = (key) => {
        return userList.filter(dt => dt.name.toLowerCase().includes(key.toLowerCase()) ||
            dt.cutId.toLowerCase().includes(key.toLowerCase()))
    }
    const filterHandle = (val) => {
        fetchPendingUser(val)
    }
    const resetFilter = () => {
        form1.resetFields()
        fetchPendingUser({})

    }
    return (
        <>
            <Modal title="Update User"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={1000}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                    initialValues={editData}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Broker Name" name="brokerName" rules={[{required: true,message: 'Please Enter broker name!'}]}>
                                <Select placeholder="Select Broker" options={brokerList.map(brk => ({
                                    value: brk._id,
                                    label: brk.name
                                }))}>
                                    {brokerList.map(bkr => <Select.Option key={bkr._id} value={bkr._id}>{bkr.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Account Number" name="accountNumber" rules={[{required: true,message: 'Please Enter account number!'}]}>
                                <Input placeholder="Enter Account Number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Master Password" name="masterPassword" rules={[{required: true,message: 'Please Enter master password!'}]}>
                                <Input.Password placeholder="Enter Master Password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Investor Password" name="investorPassword" rules={[{required: true,message: 'Please Enter investor password!'}]}>
                                <Input.Password placeholder="Enter Investor Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Portal Link" name="portalLink" rules={[{required: true,message: 'Please Enter portal link!'}]}>
                                <Input placeholder="Enter Portal Link" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Password (Portal)" name="portalPassword" rules={[{required: true,message: 'Please Enter portal password!'}]}>
                                <Input.Password placeholder="Enter Portal Password" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Server (Broker)" name="brokerServer" rules={[{required: true,message: 'Please Enter broker server!'}]}>
                                <Input placeholder="Enter Broker Server" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Vps Server" name="vpsServer" rules={[{required: true,message: 'Please select VPS server!'}]}>
                                <Select placeholder="Select Vps Server" showSearch options={serverList.map(srvr => ({
                                    value: srvr._id,
                                    label: srvr.vps_ip
                                }))} filterOption={(input,option) =>
                                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">Pending White Labels</Typography.Title>
            <Flex justify="end" gap={5} style={{marginBottom: "20px"}}>
                <Input style={{maxWidth: 250}} placeholder="Search by Name..." onChange={(e) => setSearchText(e.target.value)} />
                <Button type="primary" onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button>
                <Button type="primary" onClick={() => downloadFileHandle(userList, 'pending_white_label')}>
                    Download
                </Button>
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="refName">
                                <Select placeholder="Select Reference" allowClear showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="brokerName">
                                <Select allowClear placeholder="Select Broker">
                                    {brokerList.map(bkr => <Option key={bkr._id} value={bkr._id}>{bkr.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="ibName">
                                <Select allowClear placeholder="Select IB">
                                    {[...new Set(brokerList.map(item => item.ib_name))].map(bkr => <Option key={bkr} value={bkr}>{bkr}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            {(permission?.customer == "EDIT" || permission?.customer == "VIEW" || user?.role != "SUBADMIN") ? <Table loading={isLoading} columns={columns} dataSource={searchText ? filterData(searchText) : userList} /> : <Alert message="You havn't Enough Permission" />}
        </>
    )
}

const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps,mapDispatchToProps)(PendingWhiteLabel)