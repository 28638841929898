import { Button, Checkbox, Col, Flex, Form, Input, message, Modal, Row, Select, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import AxiosService from '../services/APIService';
import moment from 'moment';
import { connect } from 'react-redux';
import useCustomer from '../hooks/useCustomer';
import dayjs from 'dayjs';

function Announcement({ isLoading }) {
    const [form] = Form.useForm();
    const { customerList } = useCustomer({ status: `["CREATED"]`, closed_user: false });
    const [modalVisible, setModalVisible] = useState(false);
    const apiService = new AxiosService();
    const [listData, setListData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const onFinish = (val) => {
        apiService.post("/notification/announcement", {
            message: val.message,
            customers: val.customers
        })
            .then(res => {
                fetchMessage();
                message.success(res.message);
                form.resetFields();
                setSelectedItems([]);
            })
            .catch(e => message.error(e.message));
    };

    useEffect(() => {
        fetchMessage();
    }, []);

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count"
        },
        {
            title: "Message",
            dataIndex: "msg",
            key: "msg"
        },
        {
            title: "Total User",
            dataIndex: "total",
            key: "total"
        }
    ];

    const fetchMessage = () => {
        apiService.get('/notification/announcement')
            .then(res => {
                const data = res.data.map((dt, i) => ({
                    count: i + 1,
                    key: dt._id,
                    msg: dt._id,
                    total: dt?.data?.length,
                    chld: dt.data
                }));
                setListData(data);
            })
            .catch(e => console.log(e));
    };

    const handleEditCancel = () => {
        setModalVisible(false);
    };

    const handleSelectChange = (selectedValues) => {
        if (selectedValues.includes('selectAll')) {
            if (selectedItems.length === customerList.length) {
                setSelectedItems([]);
                form.setFieldsValue({ customers: [] });
            } else {
                const allValues = customerList.map(dt => dt?.user?._id);
                setSelectedItems(allValues);
                form.setFieldsValue({ customers: allValues });
            }
        } else {
            setSelectedItems(selectedValues);
        }
    };

    const expandedRowRender = (dt) => {
        const data = dt?.chld?.map((dt, i) => {
            return ({
                index: i + 1,
                name: dt.user.fullName,
                sentAt: dayjs(dt.created_at).format("DD-MM-YYYY HH:mm")
            });
        });

        const clm = [
            {
                title: "#",
                dataIndex: "index",
                key: "index"
            },
            {
                title: "Name",
                dataIndex: "name",
                key: "name"
            },
            {
                title: "Sent At",
                dataIndex: "sentAt",
                key: "sentAt"
            },
        ];

        return <Table dataSource={data} columns={clm} />;
    };

    return (
        <>
            <Typography.Title level={3} className="page-title">Announcement</Typography.Title>
            <Modal
                title="Announcement"
                open={modalVisible}
                onCancel={handleEditCancel}
                footer={[]}
                width={500}
            >
                <Form
                    name="control-hooks"
                    onFinish={onFinish}
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        name="message"
                        label="Message"
                        rules={[
                            {
                                required: true,
                                message: "Message Required"
                            },
                        ]}
                    >
                        <Input placeholder="Enter Message For Customer" />
                    </Form.Item>
                    <Form.Item
                        name="customers"
                        label="Select Customers"
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select Customer"
                            value={selectedItems}
                            onChange={handleSelectChange}
                            options={[
                                {
                                    value: 'selectAll',
                                    label: (
                                        <Checkbox
                                            checked={selectedItems.length === customerList.length}
                                        >
                                            Select All
                                        </Checkbox>
                                    )
                                },
                                ...customerList.map(dt => ({
                                    value: dt?.user?._id,
                                    label: `${dt?.account_number} (${dt?.user?.fullName})`
                                }))
                            ]}
                            filterOption={(input, option) =>
                                option?.label?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Send
                        </Button>
                    </Space>
                </Form>
            </Modal>
            <Flex justify="end" style={{ marginBottom: "20px" }}>
                <Button type='primary' onClick={() => setModalVisible(true)}>Create New Message</Button>
            </Flex>
            <Table columns={columns} dataSource={listData} loading={isLoading} expandable={{
                expandedRowRender,
            }} />
        </>
    );
}

const mapStateToProps = ({ loading }) => {
    const { isLoading } = loading;
    return { isLoading };
};

export default connect(mapStateToProps, {})(Announcement);