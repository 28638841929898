import { ON_LOADING } from "../actions/loadingAction";

const initialState = {
    isLoading: false,
  };
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ON_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};

export default loadingReducer;