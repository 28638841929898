import { SET_ROUTE } from "../actions/routeAction";

const initialState = {
    currentRoute: "",
  };
const reouterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
      };

    default:
      return state;
  }
};

export default reouterReducer;