import React,{useEffect,useState} from 'react'
import useCustomer from '../hooks/useCustomer'
import {Button,Card,Col,Flex,Form,InputNumber,message,Modal,Row,Select,Space,Table,Tooltip} from 'antd';
import useFile from '../hooks/useFile';
import {connect} from 'react-redux';
import {CheckOutlined,CloseOutlined,EditOutlined} from '@ant-design/icons';
import AxiosService from '../services/APIService';
const EditableItem = ({
    hoveredRow,
    record,
    data,
    handleSuccess,
}) => {
    const [editing,setEditing] = useState(false);
    const [inputVal,setinputVal] = useState(data);

    const cancelUpdate = () => {
        setEditing(false)
        setinputVal(data)
    }

    return (
        <>
            {editing ? (
                <>
                    <InputNumber min={1} max={99} onChange={(val) => setinputVal(val)} value={inputVal} className="mr-2" style={{width: "70px"}} />
                    <Button
                        type="default"
                        onClick={() => {
                            handleSuccess(record.key,inputVal)
                            setEditing(false)
                        }}
                        icon={<CheckOutlined />}
                        size={"small"}
                        className="mr-1" />
                    <Button
                        type="default"
                        onClick={cancelUpdate}
                        icon={<CloseOutlined />}
                        size={"small"} danger />
                </>
            ) : (
                <div className="cell-wrapper">
                    {data}
                    <Space size="small" className="edit-wrapper">
                        {hoveredRow === record.key && (
                            <Tooltip title="Edit">
                                <Button
                                    size="small"
                                    className="action-btn mt-1"
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() => setEditing(true)}
                                />
                            </Tooltip>
                        )}
                    </Space>
                </div>)}
        </>
    )
}
function BotSettings({isLoading}) {
    const {customerList,fetchCustomer} = useCustomer()
    const {fileList} = useFile()
    const [userList,setUserList] = useState([]);
    const [hoveredRow,setHoveredRow] = useState(null);
    const handleRowHover = (record) => {
        setHoveredRow(record.key);
    };
    const [defaultModal,setDefaultModal] = useState(false);
    const apiService = new AxiosService()
    const [selectedRowKeys,setSelectedRowKeys] = useState([]);
    const [form] = Form.useForm()
    const [form1] = Form.useForm()
    
    const handleRowLeave = () => {
        setHoveredRow(null);
    };

    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
        },
        {
            title: "Bot Id",
            dataIndex: "cutId",
            key: "custId",
        },
        {
            title: "Account Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "File Name",
            key: "fileName",
            dataIndex: "fileName",
            render: (e,rec) => <>
                <Select
                    defaultValue={e}
                    value={e}
                    style={{width: "100%"}}
                    onChange={(val) => fileNameChangeHandle(val,rec)}
                    options={fileList.map(fl => ({
                        value: fl._id,
                        label: fl.file
                    }))}
                />
            </>,
        },
        {
            title: "Bot Setting",
            key: "botSetting",
            dataIndex: "botSetting",
            render: (e,rec) => <>
                <Select
                    defaultValue={e}
                    value={e}
                    style={{width: "100%"}}
                    onChange={(val) => botSettingChangeHandle(val,rec)}
                    options={[
                        {
                            value: "5-10",
                            label: "5-10%",
                        },
                        {
                            value: "10-15",
                            label: "10-15%",
                        },
                        {
                            value: "15-20",
                            label: "15-20%",
                        },
                    ]}
                />
            </>
        },
        {
            title: "Bot Charge",
            key: "botCharge",
            dataIndex: "botCharge",
            render: (val,rec) => <EditableItem record={rec} data={val} hoveredRow={hoveredRow} handleSuccess={botChargeChangeHandle} />
        },
        {
            title: "Due Date",
            key: "dueDate",
            dataIndex: "dueDate",
            render: (e,rec) => <>
                <Select
                    defaultValue={e}
                    value={e}
                    style={{width: "100%"}}
                    onChange={(val) => dueDateChangeHandle(val,rec)}
                    options={[
                        {
                            value: "",
                            label: "None"
                        },
                        {
                            value: "5",
                            label: "5th day of the month"
                        },
                        {
                            value: "10",
                            label: "10th day of the month"
                        },
                        {
                            value: "15",
                            label: "15th day of the month"
                        },
                    ]}
                />
            </>
        },
    ].filter(Boolean);

    useEffect(() => {
        if(customerList.length > 0) {
            const data = customerList.map((cst,i) => ({
                count: i + 1,
                key: cst._id,
                cutId: cst.cust_code,
                name: cst?.user?.fullName,
                fileName: cst?.file_name,
                botSetting: cst?.setting,
                botCharge: cst?.bot_charge,
                dueDate: cst?.bill_due_date,
            }))
            setUserList(data)
        }
    },[customerList]);

    useEffect(() => {
        fetchDefaultSetting()
    },[]);
    const fetchDefaultSetting = () => [
        apiService.get("/customer/default-setting")
            .then(res => {
                const dataVal = res.data
                if(dataVal) {
                    form1.setFieldValue("file", dataVal.file)
                    form1.setFieldValue("botSetting", dataVal.bot_Setting)
                    form1.setFieldValue("botCharge", dataVal.bot_charge)
                    form1.setFieldValue("dueDate", dataVal.due_date)
                }
            })
            .catch(e => {
            console.log(e);
        })
    ]

    const fileNameChangeHandle = (value,record) => {
        apiService.put(`/customer/set-file/${record.key}`,{
            file: value
        })
            .then(res => {
                message.success(res.message)
                fetchCustomer()
            })
            .catch(e => {
                message.error(e.message)
            })

    }
    const botSettingChangeHandle = (value,record) => {
        apiService.put(`/customer/save-setting/${record.key}`,{
            setting: value
        })
            .then(res => {
                message.success(res.message)
                fetchCustomer()
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const botChargeChangeHandle = (id,value) => {
        apiService.put(`/customer/set-botcharge/${id}`,{
            charge: value
        })
            .then(res => {
                message.success(res.message)
                fetchCustomer()
            })
            .catch(e => message.error(e.message))
    }
    const dueDateChangeHandle = (value,record) => {
        apiService.put(`/profit/bill-date/${record.key}`,{
            date: value
        })
            .then(res => {
                message.success(res.message)
                fetchCustomer()
            })
            .catch(e => message.error(e.message))
    }
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ',newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const filterHandle = (val) => {
        const {botCharge,botSetting,dueDate,file} = val
        if(!botCharge && !botSetting && !dueDate && !file) {
            message.error("Please select at least one field")
            return
        }
        apiService.post('/customer/bulk-setting',{...val,ids: selectedRowKeys})
            .then(res => {
                message.success(res.message);
                fetchCustomer();
                form.resetFields()
            })
            .catch(e => {
                message.error(e.message);
            })

    }
    const handleDefaultSetting = (val) => {
        const {botCharge,botSetting,dueDate,file} = val
        if(!botCharge && !botSetting && !dueDate && !file) {
            message.error("Please select at least one field")
            return
        }
        apiService.post('/customer/default-setting',{...val})
            .then(res => {
                message.success(res.message);
                setDefaultModal(false)
                fetchDefaultSetting()
            })
            .catch(e => {
                message.error(e.message);
            })
    }
    return (
        <>
            {selectedRowKeys.length > 0 && <Card style={{marginBottom: 10}}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={10}>
                        <Col span={5}>
                            <Form.Item name="file" style={{marginBottom: 0}}>
                                <Select
                                    style={{width: "100%"}}
                                    options={fileList.map(fl => ({
                                        value: fl._id,
                                        label: fl.file
                                    }))}
                                    placeholder="Select File"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="botSetting" style={{marginBottom: 0}}>
                                <Select
                                    style={{width: "100%"}}
                                    options={[
                                        {
                                            value: "5-10",
                                            label: "5-10%",
                                        },
                                        {
                                            value: "10-15",
                                            label: "10-15%",
                                        },
                                        {
                                            value: "15-20",
                                            label: "15-20%",
                                        },
                                    ]}
                                    placeholder="Select Bot Setting"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="botCharge" style={{marginBottom: 0}}>
                                <InputNumber min={1} max={99} placeholder='Enter Bot Charge Pr' className="mr-2" style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item name="dueDate" style={{marginBottom: 0}}>
                                <Select
                                    style={{width: "100%"}}
                                    options={[
                                        {
                                            value: "",
                                            label: "None"
                                        },
                                        {
                                            value: "5",
                                            label: "5th day of the month"
                                        },
                                        {
                                            value: "10",
                                            label: "10th day of the month"
                                        },
                                        {
                                            value: "15",
                                            label: "15th day of the month"
                                        },
                                    ]}
                                    placeholder="Select Due Date"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button htmlType='submit' type='primary'>Save</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>}
            <Modal title="Default Setting"
                open={defaultModal}
                onCancel={() => setDefaultModal(false)}
                footer={[]}
            >
                <Form
                    form={form1}
                    layout="vertical"
                    onFinish={handleDefaultSetting}
                >
                    <Row gutter={[10, 10]}>
                        <Col span={12}>
                            <Form.Item name="file" style={{marginBottom: 0}}>
                                <Select
                                    style={{width: "100%"}}
                                    options={fileList.map(fl => ({
                                        value: fl._id,
                                        label: fl.file
                                    }))}
                                    placeholder="Select File"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="botSetting" style={{marginBottom: 0}}>
                                <Select
                                    style={{width: "100%"}}
                                    options={[
                                        {
                                            value: "5-10",
                                            label: "5-10%",
                                        },
                                        {
                                            value: "10-15",
                                            label: "10-15%",
                                        },
                                        {
                                            value: "15-20",
                                            label: "15-20%",
                                        },
                                    ]}
                                    placeholder="Select Bot Setting"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="botCharge" style={{marginBottom: 0}}>
                                <InputNumber min={1} max={99} placeholder='Enter Bot Charge Pr' className="mr-2" style={{width: "100%"}} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="dueDate" style={{marginBottom: 0}}>
                                <Select
                                    style={{width: "100%"}}
                                    options={[
                                        {
                                            value: "",
                                            label: "None"
                                        },
                                        {
                                            value: "5",
                                            label: "5th day of the month"
                                        },
                                        {
                                            value: "10",
                                            label: "10th day of the month"
                                        },
                                        {
                                            value: "15",
                                            label: "15th day of the month"
                                        },
                                    ]}
                                    placeholder="Select Due Date"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button htmlType='submit' type='primary'>Save</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Flex justify='end' style={{marginBottom: 10}}>
                <Button onClick={() => setDefaultModal(true)} type='primary'>Default Setting</Button>
            </Flex>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={userList}
                rowSelection={rowSelection}
                onRow={(record) => ({
                    onMouseEnter: () => handleRowHover(record),
                    onMouseLeave: handleRowLeave,
                })} />
        </>
    )
}
const mapStateToProps = ({loading}) => {
    const {isLoading} = loading
    return {isLoading}
}
export default connect(mapStateToProps,{})(BotSettings);