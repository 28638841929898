import {Table,Typography} from 'antd'
import moment from 'moment'
import React,{useEffect, useState} from 'react'
import AxiosService from '../../services/APIService'

const profData = [
    {
        "index": 1,
        "key": "6640c95634a7947b702f6677",
        "botId": "ELKCC2",
        "broker": "Smit",
        "name": "Smit Lathiya",
        "acNo": "9678688678867",
        "amount": 8000,
        "botChargeAmount": 1575,
        "botCharge": 19,
        "dueAmount": 2075,
        "dueDate": "15-06-2024",
        "chld": [
            {
                "_id": "667c5f6c9cfddc0a4c4e817d",
                "customer": "6640c95634a7947b702f6677",
                "profit": 2400,
                "master_profit": 8000,
                "bot_charg": 456,
                "charge_pr": 19,
                "created_at": "2024-06-26T18:35:24.767Z",
                "__v": 0,
                "created_at_date": "26-06-2024"
            },
            {
                "_id": "667c5f479cfddc0a4c4e8165",
                "customer": "6640c95634a7947b702f6677",
                "profit": 100,
                "master_profit": 5600,
                "bot_charg": 19,
                "charge_pr": 19,
                "created_at": "2024-06-26T18:34:47.536Z",
                "__v": 0,
                "created_at_date": "26-06-2024"
            },
            {
                "_id": "664b9cb77e0fd6e7bef0caf3",
                "customer": "6640c95634a7947b702f6677",
                "profit": 300,
                "master_profit": 5500,
                "bot_charg": 57,
                "charge_pr": 19,
                "created_at": "2024-05-20T18:55:51.485Z",
                "__v": 0,
                "created_at_date": "20-05-2024"
            },
            {
                "_id": "664b9ca87e0fd6e7bef0caeb",
                "customer": "6640c95634a7947b702f6677",
                "profit": 400,
                "master_profit": 5200,
                "bot_charg": 76,
                "charge_pr": 19,
                "created_at": "2024-05-20T18:55:36.326Z",
                "__v": 0,
                "created_at_date": "20-05-2024"
            },
            {
                "_id": "6647ac5e52760d42dafc2ccb",
                "customer": "6640c95634a7947b702f6677",
                "profit": 100,
                "master_profit": 4800,
                "bot_charg": 19,
                "charge_pr": 19,
                "created_at": "2024-05-17T19:13:34.943Z",
                "__v": 0,
                "created_at_date": "17-05-2024"
            },
            {
                "_id": "6647a893d96bc61cfd7a126f",
                "customer": "6640c95634a7947b702f6677",
                "profit": 200,
                "master_profit": 4700,
                "bot_charg": 38,
                "charge_pr": 19,
                "created_at": "2024-05-17T18:57:23.858Z",
                "__v": 0,
                "created_at_date": "17-05-2024"
            },
            {
                "_id": "6647a6f1d96bc61cfd7a121f",
                "customer": "6640c95634a7947b702f6677",
                "profit": 500,
                "master_profit": 4500,
                "bot_charg": 95,
                "charge_pr": 19,
                "created_at": "2024-05-17T18:50:25.045Z",
                "__v": 0,
                "created_at_date": "17-05-2024"
            },
            {
                "_id": "6647a6c9d96bc61cfd7a120d",
                "customer": "6640c95634a7947b702f6677",
                "profit": 2100,
                "master_profit": 4000,
                "bot_charg": 399,
                "charge_pr": 19,
                "created_at": "2024-05-17T18:49:45.291Z",
                "__v": 0,
                "created_at_date": "17-05-2024"
            },
            {
                "_id": "66464b92ffa08612e3181284",
                "customer": "6640c95634a7947b702f6677",
                "profit": 1400,
                "master_profit": 1900,
                "bot_charg": 266,
                "charge_pr": 19,
                "created_at": "2024-05-16T18:08:18.139Z",
                "__v": 0,
                "created_at_date": "16-05-2024"
            },
            {
                "_id": "66464adaffa08612e318122f",
                "customer": "6640c95634a7947b702f6677",
                "profit": 500,
                "master_profit": 500,
                "bot_charg": 150,
                "charge_pr": 30,
                "created_at": "2024-05-16T18:05:14.855Z",
                "__v": 0,
                "created_at_date": "16-05-2024"
            }
        ]
    },
    {
        "index": 2,
        "key": "66438273e3c25d5becac3276",
        "botId": "ISVW4T",
        "broker": "Smit",
        "name": "Kerry Rose",
        "acNo": "414",
        "amount": 1400,
        "botChargeAmount": 231,
        "botCharge": 17,
        "dueAmount": 231,
        "dueDate": "10-05-2024",
        "chld": [
            {
                "_id": "664b9c977e0fd6e7bef0cae3",
                "customer": "66438273e3c25d5becac3276",
                "profit": 200,
                "master_profit": 1400,
                "bot_charg": 34,
                "charge_pr": 17,
                "created_at": "2024-05-20T18:55:19.308Z",
                "__v": 0,
                "created_at_date": "20-05-2024"
            },
            {
                "_id": "66464b76ffa08612e318127c",
                "customer": "66438273e3c25d5becac3276",
                "profit": 1100,
                "master_profit": 1200,
                "bot_charg": 187,
                "charge_pr": 17,
                "created_at": "2024-05-16T18:07:50.604Z",
                "__v": 0,
                "created_at_date": "16-05-2024"
            },
            {
                "_id": "66464a1fffa08612e3181204",
                "customer": "66438273e3c25d5becac3276",
                "profit": 100,
                "master_profit": 100,
                "bot_charg": 10,
                "charge_pr": 10,
                "created_at": "2024-05-16T18:02:07.033Z",
                "__v": 0,
                "created_at_date": "16-05-2024"
            }
        ]
    },
    {
        "index": 3,
        "key": "66464c0effa08612e31812a2",
        "botId": "M9KYG1",
        "broker": "Smit",
        "name": "Alan Mckee",
        "acNo": "950",
        "amount": 800,
        "botChargeAmount": 104,
        "botCharge": 13,
        "dueAmount": 104,
        "dueDate": "05-05-2024",
        "chld": [
            {
                "_id": "664cddcff2a72d7e063bafe3",
                "customer": "66464c0effa08612e31812a2",
                "profit": 500,
                "master_profit": 800,
                "bot_charg": 65,
                "charge_pr": 13,
                "created_at": "2024-05-21T17:45:51.399Z",
                "__v": 0,
                "created_at_date": "21-05-2024"
            },
            {
                "_id": "664b9c8a7e0fd6e7bef0cadb",
                "customer": "66464c0effa08612e31812a2",
                "profit": 300,
                "master_profit": 300,
                "bot_charg": 39,
                "charge_pr": 13,
                "created_at": "2024-05-20T18:55:06.326Z",
                "__v": 0,
                "created_at_date": "20-05-2024"
            }
        ]
    },
    {
        "index": 4,
        "key": "664cda4df15fc496a3fc98c5",
        "botId": "DP2WHY",
        "broker": "Smit",
        "name": "Linus Wiggins",
        "acNo": "842",
        "amount": 1500,
        "botChargeAmount": 450,
        "botCharge": 30,
        "dueAmount": 450,
        "dueDate": "05-06-2024",
        "chld": [
            {
                "_id": "666ebd3e23065aca9effd701",
                "customer": "664cda4df15fc496a3fc98c5",
                "profit": 1500,
                "master_profit": 1500,
                "bot_charg": 450,
                "charge_pr": 30,
                "created_at": "2024-06-16T10:23:58.201Z",
                "__v": 0,
                "created_at_date": "16-06-2024"
            }
        ]
    }
]
export default function WLProfit() {
    const apiService = new AxiosService()
    const [whiteLabelList, setWhiteLabelList] = useState([]);
    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            width: 50
        },
        {
            title: "White Label",
            dataIndex: "whiteLabel",
            key: "whiteLabel",
        }
    ]

    useEffect(() => {
        fetchProfit()
    },[]);

    const fetchProfit = () => {
        apiService.get('/profit/list-wl')
            .then(res => {
                let data = res.data.map((dt, i) => ({
                    index: i + 1,
                    key: i,
                    whiteLabel: dt.white_label.name,
                    data: dt.customers
                }))
                setWhiteLabelList(data)
            })
            .catch(e => console.log(e))
    }

    const expandedRowRender = (customers) => {
        const profitCol = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                fixed: 'left',
                width: 50
            },
            {
                title: "Bot Id",
                dataIndex: "botId",
                key: "botId",
                fixed: 'left',
                width: 100
            },
            {
                title: "Account Name",
                dataIndex: "name",
                key: "name",
                width: 300
            },
            {
                title: "Account Number",
                dataIndex: "acNo",
                key: "acNo",
                width: 170
            },
            {
                title: "Broker",
                dataIndex: "broker",
                key: "broker",
                width: 150
            },
            {
                title: "Profit Amount",
                key: "amount",
                dataIndex: "amount",
                width: 170
            },
            {
                title: "Bot Charge",
                key: "botCharge",
                dataIndex: "botCharge",
                render: (_) => `${_}%`,
                width: 170
            },
            {
                title: "Bot Charge Amount",
                key: "botChargeAmount",
                dataIndex: "botChargeAmount",
                width: 200
            },
            {
                title: "Due Amount",
                key: "dueAmount",
                dataIndex: "dueAmount",
                width: 150
            },
            {
                title: "Due Date",
                key: "dueDate",
                dataIndex: "dueDate",
                width: 170
            },
        ];
        return <Table
            scroll={{x: 1500}}
            columns={profitCol}
            dataSource={customers.data}
            expandable={{expandedRowRender: innerTable,rowExpandable: (rec) => rec?.chld?.length > 0}}
            pagination={false}
        />
    }
    const innerTable = (rec) => {
        const innerColumns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                fixed: 'left',
                width: 50
            },
            {
                title: "Date and Time",
                dataIndex: "dateTime",
                key: "dateTime",
                width: 170
            },
            {
                title: "Profit Amount",
                key: "amount",
                dataIndex: "amount",
                width: 170
            },
            {
                title: "Bot Charge",
                key: "botCharge",
                dataIndex: "botCharge",
                render: (_) => `${_}%`,
                width: 170
            },
            {
                title: "Bot Charge Amount",
                key: "botChargeAmount",
                dataIndex: "botChargeAmount",
                width: 200
            }
        ];
        const data = rec.chld.map((d,i) => ({
            index: i + 1,
            key: d._id,
            dateTime: moment(d.created_at).format("DD-MM-YYYY HH:mm"),
            botId: rec.botId,
            name: rec.name,
            acNo: rec.acNo,
            broker: rec.broker,
            amount: d.profit,
            botCharge: d.charge_pr,
            botChargeAmount: d.bot_charg,
            dueAmount: rec.dueAmount,
            dueDate: rec.dueDate,
        }))

        return <Table dataSource={data} columns={innerColumns} pagination={false} className='inner-table' />

    }
    return (
        <>
            <Typography.Title level={3} className="page-title">Profit</Typography.Title>
            <Table
                columns={columns}
                dataSource={whiteLabelList}
                expandable={{expandedRowRender}}
                scroll={{x: 1500}}
            />
        </>
    )
}
