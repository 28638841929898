import {Button, Descriptions, Flex, Form, message, Modal, Select, Table, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import AxiosService from '../services/APIService';
import {connect} from 'react-redux';
import moment from 'moment';

function BotPerformance({isLoading, customer}) {
    const [form] = Form.useForm()
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [transactionList, setTransactionList] = useState([]);
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        fetchTransaction()
    }, []);
    const apiService = new AxiosService()
    const columns = [
        {
            title: "#",
            key: "index",
            dataIndex: "index"
        },
        {
            title: "Setting Percentage",
            key: "settingPr",
            dataIndex: "settingPr"
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Request Date",
            dataIndex: "reqDate",
            key: "reqDate"
        },
        {
            title: "Action Date",
            dataIndex: "actionDate",
            key: "actionDate"
        },
        {
            title: "By",
            key: "by",
            dataIndex: "by",
        }
    ];
    const formSubmit = (val) => {
        apiService.post("/customer/gen-setting-req",{
            setting: val.percentage
        })
        .then((res) => {
            message.success(res.message)
            form.resetFields()
            setIsModalOpen(false)
            fetchTransaction()
        })
        .catch(e => message.error(e.message))
    }
    const fetchTransaction = () => {
        apiService.get('/customer/my-settings')
            .then(res => {
                const data = res.data.map((dt,i) => ({
                    index: i + 1,
                    key: dt._id,
                    settingPr: dt.setting,
                    status: dt.status,
                    reqDate: moment(dt.created_at).format("DD-MM-YYYY"),
                    actionDate: dt.action_date ? moment(dt.action_date).format("DD-MM-YYYY"): "-",
                    by: dt?.customer?.user?.role,
                }))
                setTransactionList(data)
            })
            .catch(e => {
            console.log(e);
        })
    }
    return (
        <>
            <Modal title="Change Setting"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
                width={600}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="Percentage" name="percentage" rules={[{required: true,message: 'Amount Required!'}]}
                        extra="Percentage is base on Account Balance Amount.">
                        <Select placeholder="Select Setting" options={[
                            {
                                value: "5-10",
                                label: "5-10"
                            },
                            {
                                value: "10-15",
                                label: "10-15"
                            },
                            {
                                value: "15-20",
                                label: "15-20"
                            }
                        ]} />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
                
            </Modal>
            <Typography.Title level={3} className="page-title">Bot Performance</Typography.Title>
            <Descriptions size="small" title="Flow Information" bordered style={{marginBottom: "20px"}}>
                <Descriptions.Item label="File Name">
                    {customer?.file_name?.file}
                </Descriptions.Item>
                <Descriptions.Item label="Setting Percentage">
                        {customer?.setting ? customer?.setting + "%" : ""}
                </Descriptions.Item>
            </Descriptions>
            <Flex justify="end" align='center' style={{marginBottom: "20px"}}>
                <Button type="primary" onClick={showModal}>
                    Change Setting
                </Button>
            </Flex>
            <Table loading={isLoading} columns={columns} dataSource={transactionList} />

        </>
    )
}
const mapStateToProps = ({login, loading, billInfo}) => {
    const {wallet, customer} = login
    const {isLoading} = loading
    const {billDetail, totalProfit} = billInfo
    return {wallet, isLoading,customer, billDetail, totalProfit}
  }
export default connect(mapStateToProps, {}) (BotPerformance)