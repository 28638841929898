export const SET_BILL = "billReducer/SET_BILL";
export const SET_PROFIT = "billReducer/SET_PROFIT";

export const setBill = (val)  => (disptch) => {
  return disptch({
    type: SET_BILL,
    payload: val,
  });
};
export const setProfit = (val)  => (disptch) => {
  return disptch({
    type: SET_PROFIT,
    payload: val,
  });
};