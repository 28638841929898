import React,{useState} from "react";
import APIService from "../services/APIService"
import {Table,Modal,Form,Input,message,Button,Flex,Alert,Space,Popconfirm,Typography} from "antd";
import useBroker from "../hooks/useBroker";
import {connect} from "react-redux";
import {DeleteOutlined,EditOutlined,FilterOutlined} from "@ant-design/icons";
function Brokers({permission,user,isLoading}) {
    const {brokerList,fetchBroker} = useBroker()
    const apiService = new APIService()
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [isEditModalOpen,setIsEditModalOpen] = useState(false);
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [messageApi,contextHolder] = message.useMessage();
    const [editId,setEditId] = useState("");
    const [searchText,setSearchText] = useState("");
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields()
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleEditCancel = () => {
        setIsEditModalOpen(false);
    };
    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
        },
        {
            title: "Broker Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "IB Name",
            dataIndex: "ibName",
            key: "ibName",
        },
        {
            title: "IB Link",
            dataIndex: "ibLink",
            key: "ibLink",
            render: (e) => <a href={e} target="_blank">{e}</a>
        },
        {
            title: "Portal Link",
            key: "portalLink",
            dataIndex: "portalLink",
            render: (e) => <a href={e} target="_blank">{e}</a>
        },
        {
            title: "Broker Server Name",
            dataIndex: "brokerServerName",
            key: "brokerServerName",
        },
        {
            title: "By",
            key: "by",
            dataIndex: "by",
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e,rec) => (permission?.customer == "EDIT" || user?.role != "SUBADMIN") &&
                <Space>
                    <Button type="default" icon={<EditOutlined />} size={"middle"} onClick={() => editOpen(rec)} />
                    <Popconfirm
                        title="Delete the Broker"
                        description="Are you sure?"
                        onConfirm={() => deleteRecord(rec)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="default" icon={<DeleteOutlined />} size={"middle"} danger />
                    </Popconfirm>
                </Space>
        }
    ];
    const deleteRecord = (rec) => {
        apiService.delete(`/broker/delete/${rec.key}`)
            .then(res => {
                message.success("Broker Deleted")
                fetchBroker()
            })
            .catch(e => message.error("Something went wrong"))
    }
    const editOpen = (rec) => {
        const data = rec.record
        setEditId(data?._id)
        form2.setFieldValue("name",data?.name)
        form2.setFieldValue("ibName",data?.ib_name)
        form2.setFieldValue("ibLink",data?.ib_link)
        form2.setFieldValue("portalLink",data?.portal_link)
        form2.setFieldValue("brokerServerName",data?.broker_server_name)
        setIsEditModalOpen(true)
    }
    const formSubmit = (val) => {
        apiService.post("/broker/create",val)
            .then(() => {
                fetchBroker()
                setIsModalOpen(false)
                messageApi.open({
                    type: 'success',
                    content: 'Broker Created',
                })
            })
            .catch(e => {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                })
            })
    }
    const editFormSubmit = (val) => {
        apiService.put(`/broker/update/${editId}`,{
            name: val?.name,
            ib_name: val?.ibName,
            ib_link: val?.ibLink,
            portal_link: val?.portalLink,
            broker_server_name: val?.brokerServerName,
        })
            .then(res => {
                message.success(res.message)
                fetchBroker()
                handleEditCancel()
            })
            .catch(e => message.error(e.message))
    }
    return (
        <>
            {contextHolder}
            <Modal title="Create Broker" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={formSubmit}
                >
                    <Form.Item label="Company name" name={"name"} rules={[{required: true,message: 'Please Enter Company name!'}]}>
                        <Input placeholder="Enter Company name" />
                    </Form.Item>
                    <Form.Item label="IB Name" name={"ibName"} rules={[{required: true,message: 'Please Enter IB Name!'}]}>
                        <Input placeholder="Enter UserName" />
                    </Form.Item>
                    <Form.Item label="IB Link" name={"ibLink"} rules={[{required: true,message: 'Please Enter IB Link!'}]}>
                        <Input placeholder="Enter IB Link" />
                    </Form.Item>
                    <Form.Item label="Portal Link" name={"portalLink"} rules={[{required: true,message: 'Please Enter Portal Link!'}]}>
                        <Input placeholder="Enter Portal Link" />
                    </Form.Item>
                    <Form.Item label="Broker Server Name" name={"brokerServerName"} rules={[{required: true,message: 'Please Enter Broker Server Name!'}]}>
                        <Input placeholder="Enter Broker Server Name" />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Modal title="Edit Broker" open={isEditModalOpen} onCancel={handleEditCancel} footer={[]}>
                <Form
                    form={form2}
                    layout="vertical"
                    onFinish={editFormSubmit}
                >
                    <Form.Item label="Company name" name={"name"} rules={[{required: true,message: 'Please Enter Company name!'}]}>
                        <Input placeholder="Enter Company name" />
                    </Form.Item>
                    <Form.Item label="IB Name" name={"ibName"} rules={[{required: true,message: 'Please Enter IB Name!'}]}>
                        <Input placeholder="Enter UserName" />
                    </Form.Item>
                    <Form.Item label="IB Link" name={"ibLink"} rules={[{required: true,message: 'Please Enter IB Link!'}]}>
                        <Input placeholder="Enter IB Link" />
                    </Form.Item>
                    <Form.Item label="Portal Link" name={"portalLink"} rules={[{required: true,message: 'Please Enter Portal Link!'}]}>
                        <Input placeholder="Enter Portal Link" />
                    </Form.Item>
                    <Form.Item label="Broker Server Name" name={"brokerServerName"} rules={[{required: true,message: 'Please Enter Broker Server Name!'}]}>
                        <Input placeholder="Enter Broker Server Name" />
                    </Form.Item>
                    <Button loading={isLoading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Modal>
            <Typography.Title level={3} className="page-title">Broker</Typography.Title>
            <Flex justify="end" style={{marginBottom: "20px"}}>
                <Input style={{marginRight: 5,maxWidth: 250}} placeholder="Search by Broker Name / IB Name..." onChange={(e) => setSearchText(e.target.value)} />
                {/* <Button type="default" style={{marginRight: 5}}><FilterOutlined /></Button> */}
                {(permission?.broker == "EDIT" || user?.role != "SUBADMIN") && <Button type="primary" onClick={showModal}>
                    Create Broker
                </Button>}
            </Flex>
            {(permission?.broker == "EDIT" || permission?.broker == "VIEW" || user?.role != "SUBADMIN") ?
                <Table loading={isLoading} columns={columns} dataSource={brokerList.filter(dt => {
                    return dt.name.toLowerCase().includes(searchText.toLowerCase()) ||
                        dt.ib_name.toLowerCase().includes(searchText.toLowerCase())
                }).map((data,i) => ({
                    key: data._id,
                    index: i + 1,
                    name: data?.name,
                    ibName: data?.ib_name,
                    ibLink: data?.ib_link,
                    portalLink: data?.portal_link,
                    record: data,
                    brokerServerName: data?.broker_server_name,
                    by: data?.created_by?.fullName
                }))} /> : <Alert message="You havn't Enough Permission" />}

        </>
    );
}
const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}
export default connect(mapStateToProps,{})(Brokers)