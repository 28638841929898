import React,{useEffect,useState} from "react";
import {Table,Select,Form,Input,Modal,Button,Flex,Row,Col,Upload,message,DatePicker,Space,Alert,Card, Popconfirm, Image, Typography} from "antd";
import {InboxOutlined,CloseOutlined,EditOutlined,FilterOutlined} from "@ant-design/icons";
import useBroker from "../../hooks/useBroker";
import useServer from "../../hooks/useServer";
import useCustomer from "../../hooks/useCustomer";
import AxiosService from "../../services/APIService";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import moment from "moment";
import {downloadFileHandle} from "../../utils/commonFunc";

const {Option} = Select;

export const CloseWhiteLabel = ({permission,user,isLoading}) => {
    const apiService = new AxiosService()
    const [createdUserList,setCreatedUserList] = useState([]);
    const {brokerList} = useBroker()
    const {customerList} = useCustomer({})
    const [form2] = Form.useForm()
    const [filterShow,setFilterShow] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [docUrl,setDocUrl] = useState("")
    const [isPreviewVisible,setPreviewVisible] = useState(false);
    const {serverList} = useServer()
    const columns = [
        {
            title: "#",
            dataIndex: "count",
            key: "count",
            fixed: 'left',
            width: "50px"
        },
        {
            title: "White Label Name",
            dataIndex: "whiteLabelName",
            key: "whiteLabelName",
            fixed: 'left',
            render: (e,rec) => e
        },
        {
            title: "Full Name",
            dataIndex: "name",
            key: "name",
            fixed: 'left',
            render: (e,rec) => e
        },
        {
            title: "Action",
            key: "action",
            dataIndex: "action",
            render: (e,rec) => (permission?.customer === "EDIT" || user?.role !== "SUBADMIN") &&
                <Space>
                    <Button type="default" size={"middle"} onClick={() => onActivateCustomer(rec.key)}>Re-Activate</Button>
                    <Popconfirm
                        description="Are you sure you want to delete this account?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteCustomer(rec.key)}
                    >
                        <Button icon={<CloseOutlined />} danger size={"middle"} />
                    </Popconfirm>
                </Space>
        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Password",
            dataIndex: "password",
            key: "password",
        },
        {
            title: "Account Reference By",
            dataIndex: "reference",
            key: "reference",
        },
        {
            title: "Date of Birth",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Phone Number",
            dataIndex: "phNo",
            key: "phNo",
        },
        {
            title: "Whatsapp Number",
            dataIndex: "wpNum",
            key: "wpNum",
        },
        {
            title: "Document",
            dataIndex: "doc",
            key: "doc",
            render: (_,rec) => <Space>
                <Image
                    width={0}
                    preview={{
                        visible: isPreviewVisible,
                        onVisibleChange: (visible,prevVisible) => {
                            setPreviewVisible(visible)
                            setDocUrl("")
                        },
                    }}
                    src={docUrl}
                />
                <Button type="default" onClick={() => imagePreviewOpen("document1",rec)}>Doc 1</Button>
                <Button type="default" onClick={() => imagePreviewOpen("document2",rec)}>Doc 2</Button>
            </Space>
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",
        },
        {
            title: "State",
            dataIndex: "state",
            key: "state",
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
        },
        {
            title: "Pincode",
            dataIndex: "pincode",
            key: "pincode",
        },
    ];

    const onActivateCustomer = (id) => {
        apiService.post(`/white-label/re-open/${id}`)
            .then((res) => {
                fetchCreatedUsers({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const deleteCustomer = (id) => {
        apiService.delete(`/white-label/delete-wl/${id}`)
            .then((res) => {
                fetchCreatedUsers({})
                message.success(res.message)
            })
            .catch(e => {
                message.error(e.message)
            })
    }
    const imagePreviewOpen = (doc,rec) => {
        setDocUrl(`${process.env.REACT_APP_API_URL}doc/${rec?.record?.user?._id}/${doc}`)
        setPreviewVisible(true)
    }

    useEffect(() => {
        fetchCreatedUsers()
    },[]);

    const fetchCreatedUsers = (val) => {
        apiService.get('/white-label/list',{
            closed_wl: true,
            ...val
        })
            .then(res => {
                const list = res.data.map((cst,index) => ({
                    count: index + 1,
                    key: cst?._id,
                    whiteLabelName: cst?.name,
                    name: cst?.user?.fullName,
                    record: cst,
                    username: cst?.user?.email,
                    password: cst?.user?.password,
                    dob: moment(cst?.dob).format("DD-MM-YYYY"),
                    address: cst?.address?.street,
                    city: cst?.address?.city,
                    state: cst?.address?.state,
                    country: cst?.address?.country,
                    pincode: cst?.address?.pincode,
                    phNo: cst?.contact_no?.phone_no,
                    wpNum: cst?.contact_no?.whatsapp_no
                }))
                setCreatedUserList(list)
            })
            .catch(e => {console.log(e)})
    }
    const filterHandle = (val) => {
        fetchCreatedUsers({
            botStatus: val.botStatus,
            brokerName: val.brokerName,
            ibName: val.ibName,
            refName: val.refName,
            usrStatus: val.status,
            vpsServer: val.vpsServer
        })
    }
    const resetFilter = () => {
        fetchCreatedUsers({})
        form2.resetFields()
    }

    const filterData = (searchTxt) => {
        return createdUserList.filter(dt => dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.state.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.country.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.pincode.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.city.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.cutId.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.name.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.accNo.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.username.toLowerCase().includes(searchTxt.toLowerCase()) ||
            dt.password.toLowerCase().includes(searchTxt.toLowerCase()))
    }
    return (
        <>
            <Typography.Title level={3} className="page-title">Closed Users</Typography.Title>
            <Flex justify="end" gap={5} style={{marginBottom: "20px"}}>
                <Input style={{maxWidth: 200}} placeholder="Search..." onChange={(e) => setSearchText(e.target.value)} />
                <Button type="primary" onClick={() => setFilterShow(!filterShow)}><FilterOutlined /></Button>
                <Button type="primary" onClick={() => downloadFileHandle(createdUserList, 'close_white_label')}>
                    Download
                </Button>
            </Flex>
            {filterShow && (<Card style={{marginBottom: "20px"}}>
                <Form
                    form={form2}
                    layout="vertical"
                    onFinish={filterHandle}
                >
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item name="refName">
                                <Select placeholder="Select Reference" allowClear showSearch options={customerList.map(cst => ({
                                    value: cst?.user?._id,
                                    label: cst?.user?.fullName
                                }))} filterOption={(input,option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="brokerName">
                                <Select allowClear placeholder="Select Broker">
                                    {brokerList.map(bkr => <Option key={bkr._id} value={bkr._id}>{bkr.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="ibName">
                                <Select allowClear placeholder="Select IB">
                                    {[...new Set(brokerList.map(item => item.ib_name))].map(bkr => <Option key={bkr} value={bkr}>{bkr}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="vpsServer">
                                <Select allowClear placeholder="Select VPS Server">
                                    {serverList.map(dt => <Option value={dt._id}>{dt.vps_ip}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={isLoading}>Filter</Button>
                                <Button type="default" onClick={resetFilter}>Reset</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Card>)}
            {(permission?.customer == "EDIT" || permission?.customer == "VIEW" || user?.role != "SUBADMIN") ?
                <Table loading={isLoading} scroll={{x: 3000}} columns={columns} dataSource={searchText ? filterData(searchText) : createdUserList} /> :
                <Alert message="You havn't Enough Permission" />
            }

        </>
    );
}

const mapStateToProps = ({login,loading}) => {
    const {permission,user} = login
    const {isLoading} = loading
    return {permission,user,isLoading}
}
const mapDispatchToProps = {}

export default connect(mapStateToProps,mapDispatchToProps)(CloseWhiteLabel)